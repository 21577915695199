import { SIXTY_DAYS } from "../../utils/dates";
export var RetargetingType;
(function (RetargetingType) {
    RetargetingType["TRACKING_PIXELS"] = "pixel";
    RetargetingType["CREATIVES"] = "creative";
    RetargetingType["CAMPAIGNS"] = "campaign";
    RetargetingType["LINE_ITEMS"] = "lineItems";
})(RetargetingType || (RetargetingType = {}));
export const serviceSegmentToSegment = (dmpSegment) => {
    const lastRefreshed = dmpSegment.bt_last_seen ? new Date(dmpSegment.bt_last_seen) : null;
    const lastModified = dmpSegment.last_seen ? new Date(dmpSegment.last_seen) : null;
    const hhCountUpdatedDate = dmpSegment.hh_reach_updated_at
        ? new Date(dmpSegment.hh_reach_updated_at)
        : null;
    const today = new Date().valueOf();
    const deriveLastRefreshedStatus = lastRefreshed && lastRefreshed.valueOf() > today - SIXTY_DAYS ? "Active" : "Expired";
    const segment = {
        id: dmpSegment.id,
        label: dmpSegment.name || "",
        price: dmpSegment.cpm / 100 || 0,
        householdCount: dmpSegment.reach || 0,
        householdCountUpdatedAt: hhCountUpdatedDate,
        externalId: dmpSegment.external_id || "",
        parent: dmpSegment.parent,
        status: dmpSegment.status || 0,
        initialProvider: dmpSegment.initial_provider,
        lastRefreshed: lastRefreshed,
        lastModified: lastModified,
        lastRefreshedStatus: deriveLastRefreshedStatus,
        description: dmpSegment.description,
        createdBy: dmpSegment.created_by,
        createdOn: new Date(dmpSegment.created_on)
    };
    if (dmpSegment.retargeting) {
        const retargetingType = Object.keys(dmpSegment.retargeting).find((retargetingType) => dmpSegment.retargeting[retargetingType]);
        const target = dmpSegment.retargeting[retargetingType];
        const lineItemsCount = dmpSegment.number_of_associated_line_items || 0;
        /* Retargeting segments have no expiration **/
        return {
            ...segment,
            retargetingType,
            target,
            lineItemsCount,
            lastRefreshedStatus: "Active",
            lastModified: null
        };
    }
    return segment;
};
export const isRetargetingSegment = (segment) => 
/* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
!!segment["retargetingType"];

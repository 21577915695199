import { isVideo, isHtmlTag } from "../../../../models/creatives";
import { AssetDurationThresholds, AssetStandardsStatus } from "../../../../models/creatives/specifications";
import { getAspectRatio } from "../../../../utils/dimensions";
import { deriveSpecMessage, hasSpecErrors, allAcceptableSpecs, acceptedSpecValuesAndValue } from "./specs";
const passesSpec = (keyPath, assetMetadata, orgSpecs) => {
    const { acceptable, value } = acceptedSpecValuesAndValue(keyPath, {
        orgSpecs,
        assetMetadata: assetMetadata
    });
    if (!value || !acceptable?.length) {
        return true;
    }
    switch (keyPath) {
        case "duration":
            return acceptable.some((acceptableDuration) => 
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts2365 */
            Number(acceptableDuration) - AssetDurationThresholds.LOWER <= value &&
                /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts2365 */
                Number(acceptableDuration) + AssetDurationThresholds.UPPER >= value);
        case "audio.codec":
            return acceptable.some((format) => format === value);
        case "audio.bitrate.min":
            return !acceptable.some((acceptableValue) => value < acceptableValue);
        case "video.codec":
            return acceptable.some((format) => format === value);
        case "video.bitrate.min":
            return !acceptable.some((acceptableValue) => value < acceptableValue);
        case "video.bitrate.max":
            return !acceptable.some((acceptableValue) => value > acceptableValue);
        case "video.dimensions":
            return acceptable.some((acceptableDimensions) => {
                return (value.height === acceptableDimensions.height &&
                    value.width === acceptableDimensions.width);
            });
        case "image.dimensions": {
            return acceptable.some((acceptableDimensions) => {
                return (value.height === acceptableDimensions.height &&
                    value.width === acceptableDimensions.width);
            });
        }
        case "text.dimensions":
            return acceptable.some((acceptableDimensions) => {
                return (value.height === acceptableDimensions.height &&
                    value.width === acceptableDimensions.width);
            });
        default:
            return true;
    }
};
/**
 * Duration thresholds are fetched from the /spec endpoint,
 * and are almost always going to 15s, 30s, and 60s.
 * @param assetMetadata - CreativeAssetMetadata
 * @param specDurations - list of numbers that represent acceptable durations in seconds
 * @returns - which of these thresholds a video has violated. Only call this function on videos that have failed validateAssetDurationInSeconds.
 */
const getDurationThresholdExceeded = (assetMetadata, specDurations) => {
    const durationExceeded = specDurations
        .sort((a, b) => (a < b ? 1 : -1))
        .find((acceptableDuration) => {
        const seconds = assetMetadata.duration || 0;
        return seconds > acceptableDuration + AssetDurationThresholds.UPPER;
    });
    return durationExceeded || 0;
};
/**
 * Object of methods that determine if value passes spec,
 * and derives a spec message when value does not meet spec
 */
const problemChecks = {
    duration: (assetMetadata, orgSpecs) => {
        if (passesSpec("duration", assetMetadata, orgSpecs)) {
            return undefined;
        }
        return deriveSpecMessage("Acceptable duration(s):", "duration", orgSpecs);
    },
    "audio.codec": (assetMetadata, orgSpecs) => {
        if (passesSpec("audio.codec", assetMetadata, orgSpecs)) {
            return undefined;
        }
        return deriveSpecMessage("Acceptable audio encoding format(s):", "audio.codec", orgSpecs);
    },
    "audio.bitrate.min": (assetMetadata, orgSpecs) => {
        if (passesSpec("audio.bitrate.min", assetMetadata, orgSpecs)) {
            return undefined;
        }
        return deriveSpecMessage("Audio bitrate is too low, acceptable minimum(s):", "audio.bitrate.min", orgSpecs);
    },
    "video.aspectRatio": (assetMetadata) => {
        if (!isVideo(assetMetadata)) {
            return undefined;
        }
        const [width, height] = getAspectRatio(assetMetadata?.dimensions?.width || 0, assetMetadata?.dimensions?.height || 0);
        if (width === 16 && height === 9) {
            return undefined;
        }
        return "Acceptable aspect ratio: 16x9";
    },
    "video.codec": (assetMetadata, orgSpecs) => {
        if (passesSpec("video.codec", assetMetadata, orgSpecs)) {
            return undefined;
        }
        return deriveSpecMessage("Acceptable video encoding format(s):", "video.codec", orgSpecs);
    },
    "video.bitrate.min": (assetMetadata, orgSpecs) => {
        if (passesSpec("video.bitrate.min", assetMetadata, orgSpecs)) {
            return undefined;
        }
        return deriveSpecMessage("Video bitrate is too low, acceptable minimum(s):", "video.bitrate.min", orgSpecs);
    },
    "video.bitrate.max": (assetMetadata, orgSpecs) => {
        if (passesSpec("video.bitrate.max", assetMetadata, orgSpecs)) {
            return undefined;
        }
        return deriveSpecMessage("Video bitrate is too high, acceptable maximums(s):", "video.bitrate.max", orgSpecs);
    },
    "video.dimensions": (assetMetadata, orgSpecs) => {
        if (!isVideo(assetMetadata) || passesSpec("video.dimensions", assetMetadata, orgSpecs)) {
            return undefined;
        }
        return deriveSpecMessage("Acceptable video dimensions:", "video.dimensions", orgSpecs);
    },
    "image.dimensions": (assetMetadata, orgSpecs) => {
        if (!assetMetadata?.display?.isDisplay ||
            passesSpec("image.dimensions", assetMetadata, orgSpecs)) {
            return undefined;
        }
        return deriveSpecMessage("Acceptable image dimensions:", "image.dimensions", orgSpecs);
    },
    "text.dimensions": (assetMetadata, orgSpecs) => {
        if (!isHtmlTag(assetMetadata) || passesSpec("image.dimensions", assetMetadata, orgSpecs)) {
            return undefined;
        }
        return deriveSpecMessage("Acceptable text dimensions:", "text.dimensions", orgSpecs);
    }
};
/**
 * derives problems based on creative asset metadata and org specifications
 */
export const deriveProblems = (assetMetadata, orgSpecs) => {
    return {
        duration: problemChecks.duration(assetMetadata, orgSpecs),
        video: {
            dimensions: problemChecks["video.dimensions"](assetMetadata, orgSpecs),
            aspectRatio: problemChecks["video.aspectRatio"](assetMetadata, orgSpecs),
            codec: problemChecks["video.codec"](assetMetadata, orgSpecs),
            bitrate: problemChecks["video.bitrate.min"](assetMetadata, orgSpecs) ||
                problemChecks["video.bitrate.max"](assetMetadata, orgSpecs)
        },
        audio: {
            codec: problemChecks["audio.codec"](assetMetadata, orgSpecs),
            bitrate: problemChecks["audio.bitrate.min"](assetMetadata, orgSpecs)
        },
        image: {
            dimensions: problemChecks["image.dimensions"](assetMetadata, orgSpecs)
        },
        text: {
            dimensions: problemChecks["text.dimensions"](assetMetadata, orgSpecs)
        },
        byOrg: [...orgSpecs.entries()].reduce((acc, [name, orgSpec]) => {
            const singleSpecMap = new Map([[name, orgSpec]]);
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            acc[name] = {
                duration: problemChecks.duration(assetMetadata, orgSpecs),
                video: {
                    dimensions: problemChecks["video.dimensions"](assetMetadata, singleSpecMap),
                    aspectRatio: problemChecks["video.aspectRatio"](assetMetadata, orgSpecs),
                    codec: problemChecks["video.codec"](assetMetadata, singleSpecMap),
                    bitrate: problemChecks["video.bitrate.min"](assetMetadata, singleSpecMap) ||
                        problemChecks["video.bitrate.max"](assetMetadata, singleSpecMap)
                },
                audio: {
                    codec: problemChecks["audio.codec"](assetMetadata, singleSpecMap),
                    bitrate: problemChecks["audio.bitrate.min"](assetMetadata, singleSpecMap)
                },
                image: {
                    dimensions: problemChecks["image.dimensions"](assetMetadata, singleSpecMap)
                },
                text: {
                    dimensions: problemChecks["text.dimensions"](assetMetadata, singleSpecMap)
                }
            };
            return acc;
        }, {})
    };
};
export const deriveStandards = (assetMetadata, orgSpecs) => {
    const problems = deriveProblems(assetMetadata, orgSpecs);
    let status;
    if (!hasSpecErrors(problems)) {
        status = AssetStandardsStatus.PASSED;
    }
    else if (typeof problems?.duration === "string") {
        status = AssetStandardsStatus.FAILED_DURATION;
    }
    else {
        status = AssetStandardsStatus.FAILED_NON_DURATION_SPEC;
    }
    const durations = allAcceptableSpecs("duration", orgSpecs);
    let message = status;
    if (status === AssetStandardsStatus.FAILED_DURATION && durations) {
        const thresholdExceeded = getDurationThresholdExceeded(assetMetadata, durations);
        message =
            thresholdExceeded > 0 ? `Exceeds ${thresholdExceeded}s` : `Under ${Math.min(...durations)}s`;
    }
    return {
        status,
        message
    };
};

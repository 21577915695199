/* eslint-disable camelcase */
import { escapeRegex } from "../../utils/strings";
import { serviceSpecificationToSpecification } from "./specifications";
import { MediaType } from "../media";
import { AudioChannel, deriveAudioChannel, determineRenditionMediaType, getThumbnailSrcByMediaType } from "./asset";
import { MIME_TO_GCP_EXTENSION_LEGACY, MIME_TYPE_TO_MEDIA_TYPE_LEGACY, TextTypeLegacy } from "./legacy";
import { findClickThroughFromEvents, parseEventUrls } from "./trackers";
import { getAspectRatio } from "../../utils/dimensions";
import { removeWhitespace } from "../../utils";
import { DEFAULT_RENDITION } from "./definitions";
const serviceToAssetDetails = {
    video: (data) => 
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    ["video_codec", "video_bitrate"].filter((key) => typeof data[key] !== "undefined").length > 0
        ? {
            codec: data.video_codec,
            bitrate: data.video_bitrate,
            isCropped: data.cropped
        }
        : undefined,
    audio: (data) => ["audio_sample_rate", "audio_codec", "audio_bitrate"].filter(
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    (key) => typeof data[key] !== "undefined").length > 0
        ? {
            sampleRate: data.audio_sample_rate,
            channel: deriveAudioChannel(data),
            codec: data.audio_codec,
            bitrate: data.audio_bitrate
        }
        : undefined,
    display: (data) => {
        const mediaType = determineRenditionMediaType(data);
        const isDisplay = mediaType === MediaType.DISPLAY;
        return [isDisplay, data.image_format, data.text_format].some((val) => !!val)
            ? {
                isDisplay,
                imageFormat: data.image_format,
                textFormat: Object.values(TextTypeLegacy).includes(data.text_format)
                    ? data.text_format
                    : undefined
            }
            : undefined;
    },
    dimensions: (data) => typeof data.width === "number" && typeof data.height === "number"
        ? {
            width: data.width,
            height: data.height,
            aspectRatio: getAspectRatio(data.width, data.height).join(":")
        }
        : undefined
};
/**
 * Helper function to covert ServiceRendition to a ValidationDetails
 * @param data ServiceRendition to be converted to a ValidationDetails
 * @returns ValidationDetails based on the ServiceRendition
 */
const serviceRenditionToAssetMetadata = (data) => {
    const mediaType = determineRenditionMediaType(data);
    const video = serviceToAssetDetails.video(data);
    const audio = serviceToAssetDetails.audio(data);
    const display = serviceToAssetDetails.display(data);
    const dimensions = serviceToAssetDetails.dimensions(data);
    const assetMetadata = {
        volume: data.volume,
        fileSize: data.size,
        duration: data.duration, // default duration transform
        assetHash: data.MD5 || data.hash,
        mime: data.mime,
        mediaType,
        filename: data.filename
    };
    if (video) {
        assetMetadata.video = video;
    }
    if (audio) {
        assetMetadata.audio = audio;
    }
    if (display) {
        assetMetadata.display = display;
    }
    if (dimensions) {
        assetMetadata.dimensions = dimensions;
    }
    return assetMetadata;
};
const assetMetadataToServiceRendition = (assetMetadata) => {
    const { filename, fileSize: size, assetHash: hash, mime, dimensions, video, audio, display, volume, duration } = assetMetadata;
    const { width, height } = dimensions || {};
    const { isCropped: cropped, bitrate: video_bitrate, codec: video_codec } = video || {};
    const { bitrate: audio_bitrate, codec: audio_codec, sampleRate: audio_sample_rate, channel: audio_channel = AudioChannel.NONE } = audio || {};
    const { imageFormat: image_format, textFormat: text_format } = display || {};
    const serviceRendition = {
        filename,
        mime,
        width,
        height,
        duration,
        size,
        volume,
        video_bitrate,
        video_codec,
        audio_bitrate,
        audio_codec,
        audio_sample_rate,
        audio_channel,
        hash
    };
    if (cropped) {
        serviceRendition.cropped = cropped;
    }
    if (image_format) {
        serviceRendition.image_format = image_format;
    }
    if (text_format) {
        serviceRendition.text_format = text_format;
    }
    return serviceRendition;
};
/**
 * Coverts a ServiceValidationDetails to a ValidationDetails
 * @param data ServiceValidationDetails to be converted
 * @returns ValidationDetails to be returned
 */
export const serviceToValidationDetails = (data) => {
    const { macros_replacements_preview, metadata, problems: onUpload, specifications, vast_video_url } = data;
    const assetMetadata = serviceRenditionToAssetMetadata(metadata);
    if (vast_video_url) {
        assetMetadata.vastVideoUrl = vast_video_url;
    }
    const macroReplacements = new Array();
    if (macros_replacements_preview) {
        for (const [key, value] of Object.entries(macros_replacements_preview)) {
            const regex = new RegExp(escapeRegex(key), "g");
            const standard = value;
            const isSupported = value.trim().length !== 0;
            const macroReplacement = {
                regex,
                standard,
                isSupported
            };
            macroReplacements.push(macroReplacement);
        }
    }
    return {
        problems: {
            onUpload
        },
        specificationsCheckedAgainst: specifications.reduce((byKey, spec) => {
            const specification = serviceSpecificationToSpecification(spec);
            return {
                ...byKey,
                [specification.name]: specification
            };
        }, Object.create(null)),
        ...(macroReplacements?.length && { macroReplacements }),
        assetMetadata
    };
};
/**
 * Used to covert a ServiceCreative to a Creative
 * This is a "base", further validationDetails added in module.
 * inscape, specifications, problems, macroReplacements and other details
 * are either returned from backend or inferred from first Renditions, UploadResults and other operations
 * @param serviceCreative Service Creative to transform
 * @returns Creative based on that ServiceCreative
 */
export const serviceToCreative = (serviceCreative, creativeAssetFolderBaseUrl) => {
    const { id, status, tag, name = "", parent: advertiserId, parent_name: advertiserName, oms_id: orderManagementSystemId, vast_url: vastUrl, updated_by: updatedBy = "", asset_url: cdnUrl = "", ext_id: externalId = "", isci_code: isciCode = "", event_urls: trackingPixels = [], associated_lineitems: associatedLineItems = 0, associated_campaigns: associatedCampaigns = 0 } = serviceCreative;
    let category = "";
    if (serviceCreative.flags || serviceCreative.adv_flags) {
        const { flags: flags1 = {}, adv_flags: flags2 = {} } = serviceCreative;
        const flags = { ...flags1, ...flags2 };
        if (flags.cbd) {
            category = "cbd";
        }
        else if (flags.gambling) {
            category = "gambling";
        }
        else if (flags.lda) {
            category = "lda";
        }
        else if (flags.political) {
            category = "political";
        }
    }
    const latestRendition = serviceCreative?.renditions?.[0] || DEFAULT_RENDITION;
    const assetMetadata = latestRendition
        ? serviceRenditionToAssetMetadata(latestRendition)
        : undefined;
    const extension = MIME_TO_GCP_EXTENSION_LEGACY[assetMetadata?.mime || 0];
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    const mediaType = MIME_TYPE_TO_MEDIA_TYPE_LEGACY[assetMetadata?.mime || 0];
    const thumbnailSrc = getThumbnailSrcByMediaType(mediaType, serviceCreative.id, creativeAssetFolderBaseUrl);
    const videoAnnotation = Object.keys(serviceCreative?.video_annotation || {}).length
        ? {
            ...serviceCreative.video_annotation,
            labels: serviceCreative.video_annotation?.labels?.map((label) => {
                const { entity_id: entityId, category_id: categoryId, name, category, weight, confidence } = label;
                return { entityId, categoryId, name, category, weight, confidence };
            }) || []
        }
        : {};
    const tagReinspectResult = serviceCreative.tag_reinspect_result?.inspected_at.seconds
        ? {
            inspectedAt: new Date(serviceCreative.tag_reinspect_result.inspected_at.seconds * 1000),
            status: serviceCreative.tag_reinspect_result.status,
            errorMessage: serviceCreative.tag_reinspect_result.error_message
        }
        : undefined;
    const creative = {
        id,
        status,
        name,
        tag,
        advertiserId,
        advertiserName,
        orderManagementSystemId,
        cdnUrl,
        vastUrl,
        externalId,
        isciCode,
        trackingPixels,
        associatedLineItems,
        associatedCampaigns,
        thumbnailSrc,
        videoAnnotation,
        category,
        assetUrl: `${creativeAssetFolderBaseUrl}/${serviceCreative.id}/master${extension}`,
        clickThroughUrl: removeWhitespace(findClickThroughFromEvents(serviceCreative.event_urls)),
        createdOn: serviceCreative.created_at
            ? new Date(serviceCreative.created_at.seconds * 1000)
            : undefined,
        lastUpdated: serviceCreative.updated_at
            ? new Date(serviceCreative.updated_at.seconds * 1000)
            : undefined,
        updatedBy,
        tagReinspectResult
    };
    if (assetMetadata) {
        creative.validationDetails = { assetMetadata };
    }
    return creative;
};
/**
 * Currently this only transforms a creative to the most basic needs
 * for a service. For non-linear and vast there is an extra step of
 * transforming event_urls
 * @param creative creative to transforms
 */
export const creativeToService = (creative) => {
    const { id, advertiserId: advertiser, advertiserName: parent_name, vastUrl: vast_url, tag, status = 200, filename: file_name, associatedCampaigns: associated_campaigns, associatedLineItems: associated_lineitems } = creative;
    const potentialCategory = creative.category?.toLowerCase() || "";
    const video_annotation = Object.keys(creative?.videoAnnotation || {}).length
        ? {
            ...creative.videoAnnotation,
            labels: creative.videoAnnotation?.labels?.map((label) => {
                const { entityId: entity_id, categoryId: category_id, name, category, weight, confidence } = label;
                return { entity_id, category_id, name, category, weight, confidence };
            }) || []
        }
        : {};
    const flags = potentialCategory
        ? {
            [potentialCategory]: true
        }
        : undefined;
    const isci_code = creative?.isciCode?.trim() || undefined;
    const serviceCreative = {
        id,
        advertiser,
        parent: advertiser, // put update uses parent
        parent_name,
        associated_campaigns,
        associated_lineitems,
        status,
        name: creative?.name?.trim(),
        url: creative.cloudStorageUrl ? creative.cloudStorageUrl.trim() : "",
        duration: creative.validationDetails?.assetMetadata?.duration
            ? Math.round(creative.validationDetails?.assetMetadata?.duration)
            : 0,
        renditions: [assetMetadataToServiceRendition(creative.validationDetails?.assetMetadata || {})],
        event_urls: parseEventUrls(creative) || [],
        video_annotation
    };
    if (vast_url) {
        serviceCreative.vast_url = vast_url;
    }
    if (tag) {
        serviceCreative.tag = tag;
    }
    if (file_name) {
        serviceCreative.file_name = file_name;
    }
    if (flags) {
        serviceCreative.flags = flags;
    }
    if (isci_code) {
        serviceCreative.isci_code = isci_code;
    }
    return serviceCreative;
};

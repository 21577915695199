import { KnownProjectIds, RemoteConfigProperties } from "./types";
export const PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS = new Set([KnownProjectIds.MAD_MASTER]);
/**
 * Base set of URLS used throughout of the UI. These are based on project ids.
 * @constructor {googleEndpointProjectId} - Project ID to build URL with.
 */
export default class URLS {
    constructor(sdk, config) {
        this.sdk = sdk;
        const { googleEndpointProjectId } = config;
        this.baseCIUrl = config.baseCIUrl;
        this.googleEndpointProjectSuffix = googleEndpointProjectId.replace("mad-", "");
        this.googleEndpointProjectId = googleEndpointProjectId;
        this.googleEndpointProjectIdWithFallback = googleEndpointProjectId;
        // TODO: Remove once 'talon' has been fixed
        if (this.googleEndpointProjectSuffix === "talon") {
            this.googleEndpointProjectSuffix = "qa";
            this.googleEndpointProjectIdWithFallback = KnownProjectIds.MAD_QA;
        }
    }
    /**
     * URL for base API (NNY)
     */
    get baseAPIUrl() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/campaign-management/v1`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? "https://api.madhive.com/campaign-management/v1"
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/campaign-management/v1`;
    }
    /**
     * URL for Planner Service
     * We've decoupled planner from hermes to it's own service
     */
    get basePlannerServiceUrl() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/planning/v1`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? `https://api.madhive.com/planning/v1`
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/planning/v1`;
    }
    /**
     * URL for Hermes (to be deprecated for v2)
     */
    get baseHermesUrl() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/reporting/v1`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? `https://api.madhive.com/reporting/v1`
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/reporting/v1`;
    }
    /**
     * URL for Hermes static information.
     */
    get baseHermesUrlStaticAssets() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/static/v1`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? `https://api.madhive.com/static/v1`
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/static/v1`;
    }
    /**
     * URL for Hermes static information, v2. For temporary use; will eventually phase out phase 1 so this becomes baseHermesUrlStaticAssets
     */
    get baseHermesUrlStaticAssetsTempV2() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/static/v2`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? `https://api.madhive.com/static/v2`
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/static/v2`;
    }
    /**
     * URL for Reporting
     */
    get madhiveReportingBaseUrl() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/reporting/v2`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? "https://api.madhive.com/reporting/v2"
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/reporting/v2`;
    }
    /**
     * URL for Creatives in MadHive Encoder
     */
    get madhiveEncoderBaseUrl() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/creatives/v1`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? "https://api.madhive.com/creatives/v1"
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/creatives/v1`;
    }
    /**
     * URL for PII
     */
    get madhivePIIBaseUrl() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/pii-ingest`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? "https://api.madhive.com/pii-ingest"
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/pii-ingest`;
    }
    /**
     * URL for the creative asset folder
     */
    get creativeAssetFolderBaseUrl() {
        return PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
            ? "https://z.mdhv.io"
            : `https://storage.googleapis.com/${this.googleEndpointProjectId}-creative-assets`;
    }
    /**
     * URL for adbook campaigns
     */
    /* eslint-disable-next-line class-methods-use-this */
    get adbookCampaignsBaseUrl() {
        return PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
            ? `https://api.madhive.com/adbook`
            : // Staging Edge url for adbook (doesn't exist on hack/qa)
                `https://api-${this.googleEndpointProjectSuffix}.madhive.com/adbook`;
    }
    /**
     * URL for inscape
     */
    get inscapeBaseUrl() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/inscape/v1/status`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? `https://api.madhive.com/inscape/v1/status`
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/inscape/v1/status`;
    }
    get burnsBaseUrl() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/burns`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? `https://api.madhive.com/burns`
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/burns`;
    }
    get wideorbitErrorsBaseUrl() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/wideorbit/v1/error/report`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? `https://api.madhive.com/wideorbit/v1/error/report`
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/wideorbit/v1/error/report`;
    }
    themeVarsUrl(orgName) {
        const apiDomain = this.baseCIUrl || PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
            ? "https://api.madhive.com"
            : `https://api-${this.googleEndpointProjectSuffix}.madhive.com`;
        return `${apiDomain}/burns/styles/${orgName}.css`;
    }
    get advancedQueryToolBaseUrl() {
        return this.baseCIUrl
            ? `${this.baseCIUrl}/advancedquerytool`
            : PRODUCTION_GOOGLE_ENDPOINT_PROJECT_IDS.has(this.googleEndpointProjectId)
                ? `https://api.madhive.com/advancedquerytool`
                : `https://api-${this.googleEndpointProjectSuffix}.madhive.com/advancedquerytool`;
    }
    /** The Madhive looker host, minus any connection protocol. */
    get lookerHost() {
        switch (this.googleEndpointProjectId) {
            case KnownProjectIds.MAD_HACK:
                return "madhiveexternaldev.cloud.looker.com";
            case KnownProjectIds.MADHIVE_TESTING:
                return "madhiveexternaldev.cloud.looker.com";
            case KnownProjectIds.MAD_QA:
                return "madhiveexternalstage.cloud.looker.com";
            case KnownProjectIds.MAD_STAGING:
                return "madhiveexternalstage.cloud.looker.com";
            default:
                return `madhiveexternal.cloud.looker.com`;
        }
    }
    /** A full, base URL to the Madhive looker instance. */
    get lookerBaseUrl() {
        return `https://${this.lookerHost}`;
    }
    /** The url for campaign/lineitem pacing summary data. */
    get pacingUrl() {
        const pacingEndpoint = this.sdk.featureFlags.isFlagEnabled(RemoteConfigProperties.PACE_TO_BUDGET_REPORTING)
            ? "campaign_impression_summary_p2b"
            : "campaign_impression_summary";
        return `${this.sdk.urls.baseHermesUrl}/${pacingEndpoint}`;
    }
}

import { FilterTypes } from "../../../../types";
import { DateTime } from "luxon";
const attributionModelToQueryMap = {
    totalImpressions: "explore_conversion.impressions",
    totalCompletes: "explore_conversion.completes",
    totalReach: "explore_conversion.reach",
    averageFrequency: "explore_conversion.avg_frequency",
    totalConversions: "explore_conversion.impression_converted",
    conversionRate: "explore_conversion.conversion_rate",
    uniqueConversions: "explore_conversion.unique_conversions",
    uniqueConversionRate: "explore_conversion.unique_conversion_rate",
    viewCompletionRate: "explore_conversion.view_completion_rate",
    totalClicks: "explore_conversion.clicks",
    publisherName: "explore_conversion.publisher_name",
    creativeName: "explore_conversion.creative_name",
    beaconName: "explore_conversion.beacon_name",
    daysToConversion: "explore_conversion.days_to_conversion",
    state: "explore_conversion.region_name",
    lineitemName: "explore_conversion.lineitem_name",
    zipCode: "explore_conversion.postal",
    week: "explore_conversion.impression_week",
    month: "explore_conversion.impression_month",
    hourOfWeek: "explore_conversion.impression_hour_of_week",
    hourOfDay24: "explore_conversion.impression_hour_of_day24",
    hourOfDay12: "explore_conversion.impression_hour_of_day12",
    dayOfWeek: "explore_conversion.impression_day_of_week",
    dayOfMonth: "explore_conversion.impression_day_of_month",
    date: "explore_conversion.impression_date",
    frequency: "explore_conversion.frequency",
    dma: "explore_conversion.dma_name",
    deviceType: "explore_conversion.device_type",
    daypart: "explore_conversion.daypart_name",
    congressionalDistrict: "explore_conversion.congressional_district_name",
    campaignName: "explore_conversion.campaign_name",
    advertiserName: "explore_conversion.advertiser_name",
    contentLanguage: "explore_conversion.language_name"
};
export const IMPRESSION_METRICS = [
    "totalImpressions",
    "totalReach",
    "averageFrequency",
    "totalCompletes",
    "viewCompletionRate",
    "totalClicks"
];
export const CONVERSION_METRICS = [
    "totalConversions",
    "conversionRate",
    "uniqueConversions",
    "uniqueConversionRate"
];
/**
 * Transforms a list of model fields into a list of explore dimensions
 * using the object mapper
 *
 * @param fields The list of fields that are gonna be requested from the explore
 * @returns A list of dimension names from the explore
 */
export const attributionModelFieldsToQueryDimensions = (fields) => {
    return fields.map((field) => attributionModelToQueryMap[field]);
};
export const isAttributionQueryMeasure = (field) => {
    const validFields = [
        "explore_conversion.reach",
        "explore_conversion.impressions",
        "explore_conversion.avg_frequency",
        "explore_conversion.impression_converted",
        "explore_conversion.unique_conversion_rate",
        "explore_conversion.unique_conversions",
        "explore_conversion.completes",
        "explore_conversion.conversion_rate",
        "explore_conversion.view_completion_rate",
        "explore_conversion.clicks"
    ];
    return validFields.includes(field);
};
/**
 * This builds the model object using the provided fields and the raw response
 *
 * @param fields The list of fields that where requested from the explore
 * @param queryResponse The response data from the explore query
 * @returns An object containing the requested fields with their matching response value
 */
export const attributionQueryDimensionsToModel = (fields, queryResponse) => {
    const model = {};
    for (const field of fields) {
        /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
        model[field] = queryResponse[attributionModelToQueryMap[field]];
    }
    return model;
};
export const modelFiltersToQueryFilters = (filters) => {
    if (!filters) {
        throw new Error("You must provide filters in order to query the attribution explore");
    }
    const queryFilters = {};
    for (const filter of filters) {
        switch (filter.field) {
            case "dates":
                queryFilters["explore_conversion.flt_analysis_interval"] = parseDateFilter(filter);
                break;
            case "reportType":
                queryFilters["explore_conversion.flt_advertiser_id"] = parseStringFilter(filter);
                break;
            case "advertiserIds":
                queryFilters["explore_conversion.flt_advertiser_id"] = parseStringFilter(filter);
                break;
            case "lineitemIds":
                queryFilters["explore_conversion.flt_lineitem_id"] = parseStringFilter(filter);
                break;
            case "pixelIds":
                queryFilters["explore_conversion.flt_beacon_id"] = parseStringFilter(filter);
                break;
            default:
                throw new Error(`Filter ${filter.field} is not supported for the attribution explore`);
        }
    }
    return queryFilters;
};
export const parseDateFilter = (filter) => {
    let queryFilter = "";
    switch (filter.type) {
        case FilterTypes.BETWEEN: {
            if (!Array.isArray(filter.value)) {
                throw new Error(`Filter ${filter.field} must be an array of dates when filtering for a date range`);
            }
            const [startDate, endDate] = filter.value;
            if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
                throw new Error(`Filter ${filter.field} must contain two dates when filtering for a date range`);
            }
            const formattedStartDate = DateTime.fromJSDate(startDate).toFormat("yyyy/MM/dd");
            // We add one day to the end date because Looker uses the end date as an exclusive filter, they show a message
            // in the UI that says "until (before)" which means that the end date is not included in the filter
            const formattedEndDate = DateTime.fromJSDate(endDate).plus({ day: 1 }).toFormat("yyyy/MM/dd");
            queryFilter = `${formattedStartDate} to ${formattedEndDate}`;
            break;
        }
    }
    return queryFilter;
};
const parseStringFilter = (filter) => {
    switch (filter.type) {
        case FilterTypes.EQ: {
            if (typeof filter.value !== "string") {
                throw new Error(`Filter ${filter.field} must be a string`);
            }
            return filter.value;
        }
        case FilterTypes.IN: {
            if (!Array.isArray(filter.value)) {
                throw new Error(`Filter ${filter.field} must be an array of strings`);
            }
            return filter.value.join(",");
        }
        default:
            throw new Error(`Filter ${filter.type} is not supported for the field ${filter.field}`);
    }
};
export const modelFiltersToLookQuerySort = (filters) => {
    if (!filters) {
        throw new Error("You must provide filters in order to query the attribution explore");
    }
    const [sortField, sortDirection] = ["sortField", "sortDirection"].map((field) => {
        const filterField = filters.find((filter) => filter.field === field);
        if (!filterField) {
            throw new Error(`You must provide a ${field} in order to create an attribution`);
        }
        return parseStringFilter(filterField);
    });
    return `${attributionModelToQueryMap[sortField]} ${sortDirection}`;
};
export const isAttributionFilter = (filterField) => {
    const validFields = [
        "dates",
        "reportType",
        "advertiserIds",
        "lineitemIds",
        "pixelIds"
    ];
    return validFields.includes(filterField);
};
export const formatCampaignPixelResponse = (data) => {
    const campaignIdToLineItemIds = {};
    const seenCampaignIds = new Set();
    const seenPixelIds = new Set();
    const campaigns = [];
    const pixels = [];
    data.forEach((campaignPixel) => {
        const campaignId = campaignPixel["conversion_meta_inst.campaign__id"];
        const campaignName = campaignPixel["conversion_meta_inst.campaign__name"];
        const lineItemId = campaignPixel["conversion_meta_inst.lineitem__id"];
        const pixelId = campaignPixel["conversion_meta_beacon.pk1_id"];
        const pixelName = campaignPixel["conversion_meta_beacon.name"];
        if (campaignId) {
            if (!seenCampaignIds.has(campaignId)) {
                campaigns.push({
                    name: campaignName,
                    id: campaignId
                });
                seenCampaignIds.add(campaignId);
            }
            if (!campaignIdToLineItemIds[campaignId]) {
                campaignIdToLineItemIds[campaignId] = new Set();
            }
            campaignIdToLineItemIds[campaignId].add(lineItemId);
        }
        if (pixelId && !seenPixelIds.has(pixelId)) {
            pixels.push({
                name: pixelName,
                id: pixelId
            });
            seenPixelIds.add(pixelId);
        }
    });
    return {
        campaigns,
        pixels,
        campaignIdToLineItemIds
    };
};

import { NotImplementedError } from "../../../../../errors";
import { parseDateFilter, formatCampaignPixelResponse } from "../../../../../models/looker/modules/attribution";
import { Handler } from "../../../../handlers";
import { FILTER_OPTIONS_CAMPAIGN_PIXEL_QUERY } from "../query";
export default class CampaignPixel extends Handler {
    constructor(sdk) {
        super(sdk, "looker-pacing");
    }
    async findItems(filters) {
        const dateFilter = filters.where?.find((filter) => filter.field === "dates");
        if (!dateFilter) {
            throw new Error("Dates filter is required");
        }
        const parsedDateFilter = parseDateFilter(dateFilter);
        const advertiserIdFilter = filters.where?.find((filter) => filter.field === "conversion_meta_inst.advertiser__id")?.value;
        if (!advertiserIdFilter) {
            throw new Error("Advertiser ID filter is required");
        }
        const queryBody = {
            ...FILTER_OPTIONS_CAMPAIGN_PIXEL_QUERY,
            filters: {
                "billable_agg.filter_date_nyc": parsedDateFilter,
                "conversion_meta_inst.advertiser__id": advertiserIdFilter
            }
        };
        const hashKey = await this.sdk.looker.query.hashQuery(queryBody);
        return this.cache.promise(hashKey, () => this.sdk.looker.query
            .run(queryBody)
            .then(({ data, queryMeta }) => [
            {
                data: formatCampaignPixelResponse(data),
                queryMeta
            }
        ])
            .catch((e) => {
            console.error(e);
            throw new Error("Failed to fetch conversion dashboard pixel filter options");
        }));
    }
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    async deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}

import { NotImplementedError } from "../../../../errors";
import { attributionModelFieldsToQueryDimensions, attributionQueryDimensionsToModel, modelFiltersToQueryFilters } from "../../../../models/looker/modules/attribution";
import { Handler } from "../../../handlers";
import { KnownOrganizationIds } from "../../../../types";
import Advertiser from "./filters/Advertiser";
import CampaignPixel from "./filters/CampaignPixel";
import Looks from "./looks";
import { ATTRIBUTION_QUERY } from "./query";
import Scheduling from "./scheduling";
class Attribution extends Handler {
    constructor(sdk) {
        super(sdk, "looker-attribution");
        this.filters = {
            advertiser: new Advertiser(sdk),
            campaignPixel: new CampaignPixel(sdk)
        };
        this.looks = new Looks(sdk);
        this.schedule = new Scheduling(sdk);
    }
    async findItems(filters) {
        const fields = attributionModelFieldsToQueryDimensions(filters.fields || []);
        if (fields.length === 0) {
            throw new Error("You must specify at least one field to query");
        }
        const queryFilters = modelFiltersToQueryFilters(filters.where);
        if (this.sdk.getCurrentUser()?.primaryOrganizationId === KnownOrganizationIds.FOX) {
            queryFilters["explore_conversion.pmt_filter_out_fox_anomalous_ips"] = "yes";
        }
        const queryBody = {
            ...ATTRIBUTION_QUERY,
            limit: "5000",
            filters: queryFilters,
            fields: fields
        };
        const hashKey = await this.sdk.looker.query.hashQuery(queryBody);
        return this.cache.promise(hashKey, () => this.sdk.looker.query
            .run(queryBody)
            .then(({ data, queryMeta }) => [
            {
                data: data.map((attribution) => attributionQueryDimensionsToModel(filters.fields || [], attribution)),
                queryMeta
            }
        ])
            .catch(() => {
            throw new Error("Failed to fetch attribution data");
        }));
    }
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    async deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}
export default Attribution;

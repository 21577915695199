import axios, { AxiosError, AxiosResponse, CancelTokenSource } from "axios";
import {
  AdbookCampaignsEndpointGetResponse,
  CampaignAudit,
  CampaignDetailSpecificInfo,
  CampaignId,
  CommonCampaignExternalMetadata,
  DraftCampaign,
  FoxCampaignExternalMetadata,
  FoxLineItemExternalMetadata,
  GroundTruthLineItemExternalMetadata,
  LineItemAudit,
  LineItemCreative,
  LineItemFormatted,
  LineItemFormattedForAttachingCreatives,
  LineItemId,
  PartialLineItemForBulk,
  PremionCampaignExternalMetadata,
  PremionLineItemExternalMetadata,
  QuickEditLineItemPayload,
  QuickEditsPayload,
  RootCampaign,
  SalesforceCampaignExternalMetadata,
  ScrippsCampaignExternalMedatata,
  ScrippsLineItemExternalMetadata,
  ShallowLineItem,
  WideOrbitCampaignExternalMetadata,
  WideOrbitLineItemExternalMetadata
} from "campaign-types";
import { FILLED_DAYPARTS } from "components/SmithersDayparter/SmithersDayparter";
import {
  INSTRUCTION_STATUS_CHECKBOX_OPTIONS,
  RawInstructionStatus
} from "features/ManageCampaigns/constants";
import { isCampaignDescendentEditable } from "features/ManageCampaigns/utils";
import {
  CampaignStatus,
  UNEDITABLE_CAMPAIGN_STATUSES
} from "lib/constants/campaign";
import { DayOfTheWeek, LogicalOperator } from "lib/constants/misc";
import { madSDK } from "lib/sdk";
import { isPaceToBudgetEnabled } from "lib/utils/budget";
import { featureFlag } from "lib/utils/featureFlags";
import { deleteKeysWithUndefinedValues, isTruthy, sum } from "lib/utils/fp";
import { OPERATOR_TO_FINALIZE_GEO_FN } from "lib/utils/lineItemGeography";
import { convertRecordToQueryString } from "lib/utils/qs";
import { mapValues, uniq } from "lodash";
import {
  BulkEditPatchBody,
  BulkEditPatchItem,
  CampaignDescendantRaw,
  CampaignIdEndpointGetResponse,
  CampaignsEndpointGetResponse,
  CreateAndUpdateLineItemEndpointPostBody,
  CreateCampaignEndpointPostBody,
  CreateCampaignEndpointPostResponse,
  Entity,
  LineItemIdEndpointGetResponse,
  LineItemsEndpointGetResponse,
  RawLineItemCreative,
  RootCampaignRaw,
  UpdateCampaignEndpointPatchBody
} from "newnewyork";
import { DaypartsArray, Omit, PaceStrategy } from "types";

/* eslint-disable camelcase */
import {
  Daypart,
  DEFAULT_EMPTY_TRACKING_PIXELS,
  DerivedLineItemStatus,
  dollarsToSatoshis,
  fillInEmptyTrackingTypes,
  filterOutEmptyTrackingUrls,
  findClickthroughFromEventsLegacy,
  invertDayparts,
  isActionRequiredStatus,
  KnownOrganizationIds,
  maybeAddClickthroughToTrackersLegacy,
  ObjType,
  OrganizationType,
  parseNewNewYorkApiResponse,
  RemoteConfigProperties,
  removeWhitespace,
  satoshisToDollars,
  ServiceStatus,
  ServiceSummaryFilters,
  trim
} from "@madhive/mad-sdk";
import calculateUserShouldBeAbleToSetInstructionLive from "./campaigns/calculateUserShouldBeAbleToSetInstructionLive";
import transformRawCampaignDescendant from "./campaigns/transformRawCampaignDescendant";
import {
  ADBOOK_CAMPAIGNS_BASE_URL,
  BASE_API_URL,
  BASE_HERMES_URL
} from "./constants";
import { logErrorToStackdriver } from "./logErrors";
import {
  reconcileMadSdkCampaignCache,
  reconcileMadSdkLineItemCache
} from "./reconcileMadSdk";

// PK TODO: Update these with other filter fields we need
export enum CampaignFilterFieldsInProto {
  NAME = "name",
  OWNERS = "owners",
  START = "start",
  END = "end",
  NULL = ""
}

export const deriveParamsForCampaigns = (params?: Record<string, string>) => {
  if (!params) return "";
  return `?${convertRecordToQueryString(params)}`;
};

export const deriveFiltersForCampaigns = (
  filters?: Record<string, CampaignFilterFieldsInProto>
) => {
  if (!filters) return "";

  const existingFilterLength = Object.values(filters).filter(isTruthy).length;
  if (existingFilterLength) {
    return `&fields=${Object.values(filters).filter(isTruthy).join(",")}`;
  }
  return "";
};

// PK: Passing in filters via campaigns is done as such:
// ?fields=id,name
export const getRawRootCampaigns = async (
  cancellationToken: CancelTokenSource,
  params?: Record<string, string>,
  filters?: Record<string, CampaignFilterFieldsInProto>
): Promise<CampaignsEndpointGetResponse> =>
  axios
    .get(
      `${BASE_API_URL}/campaigns${deriveParamsForCampaigns(
        params
      )}${deriveFiltersForCampaigns(filters)}`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        cancelToken: cancellationToken.token
      }
    )
    .then((res: AxiosResponse) => {
      try {
        const { data: campaigns, pagingInfo } =
          parseNewNewYorkApiResponse<RootCampaignRaw[]>(res);

        return { campaigns, pagingInfo };
      } catch (e) {
        throw Error(
          "Failed to retrieve campaigns. Please try again at a later time."
        );
      }
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        throw e;
      }
    });

export const getRawRootLineItems = async (
  cancellationToken?: CancelTokenSource,
  params?: Record<string, string>,
  filters?: Record<string, CampaignFilterFieldsInProto>
): Promise<LineItemsEndpointGetResponse> =>
  axios
    .get(
      `${BASE_API_URL}/lineitems${deriveParamsForCampaigns(
        params
      )}${deriveFiltersForCampaigns(filters)}`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        ...(cancellationToken ? { cancelToken: cancellationToken.token } : {})
      }
    )
    .then((res: AxiosResponse) => {
      try {
        const { data: lineItems, pagingInfo } =
          parseNewNewYorkApiResponse<CampaignDescendantRaw[]>(res);
        return { lineItems, pagingInfo };
      } catch (e) {
        throw Error(
          "Failed to retrieve line items. Please try again at a later time."
        );
      }
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        throw e;
      }
    });

export const getCampaignDetails = async (
  campaignId: string,
  cancellationToken: CancelTokenSource,
  options?: { includeArchived?: boolean }
) =>
  await axios
    .get(
      `${BASE_API_URL}/campaign/${campaignId}?includeArchived=${!!options?.includeArchived}`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        cancelToken: cancellationToken.token
      }
    )
    .then((res: AxiosResponse) => {
      const { data: campaignDetail } =
        parseNewNewYorkApiResponse<CampaignIdEndpointGetResponse>(res);
      return campaignDetail;
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

export const getCampaignAudit = async (
  campaignId: string
): Promise<CampaignAudit[]> =>
  await axios
    .get(`${BASE_API_URL}/campaign/${campaignId}/audit`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((res: AxiosResponse) => res.data.data as CampaignAudit[]);

export const getLineItemAudit = async (
  lineItemId: string
): Promise<LineItemAudit[]> =>
  await axios
    .get(`${BASE_API_URL}/lineitem/${lineItemId}/audit`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((res: AxiosResponse) => res.data.data)
    .catch((e: AxiosError) => e);

export const formatDaypartForEndpoint = (
  operator: LogicalOperator,
  dayparts: DaypartsArray
): Daypart[] => {
  const shapedDayparts = dayparts.reduce<Daypart[]>((acc, cur) => {
    if (cur.hours.length) {
      acc.push({
        day: cur.day, // Endpoint requires days of the weeks to start with a capital letter.
        hours: cur.hours
      });
    }
    return acc;
  }, []);

  /**
   * The default state in the UI is for nothing to be selected, assume that if
   * that is the selection, that they intended to neither include nor exclude nothing.
   */
  const noDaypartsSelected = shapedDayparts.length === 0;

  /**
   * shapedDayparts will filter out days with no selections, so check based on the
   * passed in dayparts instead.
   */
  const allHoursExcluded =
    dayparts.every(daypart => daypart.hours.length === 24) &&
    operator === LogicalOperator.EXCLUDE;

  /** Excluding all hours isn't a valid option, return a sensible default in that case. */
  if (noDaypartsSelected || allHoursExcluded) {
    return [];
  }

  return operator === LogicalOperator.INCLUDE
    ? invertDayparts(shapedDayparts)
    : shapedDayparts;
};

export const formatCampaignForUpdate = (
  campaign: RootCampaign
): UpdateCampaignEndpointPatchBody => {
  if (!campaign.details.data) {
    throw new Error(
      "Attempted to save a campaign without having daypart information first."
    );
  }

  const externalMetadata: Partial<
    CommonCampaignExternalMetadata &
      PremionCampaignExternalMetadata &
      ScrippsCampaignExternalMedatata &
      SalesforceCampaignExternalMetadata &
      FoxCampaignExternalMetadata
  > = {
    client_code: campaign.premionFieldClientCode
      ? campaign.premionFieldClientCode.trim()
      : undefined,
    client_estimate_code: campaign.estimateCode
      ? campaign.estimateCode.trim()
      : "",
    revenue_type: campaign.premionFieldRevenueType
      ? campaign.premionFieldRevenueType.trim()
      : undefined,
    rfp_detail_name: campaign.premionFieldRfpDetailName
      ? campaign.premionFieldRfpDetailName.trim()
      : undefined,
    rfp_name: campaign.premionFieldRfpName
      ? campaign.premionFieldRfpName.trim()
      : undefined,
    adbook_market: campaign.scrippsFieldAdbookMarket
      ? campaign.scrippsFieldAdbookMarket.trim()
      : undefined,
    /** Adbook Client and Adbook Status are not-editable */
    placements_io_id: campaign.premionPlacementsIoId
      ? campaign.premionPlacementsIoId.trim()
      : undefined,
    adbook_status: campaign.scrippsFieldAdbookStatus
      ? campaign.scrippsFieldAdbookStatus.trim()
      : undefined,
    adbook_client_name: campaign.scrippsFieldAdbookClient
      ? campaign.scrippsFieldAdbookClient.trim()
      : undefined,
    adbook_wide_orbit_id: campaign.scrippsWideOrbitId
      ? campaign.scrippsWideOrbitId.trim()
      : undefined,
    adbook_package_id: campaign.scrippsFieldAdbookPackageId
      ? campaign.scrippsFieldAdbookPackageId.trim()
      : undefined,
    adbook_server_mt_alias: campaign.scrippsFieldAdbookPackageName
      ? campaign.scrippsFieldAdbookPackageName.trim()
      : undefined,
    adbook_adbook_id: campaign.scrippsFieldAdbookId
      ? campaign.scrippsFieldAdbookId.trim()
      : undefined,
    adbook_segment_notes: campaign.scrippsFieldAdbookSegmentNotes
      ? campaign.scrippsFieldAdbookSegmentNotes.trim()
      : undefined,
    adbook_geo: campaign.scrippsFieldAdbookGeo
      ? campaign.scrippsFieldAdbookGeo.trim()
      : undefined,
    adbook_geo_ids: campaign.scrippsFieldAdbookGeo
      ? campaign.scrippsFieldAdbookGeo.trim()
      : undefined,
    adbook_agency_id: campaign.scrippsFieldAdbookAgencyId
      ? campaign.scrippsFieldAdbookAgencyId.trim()
      : undefined,
    adbook_external_advertiser_id:
      campaign.scrippsFieldAdbookExternalAdvertiserId
        ? campaign.scrippsFieldAdbookExternalAdvertiserId.trim()
        : undefined,
    adbook_campaign_id: campaign.scrippsFieldAdbookCampaignId
      ? campaign.scrippsFieldAdbookCampaignId.trim()
      : undefined,
    adbook_advertiser: campaign.scrippsFieldAdbookAdvertiser
      ? campaign.scrippsFieldAdbookAdvertiser.trim()
      : undefined,
    adbook_station: campaign.scrippsFieldAdbookStation
      ? campaign.scrippsFieldAdbookStation.trim()
      : undefined,
    adbook_drop_id: campaign.scrippsFieldAdbookDropId
      ? campaign.scrippsFieldAdbookDropId.trim()
      : undefined,
    adbook_station_id: campaign.scrippsFieldAdbookStationId
      ? campaign.scrippsFieldAdbookStationId.trim()
      : undefined,
    adbook_line_item_status: campaign.scrippsFieldAdbookLineItemStatus
      ? campaign.scrippsFieldAdbookLineItemStatus.trim()
      : undefined,
    adbook_scripps_advertiser_id: campaign.scrippsFieldAdbookScrippsId
      ? campaign.scrippsFieldAdbookScrippsId.trim()
      : undefined,
    adbook_advertiser_id: campaign.scrippsFieldAdbookAdvertiserId
      ? campaign.scrippsFieldAdbookAdvertiserId.trim()
      : undefined,
    adbook_price_type: campaign.scrippsFieldAdbookPriceType
      ? campaign.scrippsFieldAdbookPriceType.trim()
      : undefined,
    adbook_price: campaign.scrippsFieldAdbookPrice
      ? campaign.scrippsFieldAdbookPrice.trim()
      : undefined,
    adbook_gross_price: campaign.scrippsFieldAdbookGrossPrice
      ? campaign.scrippsFieldAdbookGrossPrice.trim()
      : undefined,
    adbook_demo_audience: campaign.scrippsFieldAdbookStnDemoAudience
      ? campaign.scrippsFieldAdbookStnDemoAudience.trim()
      : undefined,
    adbook_consumer_audience: campaign.scrippsFieldAdbookStnConsumerAudience
      ? campaign.scrippsFieldAdbookStnConsumerAudience.trim()
      : undefined,
    adbook_custom_segment: campaign.scrippsFieldAdbookCustomSegment
      ? campaign.scrippsFieldAdbookCustomSegment.trim()
      : undefined,
    adbook_custom_segment_notes: campaign.scrippsFieldAdbookCustomSegmentNotes
      ? campaign.scrippsFieldAdbookCustomSegmentNotes.trim()
      : undefined,

    postal_codes: campaign.scrippsFieldAdbookPostalCodes
      ? campaign.scrippsFieldAdbookPostalCodes.trim()
      : undefined,
    adbook_revision: campaign.scrippsFieldAdbookRevision
      ? campaign.scrippsFieldAdbookRevision.trim()
      : undefined,
    adbook_last_updated: campaign.scrippsFieldAdbookLastUpdated
      ? campaign.scrippsFieldAdbookLastUpdated.trim()
      : undefined,
    adbook_agency_name: campaign.scrippsFieldAdbookAgencyName
      ? campaign.scrippsFieldAdbookAgencyName.trim()
      : undefined,
    adbook_package_position_path: campaign.scrippsFieldAdbookPackagePositionPath
      ? campaign.scrippsFieldAdbookPackagePositionPath.trim()
      : undefined,
    adbook_position_name: campaign.scrippsFieldAdbookPositionName
      ? campaign.scrippsFieldAdbookPositionName.trim()
      : undefined,
    adbook_advertiser_domain: campaign.scrippsFieldAdbookAdvertiserDomain
      ? campaign.scrippsFieldAdbookAdvertiserDomain.trim()
      : undefined,
    adbook_package_level: campaign.scrippsFieldAdbookPackageLevel
      ? campaign.scrippsFieldAdbookPackageLevel.trim()
      : undefined,
    adbook_last_changed_by: campaign.scrippsFieldAdbookLastChangedBy
      ? campaign.scrippsFieldAdbookLastChangedBy.trim()
      : undefined,
    adbook_advertiser_whitelist: campaign.scrippsFieldAdbookAdvertiserWhitelist
      ? campaign.scrippsFieldAdbookAdvertiserWhitelist.trim()
      : undefined,
    adbook_whitelist_name: campaign.scrippsFieldAdbookAdvertiserWhitelistName
      ? campaign.scrippsFieldAdbookAdvertiserWhitelistName.trim()
      : undefined,
    adbook_product: campaign.scrippsFieldAdbookProduct
      ? campaign.scrippsFieldAdbookProduct.trim()
      : undefined,
    adbook_contract_date: campaign.scrippsFieldAdbookContractDate
      ? campaign.scrippsFieldAdbookContractDate.trim()
      : undefined,

    salesforce_insertion_order_id: campaign.salesforceInsertionOrderId
      ? campaign.salesforceInsertionOrderId.trim()
      : undefined,

    fox_holding_company: campaign.foxHoldingCompany
      ? campaign.foxHoldingCompany
      : undefined,

    product_code: campaign.productCode ? campaign.productCode : "",
    // empty string is required to clear the values in ext_metas
    brand: campaign.brand?.toString(),
    estimate: campaign.estimate?.toString()
  };

  return {
    id: campaign.id,
    advertiser_id: campaign.advertiserId,
    booked: campaign.bookedImpressions,
    dayparts: formatDaypartForEndpoint(
      campaign.daypartOperator,
      campaign.details.data && campaign.details.data.dayparts
    ),
    owners: uniq(
      [
        campaign.organizationId,
        campaign.stationId,
        campaign.agencyId,
        campaign.advertiserId
      ].filter(isTruthy)
    ),
    external_id: campaign.externalId,
    end_date: campaign.endDate.toISOString(),
    start_date: campaign.startDate.toISOString(),
    start_asap: campaign.startASAP,
    name: campaign.name.trim(),
    oms_id: campaign.externalOrderManagementSystemId.trim(),
    status: campaign.rawStatus,
    parent: campaign.details.data.parent,
    version: campaign.version,
    ext_metas: externalMetadata,
    frequencies: []
  };
};

export const formatCampaignForCreation = (
  campaign: DraftCampaign,
  id: string
): CreateCampaignEndpointPostBody => {
  const externalMetadata: Partial<
    CommonCampaignExternalMetadata &
      PremionCampaignExternalMetadata &
      ScrippsCampaignExternalMedatata &
      FoxCampaignExternalMetadata
  > = {
    client_code: campaign.premionFieldClientCode
      ? campaign.premionFieldClientCode.trim()
      : undefined,
    client_estimate_code: campaign.estimateCode
      ? campaign.estimateCode.trim()
      : "",
    revenue_type: campaign.premionFieldRevenueType
      ? campaign.premionFieldRevenueType.trim()
      : undefined,
    rfp_detail_name: campaign.premionFieldRfpDetailName
      ? campaign.premionFieldRfpDetailName.trim()
      : undefined,
    rfp_name: campaign.premionFieldRfpName
      ? campaign.premionFieldRfpName.trim()
      : undefined,
    adbook_market: campaign.scrippsFieldAdbookMarket
      ? campaign.scrippsFieldAdbookMarket.trim()
      : undefined,
    placements_io_id: campaign.premionPlacementsIoId
      ? campaign.premionPlacementsIoId.trim()
      : undefined,
    fox_holding_company: campaign.foxHoldingCompany
      ? campaign.foxHoldingCompany.trim()
      : undefined,
    product_code: campaign.productCode ? campaign.productCode.trim() : "",
    brand: campaign.brand?.toString() || undefined,
    estimate: campaign.estimate?.toString() || undefined,
    cash_or_trade: campaign.estimate?.toString() || undefined,
    advertiser_code: campaign.estimate?.toString() || undefined

    /** Adbook Client and Adbook Status are not-editable */
  };

  const owners = new Set<string>();
  if (campaign.organizationId) {
    owners.add(campaign.organizationId);
  }

  if (campaign.stationId) {
    owners.add(campaign.stationId);
  }

  if (campaign.agencyId) {
    owners.add(campaign.agencyId);
  }

  if (campaign.advertiserId) {
    owners.add(campaign.advertiserId);
  }

  return {
    id,
    advertiser_id: campaign.advertiserId,
    dayparts: formatDaypartForEndpoint(
      campaign.daypartOperator,
      campaign.dayparts
    ),
    external_id: campaign.externalId ? campaign.externalId.trim() : "",
    end_date: campaign.endDate.toISOString(),
    start_date: campaign.startDate.toISOString(),
    start_asap: campaign.startASAP,
    /**
     * Setting frequency on the campaign level is currently not supported.
     */
    name: campaign.name.trim(),
    oms_id: campaign.orderManagementSystemId
      ? campaign.orderManagementSystemId.trim()
      : "",
    booked: campaign.bookedImpressions,
    status: 100,
    // Needs to check for empty string since this is an optional property
    owners: Array.from(owners),
    version: 0,
    parent: campaign.parent,
    ext_metas: externalMetadata
  };
};

export const updateCampaign = async (campaign: RootCampaign) =>
  await axios
    .post(
      `${BASE_API_URL}/campaign/${campaign.id}`,
      formatCampaignForUpdate(campaign),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    .then((res: AxiosResponse) => {
      try {
        // Returns an object of id and name, leaving it as returning an object in case we need either properties
        const { data: campaignData } = parseNewNewYorkApiResponse<void>(res);

        reconcileMadSdkCampaignCache(campaignData);
        return campaignData;
      } catch (e) {
        throw Error("Error updating campaign, please try again.");
      }
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

export const createCampaign = async (
  campaign: DraftCampaign,
  id: string,
  cancellationToken: CancelTokenSource
) =>
  await axios
    .post(
      `${BASE_API_URL}/campaign/${id}`,
      formatCampaignForCreation(campaign, id),
      {
        headers: {
          "Content-Type": "application/json"
        },
        cancelToken: cancellationToken.token
      }
    )
    .then((res: AxiosResponse) => {
      try {
        const { data: campaignData } =
          parseNewNewYorkApiResponse<CreateCampaignEndpointPostResponse>(res);
        return campaignData;
      } catch (e) {
        throw Error("Error creating campaign, please try again.");
      }
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

export const duplicateCampaign = async (
  campaign: DraftCampaign,
  parentId: string,
  newId: string,
  cancellationToken: CancelTokenSource
) =>
  await axios
    .post(
      `${BASE_API_URL}/campaign/${parentId}/duplicate`,
      formatCampaignForCreation(campaign, newId),
      {
        headers: {
          "Content-Type": "application/json"
        },
        cancelToken: cancellationToken.token
      }
    )
    .then((res: AxiosResponse) => {
      try {
        const { data: campaignData } =
          parseNewNewYorkApiResponse<CreateCampaignEndpointPostResponse>(res);
        return campaignData;
      } catch (e) {
        throw Error("Error duplicating campaign, please try again.");
      }
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

const convertDateToSecondsFormat = (date: Date | undefined) => ({
  seconds: Math.floor((date?.getTime() || 0) / 1000)
});

export const formatBulkEditForSaving = (
  items: QuickEditsPayload[]
): BulkEditPatchBody => {
  const payload = items.map<BulkEditPatchItem>(item => {
    const { creatives } = item as QuickEditLineItemPayload;
    const isChangingStartAsapOnLineItemStartDate =
      !!item.startDate && item.startASAP === false;

    const unfilteredPatchBody: BulkEditPatchItem = {
      booked: (item as QuickEditLineItemPayload).bookedImpressions,
      end_date: (item as QuickEditLineItemPayload).endDate,
      id: item.id,
      budget: (item as QuickEditLineItemPayload).budget,
      start_date: (item as QuickEditLineItemPayload).startDate,
      start_asap: (item as QuickEditLineItemPayload).startASAP,
      status: item.status,
      version: item.version,
      creatives:
        Array.isArray(creatives) && creatives.length > 0
          ? creatives.map(el => {
              const wasCreativePreviouslySetToStartAsap = el.startASAP === true;
              const shouldMatchCreativeStartDateWithLineItem =
                isChangingStartAsapOnLineItemStartDate &&
                wasCreativePreviouslySetToStartAsap;

              return {
                weight: Math.floor(el.weight * 100),
                start: shouldMatchCreativeStartDateWithLineItem
                  ? convertDateToSecondsFormat(item.startDate)
                  : convertDateToSecondsFormat(el.startDate),
                end: convertDateToSecondsFormat(el.endDate),
                start_asap: shouldMatchCreativeStartDateWithLineItem
                  ? false
                  : el.startASAP,
                creative_vid_id: el.creativeId,
                // [PK] Need to set ID as blank since backend is minting IDs/we're getting rid of this in near future since they no longer are 1st class
                id: el.id ? el.id : "",
                parent: el.attachedLineItemId || "",
                event_urls: maybeAddClickthroughToTrackersLegacy(
                  filterOutEmptyTrackingUrls(
                    el.trackingPixels.map(element => ({
                      type: element.type,
                      urls: element.urls.map(url => url.trim())
                    }))
                  ),
                  el.clickThroughUrl
                ),
                status: el.status
              };
            })
          : // [PK] Talked to Darren, passing in null for cases where creative flight shouldn't exist
            // on inst (e.g campaigns will never have their creatives: property filled with anything since they're all attached at LI level)
            null
    };

    /** To reduce noise we send to the backend, delete keys where the value would be undefined. */
    return deleteKeysWithUndefinedValues(unfilteredPatchBody);
  });

  return {
    data: payload
  };
};

export const updateBulkInstructions = async (
  data: QuickEditsPayload[],
  cancellationToken: CancelTokenSource
) =>
  axios
    .patch(`${BASE_API_URL}/campaigns`, formatBulkEditForSaving(data), {
      headers: {
        "Content-Type": "application/json"
      },
      cancelToken: cancellationToken.token
    })
    .then((res: AxiosResponse) => {
      try {
        const { data: bulkInstData }: { data: any[] } =
          parseNewNewYorkApiResponse(res);

        // Response does not discern between campaigns and lineitems so checking both caches.
        reconcileMadSdkCampaignCache(bulkInstData);
        reconcileMadSdkLineItemCache(bulkInstData);

        return bulkInstData;
      } catch (e) {
        throw Error("Error updating campaigns, please try again.");
      }
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

export const getLineItemDetails = async (
  lineItemId: string,
  cancellationToken: CancelTokenSource,
  includeArchived?: boolean
) =>
  axios
    .get(
      `${BASE_API_URL}/lineitem/${lineItemId}?includeArchived=${!!includeArchived}`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        cancelToken: cancellationToken.token
      }
    )
    .then((res: AxiosResponse) => {
      try {
        const { data: lineItemDetails } =
          parseNewNewYorkApiResponse<LineItemIdEndpointGetResponse>(res);
        return lineItemDetails;
      } catch (e) {
        throw Error("Error fetching line item details, please try again.");
      }
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

/**
 * @param lineItem: a line item.
 * @return: the adbok, brand, postal code, placement and other external metadata extracted from the line item. If not provided via the line item, set to undefined.
 */
const getExternalMetadata = (
  lineItem: LineItemFormattedForAttachingCreatives
): Partial<
  PremionLineItemExternalMetadata &
    ScrippsLineItemExternalMetadata &
    ScrippsCampaignExternalMedatata &
    FoxLineItemExternalMetadata &
    GroundTruthLineItemExternalMetadata
> => ({
  rfp_name: trim(lineItem.premionFieldRfpName) || undefined,
  adbook_package: trim(lineItem.scrippsFieldAdbookPackageId) || undefined,

  salesforce_geo_label: trim(lineItem.premionGeoTargetingNotes) || undefined,
  placements_io_id: trim(lineItem.premionPlacementsIoId) || undefined,

  adbook_status: trim(lineItem.scrippsFieldAdbookStatus) || undefined,
  adbook_market: trim(lineItem.scrippsFieldAdbookMarket) || undefined,
  adbook_client_name: trim(lineItem.scrippsFieldAdbookClient) || undefined,
  adbook_wide_orbit_id: trim(lineItem.scrippsWideOrbitId) || undefined,
  adbook_package_id: trim(lineItem.scrippsFieldAdbookPackageId) || undefined,
  adbook_server_mt_alias:
    trim(lineItem.scrippsFieldAdbookPackageName) || undefined,
  adbook_adbook_id: trim(lineItem.scrippsFieldAdbookId) || undefined,
  adbook_segment_notes:
    trim(lineItem.scrippsFieldAdbookSegmentNotes) || undefined,
  adbook_geo: trim(lineItem.scrippsFieldAdbookGeo) || undefined,
  adbook_geo_ids: trim(lineItem.scrippsFieldAdbookGeo) || undefined,
  adbook_agency_id: trim(lineItem.scrippsFieldAdbookAgencyId) || undefined,
  adbook_external_advertiser_id:
    trim(lineItem.scrippsFieldAdbookExternalAdvertiserId) || undefined,
  adbook_campaign_id: trim(lineItem.scrippsFieldAdbookCampaignId) || undefined,
  adbook_advertiser: trim(lineItem.scrippsFieldAdbookAdvertiser) || undefined,
  adbook_station: trim(lineItem.scrippsFieldAdbookStation) || undefined,
  adbook_drop_id: trim(lineItem.scrippsFieldAdbookDropId) || undefined,
  adbook_station_id: trim(lineItem.scrippsFieldAdbookStationId) || undefined,
  adbook_line_item_status:
    trim(lineItem.scrippsFieldAdbookLineItemStatus) || undefined,
  adbook_scripps_advertiser_id:
    trim(lineItem.scrippsFieldAdbookScrippsId) || undefined,
  adbook_advertiser_id:
    trim(lineItem.scrippsFieldAdbookAdvertiserId) || undefined,
  adbook_price_type: trim(lineItem.scrippsFieldAdbookPriceType) || undefined,
  adbook_price: trim(lineItem.scrippsFieldAdbookPrice) || undefined,
  adbook_gross_price: trim(lineItem.scrippsFieldAdbookGrossPrice) || undefined,
  adbook_demo_audience:
    trim(lineItem.scrippsFieldAdbookStnDemoAudience) || undefined,
  adbook_consumer_audience:
    trim(lineItem.scrippsFieldAdbookStnConsumerAudience) || undefined,
  adbook_custom_segment:
    trim(lineItem.scrippsFieldAdbookCustomSegment) || undefined,
  adbook_custom_segment_notes:
    trim(lineItem.scrippsFieldAdbookCustomSegmentNotes) || undefined,

  postal_codes: trim(lineItem.scrippsFieldAdbookPostalCodes) || undefined,
  adbook_revision: trim(lineItem.scrippsFieldAdbookRevision) || undefined,
  adbook_last_updated:
    trim(lineItem.scrippsFieldAdbookLastUpdated) || undefined,
  adbook_agency_name: trim(lineItem.scrippsFieldAdbookAgencyName) || undefined,
  adbook_package_position_path:
    trim(lineItem.scrippsFieldAdbookPackagePositionPath) || undefined,
  adbook_position_name:
    trim(lineItem.scrippsFieldAdbookPositionName) || undefined,
  adbook_advertiser_domain:
    trim(lineItem.scrippsFieldAdbookAdvertiserDomain) || undefined,
  adbook_package_level:
    trim(lineItem.scrippsFieldAdbookPackageLevel) || undefined,
  adbook_last_changed_by:
    trim(lineItem.scrippsFieldAdbookLastChangedBy) || undefined,
  adbook_advertiser_whitelist:
    trim(lineItem.scrippsFieldAdbookAdvertiserWhitelist) || undefined,
  adbook_whitelist_name:
    trim(lineItem.scrippsFieldAdbookAdvertiserWhitelistName) || undefined,
  adbook_product: trim(lineItem.scrippsFieldAdbookProduct) || undefined,

  adbook_contract_date:
    trim(lineItem.scrippsFieldAdbookContractDate) || undefined,
  wideorbit_line_number: trim(lineItem.foxFieldWideorbitLineId) || undefined,
  brands: lineItem.groundTruthBrands,
  location_groups: lineItem.groundTruthLocationGroups,
  booked_pace_strategy: lineItem.bookedPaceStrategy
  // PK: Will bring this back once we allow users to toggle DV
  // (After speaking with Elan, as of 02 / 24 / 21 we no longer want any line items saved with this attribute, regardless of if it was there or not)
  // double_verify: lineItem.scrippsIsDoubleVerify ? "true" : "false"
});

export const formatLineItemForCreateOrUpdate = (
  lineItem: LineItemFormattedForAttachingCreatives
): CreateAndUpdateLineItemEndpointPostBody => {
  const externalMetadata: Partial<
    PremionLineItemExternalMetadata &
      ScrippsLineItemExternalMetadata &
      ScrippsCampaignExternalMedatata &
      FoxLineItemExternalMetadata &
      GroundTruthLineItemExternalMetadata
  > = getExternalMetadata(lineItem);
  const archivedAndNonArchivedCreativeFlights =
    lineItem.attachedCreatives.concat(...lineItem.archivedCreatives);

  const { includeIds: include_ids, excludeIds: exclude_ids } =
    lineItem.inventorySet || {};

  return {
    actualized: lineItem.actualizedImpressions,
    booked: lineItem.bookedImpressions,
    budget: dollarsToSatoshis(lineItem.budget),
    ecpm: dollarsToSatoshis(lineItem.effectiveCpm),
    goal_interval: lineItem.goalInterval,
    creatives: archivedAndNonArchivedCreativeFlights.map(el => ({
      weight: Math.floor(el.weight * 100),
      start: convertDateToSecondsFormat(el.startDate),
      end: convertDateToSecondsFormat(el.endDate),
      start_asap: el.startASAP,
      creative_vid_id: el.creativeId,
      // [PK] Need to set ID as blank since backend is minting IDs/we're getting rid of this in near future since they no longer are 1st class
      id: el.id ? el.id : "",
      parent: el.attachedLineItemId || "",
      event_urls: maybeAddClickthroughToTrackersLegacy(
        filterOutEmptyTrackingUrls(
          el.trackingPixels.map(element => ({
            type: element.type,
            urls: element.urls.map(url => url.trim())
          }))
        ),
        el.clickThroughUrl
      ),
      status: el.status
    })),
    dayparts: formatDaypartForEndpoint(
      lineItem.daypartOperator,
      lineItem.dayparts
    ),
    start_date: lineItem.startDate?.toISOString(),
    end_date: lineItem.endDate?.toISOString(),
    external_id: lineItem.externalId,
    frequencies: lineItem.frequencies,
    geo_include: OPERATOR_TO_FINALIZE_GEO_FN[LogicalOperator.INCLUDE](
      lineItem.geoTargeting
    ),
    geo_exclude: OPERATOR_TO_FINALIZE_GEO_FN[LogicalOperator.EXCLUDE](
      lineItem.geoTargeting
    ),
    id: lineItem.id,
    name: lineItem.name.trim(),
    oms_id: lineItem.orderManagementSystemId.trim(),
    parent: lineItem.parentCampaignId,
    segments: lineItem.appliedSegmentIds || [],
    start_asap: lineItem.startASAP,
    // Type '{ [x: string]: number; }' is missing the following properties from type 'DeviceCaps': tv, desktop, mobile, tabletts(2739)
    // @ts-expect-error
    device_caps: mapValues(lineItem.deviceType, val =>
      val >= 0 && typeof val === "number" ? val * 100 : 0
    ),
    product_id: lineItem.productId,
    whitelist_id: lineItem.whitelistId,
    status:
      lineItem.status === CampaignStatus.LIVE
        ? 200
        : lineItem.status === CampaignStatus.PAUSED
        ? 300
        : lineItem.rawStatus,
    version: lineItem.version,
    ext_metas: externalMetadata,
    tpa: lineItem.tpa,
    tpa_id: lineItem.tpaId,
    inventory_set: {
      include_ids,
      exclude_ids
    },
    scenario_id: lineItem.scenarioId
  };
};

export const updateLineItem = async (
  lineItem: LineItemFormattedForAttachingCreatives,
  cancellationToken: CancelTokenSource
) =>
  axios
    .post(
      `${BASE_API_URL}/lineitem/${lineItem.id}`,
      formatLineItemForCreateOrUpdate(lineItem),
      {
        headers: {
          "Content-Type": "application/json"
        },
        cancelToken: cancellationToken.token
      }
    )
    .then((res: AxiosResponse) => {
      const { data: lineItemDataAfterUpdate } = parseNewNewYorkApiResponse(res);

      reconcileMadSdkLineItemCache(lineItemDataAfterUpdate);

      return lineItemDataAfterUpdate;
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

type DSPResult = Array<{
  org_id: string;
  url: string;
}>;

export const getLineItemTPA = (
  lineItem: LineItemFormattedForAttachingCreatives,
  org_ids: string[]
): Promise<DSPResult> =>
  axios
    .post(
      `${BASE_API_URL}/generateTpaUrl`,
      JSON.stringify({
        lineItem: formatLineItemForCreateOrUpdate(lineItem),
        org_ids
      }),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    .then((res: AxiosResponse) => res.data.data);

// Only need to accept shallow line item since creatives are at the line item level. If a campaign is picked, it'll attach all line items so no need to pass in campaigns here
const formatBulkAssignForSaving = (
  lineItems: ShallowLineItem[],
  isForTemplate: boolean
): BulkEditPatchBody => {
  const lineItemPayload = lineItems.map<BulkEditPatchItem>(item => {
    const creatives = item.attachedCreatives;
    const unfilteredPatchBody: BulkEditPatchItem = {
      booked: item.bookedImpressions,
      end_date: item.endDate,
      id: item.id,
      start_date: item.startDate,
      status: item.rawStatus,
      version: item.version,
      name: item.name ? item.name : "",
      campaign_name: item.campaignName ? item.campaignName : "",
      creatives:
        Array.isArray(creatives) && creatives.length > 0
          ? creatives
              // PK: Backend will now populate our bulk assign attached creatives with existing, just need to send back newly attached
              // UNLESS it's for PATCH, since that is a WIP we want it to send all creative flights back. Else it'll get overwritten.
              .filter(cr => (isForTemplate ? cr.isNewlyAttached : true))
              .map(el => ({
                weight:
                  // [PK] When it is for template and hasn't finished serving, should always default to 100. Product team wanted this
                  isForTemplate &&
                  (el.endDate?.valueOf() || 0) > new Date().valueOf()
                    ? 100
                    : // For template but it is finished serving or is non-template bulk assign? Default to multiplying by 100
                      Math.floor(el.weight * 100),
                end: convertDateToSecondsFormat(el.endDate),
                start: convertDateToSecondsFormat(el.startDate),
                start_asap: el.startASAP,
                creative_vid_id: el.creativeId,
                // [PK] Need to set ID as blank since backend is minting IDs/we're getting rid of this in near future since they no longer are 1st class
                id: el.id ? el.id : "",
                name: el.name,
                parent: el.attachedLineItemId || "",
                event_urls: maybeAddClickthroughToTrackersLegacy(
                  filterOutEmptyTrackingUrls(
                    el.trackingPixels.map(element => ({
                      type: element.type,
                      urls: element.urls.map(url => url.trim())
                    }))
                  ),
                  el.clickThroughUrl
                ),
                status: el.status,
                new: el.isNewlyAttached
              }))
          : // [PK] Talked to Darren, passing in null for cases where creative flight shouldn't exist
            // on inst (e.g campaigns will never have their creatives: property filled with anything since they're all attached at LI level)
            null
    };

    /** To reduce noise we send to the backend, delete keys where the value would be undefined. */
    return deleteKeysWithUndefinedValues(unfilteredPatchBody);
  });

  return {
    data: lineItemPayload
  };
};

export const createLineItem = async (
  lineItem: LineItemFormattedForAttachingCreatives,
  id: string,
  cancellationToken: CancelTokenSource
) =>
  axios
    .post(
      `${BASE_API_URL}/lineitem/${id}`,
      formatLineItemForCreateOrUpdate(lineItem),
      {
        headers: {
          "Content-Type": "application/json"
        },
        cancelToken: cancellationToken.token
      }
    )
    .then((res: AxiosResponse) => {
      const { data: lineItemDataAfterCreation } =
        parseNewNewYorkApiResponse<CreateAndUpdateLineItemEndpointPostBody>(
          res
        );

      // Reconcile the campaign that the new lineitem is being attached to.
      lineItemDataAfterCreation.parent &&
        reconcileMadSdkCampaignCache({ id: lineItemDataAfterCreation.parent });
      return lineItemDataAfterCreation;
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

export const archiveCampaign = async (
  campaignId: CampaignId,
  cancellationToken: CancelTokenSource
) =>
  axios
    .delete(`${BASE_API_URL}/campaign/${campaignId}`, {
      cancelToken: cancellationToken.token
    })
    .then((res: AxiosResponse) => {
      try {
        const { data: campaignDataAfterDeletion } =
          parseNewNewYorkApiResponse(res);

        reconcileMadSdkCampaignCache({ id: campaignId });

        return campaignDataAfterDeletion;
      } catch (e) {
        throw Error("Failed to archive campaign, please try again.");
      }
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

const formatInstForStatusChange = (
  inst: RootCampaign | ShallowLineItem,
  newStatus: ServiceStatus
) => ({
  id: inst.id,
  version: inst.version,
  status: newStatus
});

const formatForCampaignStatusChange = (
  campaign: RootCampaign,
  newStatus: ServiceStatus
) => [
  formatInstForStatusChange(campaign, newStatus),
  ...campaign.lineItems.map(lineItem =>
    formatInstForStatusChange(lineItem, newStatus)
  )
];

export const unArchiveCampaign = async (campaign: RootCampaign) =>
  axios
    .patch(
      `${BASE_API_URL}/campaigns`,
      { data: formatForCampaignStatusChange(campaign, ServiceStatus.DRAFT) },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    .then((res: AxiosResponse) => {
      try {
        const { data: bulkInstData } = parseNewNewYorkApiResponse(res);
        return bulkInstData;
      } catch (e) {
        throw Error("Error updating campaign, please try again.");
      }
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

export const archiveLineItem = async (
  lineItemId: LineItemId,
  cancellationToken: CancelTokenSource
) =>
  axios
    .delete(`${BASE_API_URL}/lineitem/${lineItemId}`, {
      cancelToken: cancellationToken.token
    })
    .then((res: AxiosResponse) => {
      try {
        const { data: lineItemDataAfterDeletion } =
          parseNewNewYorkApiResponse(res);

        reconcileMadSdkLineItemCache(lineItemDataAfterDeletion);

        return lineItemDataAfterDeletion;
      } catch (e) {
        throw Error("Failed to archive line item, please try again.");
      }
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

export const getReportingCreatives = async (
  id: string,
  filters: ServiceSummaryFilters
) =>
  axios
    .post(`${BASE_HERMES_URL}/dashboard/creative/${id}`, filters, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((res: AxiosResponse) => res.data)
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

export const getQAReport = async () =>
  axios
    .get(`${BASE_API_URL}/qaexport`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/json"
      }
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

export const getBulkAssignTemplate = async (lineItems: ShallowLineItem[]) => {
  const params = { iabCategories: true };

  return axios
    .post(
      `${BASE_API_URL}/populateTemplate`,
      formatBulkAssignForSaving(lineItems, true),
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/json"
        },
        params
      }
    )
    .then((res: AxiosResponse) => res.data)
    .catch((e: AxiosError) => {
      throw e;
    });
};

export const getCampaignTemplate = async () => {
  const queryParams = convertRecordToQueryString({
    budget: await isPaceToBudgetEnabled(),
    multiFreqCap: await featureFlag(
      RemoteConfigProperties.TARGETING_MULTIPLE_FREQ_CAP
    )
  });

  return axios
    .get(`${BASE_API_URL}/template/campaignUpload?${queryParams}`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/json"
      }
    })
    .then((res: AxiosResponse) => res.data)
    .catch((e: AxiosError) => {
      throw e;
    });
};

export const uploadCampaignTemplate = async (blob: Blob) => {
  const queryParams = convertRecordToQueryString({
    budget: await isPaceToBudgetEnabled()
  });

  return axios
    .post(`${BASE_API_URL}/template/campaignUpload?${queryParams}`, blob, {
      headers: {
        Accept: "application/json"
      }
    })
    .then((res: AxiosResponse) => res.data)
    .catch((e: AxiosError) => {
      throw e;
    });
};

export const uploadBulkAssignTemplate = async (blob: Blob) =>
  axios
    .post(`${BASE_API_URL}/submitTemplate`, blob, {
      headers: {
        Accept: "application/json"
      }
    })
    .then((res: AxiosResponse) => res.data)
    .catch((e: AxiosError) => {
      throw e;
    });

export const updateInstructionsWithBulkAssignCreatives = async (
  data: ShallowLineItem[],
  cancellationToken: CancelTokenSource
) =>
  axios
    .patch(
      `${BASE_API_URL}/campaigns`,
      formatBulkAssignForSaving(data, false),
      {
        headers: {
          "Content-Type": "application/json"
        },
        cancelToken: cancellationToken.token
      }
    )
    .then((res: AxiosResponse) => {
      reconcileMadSdkLineItemCache(data);
      return parseNewNewYorkApiResponse(res);
    })
    .catch((e: AxiosError) => {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else {
        logErrorToStackdriver(e);
        throw e;
      }
    });

const DEFAULT_CAMPAIGN_METADATA_FIELDS: Partial<
  CommonCampaignExternalMetadata &
    PremionCampaignExternalMetadata &
    ScrippsCampaignExternalMedatata
> = {
  client_code: undefined,
  client_estimate_code: undefined,
  product_code: undefined,
  rfp_name: undefined,
  rfp_detail_name: undefined,
  revenue_type: undefined,
  adbook_consumer: undefined,
  adbook_cpm: undefined,
  adbook_demo: undefined,
  adbook_geo: undefined,
  adbook_package: undefined,
  adbook_product: undefined,
  adbook_status: undefined,
  adbook_market: undefined,
  wide_orbit_id: undefined
};

const DEFAULT_LINEITEM_METADATA_FIELDS: Partial<
  PremionLineItemExternalMetadata &
    ScrippsLineItemExternalMetadata &
    FoxLineItemExternalMetadata &
    GroundTruthLineItemExternalMetadata
> = {
  rfp_name: undefined,
  adbook_package: undefined
};

// PK: If no creatives exist, it has no active creatives
// If the # of non-active creatives == total # of creatives, there are no active creatives
export const doesNoActiveCreativeExist = (
  creatives: RawLineItemCreative[] | null
) =>
  creatives
    ? creatives.filter(
        cr =>
          cr.status === RawInstructionStatus.DELIVERABLE &&
          new Date((cr.end?.seconds || 0) * 1000).valueOf() <
            new Date().valueOf()
      ).length === creatives.length
    : true;

const getCampaignStatusByCode = (status: number): CampaignStatus =>
  INSTRUCTION_STATUS_CHECKBOX_OPTIONS.find(
    checkboxOption => checkboxOption.id === status.toString()
  )?.label || CampaignStatus.ARCHIVED;

/** Data transformation functions that can optionally be called by consumers of these API functions */
export const transformRawRootCampaign = (
  rawRootCampaign: RootCampaignRaw
): Omit<
  RootCampaign,
  | "ownerIdsWithTypes"
  | "advertiserId"
  | "stationId"
  | "agencyId"
  | "organizationId"
> => {
  const metadataFields: Partial<
    CommonCampaignExternalMetadata &
      PremionCampaignExternalMetadata &
      ScrippsCampaignExternalMedatata &
      FoxCampaignExternalMetadata &
      FoxLineItemExternalMetadata &
      SalesforceCampaignExternalMetadata &
      WideOrbitCampaignExternalMetadata &
      FoxCampaignExternalMetadata
  > = {
    ...DEFAULT_CAMPAIGN_METADATA_FIELDS,
    ...rawRootCampaign.ext_metas
  };

  const lineItemsAssociatedWithCampaign = rawRootCampaign.descendants
    ? Object.values(rawRootCampaign.descendants).map(unformattedLineItem =>
        transformRawCampaignDescendant(
          unformattedLineItem,
          rawRootCampaign.id,
          rawRootCampaign.name
        )
      )
    : [];

  // TODO: Can skip this check for non-wideorbit orgs, will have to modify upstream calls as well.
  const wideOrbitEntries: Record<
    keyof WideOrbitCampaignExternalMetadata,
    string
  > = Object.entries(metadataFields)
    .filter(el => el[0].includes("wideorbit"))
    .reduce((acc, cur) => {
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      acc[cur[0]] = cur[1];
      return acc;
    }, {} as Record<keyof WideOrbitCampaignExternalMetadata, string>);

  const isDoubleVerified = !!lineItemsAssociatedWithCampaign.find(
    el => el.scrippsIsDoubleVerify
  );
  const {
    client_code: premionFieldClientCode,
    client_estimate_code: estimateCode,
    product_code: productCode,
    revenue_type: premionFieldRevenueType,
    rfp_detail_name: premionFieldRfpDetails,
    rfp_name: premionFieldRfpName,
    adbook_status: scrippsFieldAdbookStatus,
    adbook_market: scrippsFieldAdbookMarket,
    adbook_client_name: scrippsFieldAdbookClient,
    adbook_wide_orbit_id: scrippsWideOrbitId,
    adbook_package_id: scrippsFieldAdbookPackageId,
    adbook_server_mt_alias: scrippsFieldAdbookPackageName,
    adbook_segment_notes: scrippsFieldAdbookSegmentNotes,
    adbook_geo: scrippsFieldAdbookGeo,
    adbook_geo_ids: scrippsFieldAdbookGeoIds,
    adbook_agency_id: scrippsFieldAdbookAgencyId,
    adbook_adbook_id: scrippsFieldAdbookId,
    adbook_external_advertiser_id: scrippsFieldAdbookExternalAdvertiserId,
    adbook_campaign_id: scrippsFieldAdbookCampaignId,
    adbook_advertiser: scrippsFieldAdbookAdvertiser,
    adbook_client_name: scrippsFieldAdbookClientName,
    adbook_drop_id: scrippsFieldAdbookDropId,
    adbook_station: scrippsFieldAdbookStation,
    adbook_line_item_status: scrippsFieldAdbookLineItemStatus,
    adbook_station_id: scrippsFieldAdbookStationId,
    adbook_scripps_advertiser_id: scrippsFieldAdbookScrippsId,
    adbook_advertiser_id: scrippsFieldAdbookAdvertiserId,

    adbook_price_type: scrippsFieldAdbookPriceType,
    adbook_price: scrippsFieldAdbookPrice,
    adbook_gross_price: scrippsFieldAdbookGrossPrice,
    adbook_demo_audience: scrippsFieldAdbookStnDemoAudience,
    adbook_consumer_audience: scrippsFieldAdbookStnConsumerAudience,
    adbook_custom_segment: scrippsFieldAdbookCustomSegment,
    adbook_custom_segment_notes: scrippsFieldAdbookCustomSegmentNotes,
    postal_codes: scrippsFieldAdbookPostalCodes,
    adbook_revision: scrippsFieldAdbookRevision,
    adbook_last_updated: scrippsFieldAdbookLastUpdated,
    adbook_agency_name: scrippsFieldAdbookAgencyName,
    adbook_package_position_path: scrippsFieldAdbookPackagePositionPath,
    adbook_position_name: scrippsFieldAdbookPositionName,
    adbook_advertiser_domain: scrippsFieldAdbookAdvertiserDomain,
    adbook_package_level: scrippsFieldAdbookPackageLevel,
    adbook_last_changed_by: scrippsFieldAdbookLastChangedBy,
    adbook_advertiser_whitelist: scrippsFieldAdbookAdvertiserWhitelist,
    adbook_whitelist_name: scrippsFieldAdbookAdvertiserWhitelistName,
    adbook_product: scrippsFieldAdbookProduct,
    adbook_contract_date: scrippsFieldAdbookContractDate,
    placements_io_id: premionPlacementsIoId,
    salesforce_insertion_order_id: salesforceInsertionOrderId,
    fox_holding_company: foxHoldingCompany,
    brand,
    estimate,
    cash_or_trade,
    advertiser_code
  } = metadataFields;

  const sumOfCreativesOnAllAssociatedLineItems = sum(
    lineItemsAssociatedWithCampaign.map(lineItem => lineItem.creativesCount)
  );

  const sumOfBookedImpressionsOnUnarchivedAssociatedLineItems = sum(
    lineItemsAssociatedWithCampaign
      .filter(el => el.status !== CampaignStatus.ARCHIVED)
      .map(lineItem => lineItem.bookedImpressions)
  );

  const numberOfLiveLineItems = lineItemsAssociatedWithCampaign.filter(
    lineItem => lineItem.status === CampaignStatus.LIVE
  ).length;

  /** We need to do this transformation in steps. Getting the IDs is an async operation. */
  const campaignWithoutDerivedStatus: Omit<
    RootCampaign,
    | "ownerIdsWithTypes"
    | "isUserEditable"
    | "userCanSetLive"
    | "advertiserId"
    | "stationId"
    | "agencyId"
    | "organizationId"
  > = {
    id: rawRootCampaign.id,
    isAdbookOrder: !!(
      rawRootCampaign.ext_metas && rawRootCampaign.ext_metas.adbook_campaign_id
    ),
    bookedImpressions: rawRootCampaign.booked || 0,
    bookedImpressionsAsSumOfDescendantsBookedImpressions:
      sumOfBookedImpressionsOnUnarchivedAssociatedLineItems,
    creativesCount: sumOfCreativesOnAllAssociatedLineItems,
    lineItemsCount: lineItemsAssociatedWithCampaign.length,
    liveLineItemsCount: numberOfLiveLineItems,
    startDate: new Date(rawRootCampaign.start_date),
    startASAP: !!rawRootCampaign.start_asap,
    endDate: new Date(rawRootCampaign.end_date),
    status: getCampaignStatusByCode(
      rawRootCampaign.campaign_validation.derived_status
    ),
    warningCount: rawRootCampaign.campaign_validation.warnings || 0,
    madhiveId: rawRootCampaign.id,
    name: rawRootCampaign.name,
    externalOrderManagementSystemId: rawRootCampaign.oms_id,
    premionPlacementsIoId: premionPlacementsIoId
      ? premionPlacementsIoId.toString()
      : undefined,
    hasFrequencyCap: false, // Campaigns never have a frequency cap
    details: {
      data: null,
      isLoading: false,
      error: false
    },
    dateLastFetched: new Date(),
    budget: rawRootCampaign.budget,
    externalId: rawRootCampaign.external_id,
    rawStatus: rawRootCampaign.status,
    daypartOperator: LogicalOperator.INCLUDE,
    ownerIdsMadhiveEncrypted: rawRootCampaign.owners || [],
    lastUpdated: new Date(rawRootCampaign.updated),
    updatedBy: rawRootCampaign.updated_by,
    version: rawRootCampaign.version,
    parent: rawRootCampaign.parent,
    isDoubleVerify: isDoubleVerified,

    scrippsFieldAdbookClient: scrippsFieldAdbookClient
      ? scrippsFieldAdbookClient.toString()
      : undefined,
    scrippsFieldAdbookMarket: scrippsFieldAdbookMarket
      ? scrippsFieldAdbookMarket.toString()
      : undefined,
    scrippsFieldAdbookStatus: scrippsFieldAdbookStatus
      ? scrippsFieldAdbookStatus.toString()
      : undefined,
    scrippsWideOrbitId: scrippsWideOrbitId
      ? scrippsWideOrbitId.toString()
      : undefined,

    scrippsFieldAdbookPackageId: scrippsFieldAdbookPackageId
      ? scrippsFieldAdbookPackageId.toString()
      : undefined,

    scrippsFieldAdbookPackageName: scrippsFieldAdbookPackageName
      ? scrippsFieldAdbookPackageName.toString()
      : undefined,
    scrippsFieldAdbookSegmentNotes: scrippsFieldAdbookSegmentNotes
      ? scrippsFieldAdbookSegmentNotes.toString()
      : undefined,

    scrippsFieldAdbookGeo: scrippsFieldAdbookGeo
      ? scrippsFieldAdbookGeo.toString()
      : undefined,
    scrippsFieldAdbookGeoIds: scrippsFieldAdbookGeoIds
      ? scrippsFieldAdbookGeoIds.toString()
      : undefined,

    scrippsFieldAdbookAgencyId: scrippsFieldAdbookAgencyId
      ? scrippsFieldAdbookAgencyId.toString()
      : undefined,
    scrippsFieldAdbookId: scrippsFieldAdbookId
      ? scrippsFieldAdbookId.toString()
      : undefined,
    scrippsFieldAdbookExternalAdvertiserId:
      scrippsFieldAdbookExternalAdvertiserId
        ? scrippsFieldAdbookExternalAdvertiserId.toString()
        : undefined,

    scrippsFieldAdbookCampaignId: scrippsFieldAdbookCampaignId
      ? scrippsFieldAdbookCampaignId.toString()
      : undefined,

    scrippsFieldAdbookAdvertiser: scrippsFieldAdbookAdvertiser
      ? scrippsFieldAdbookAdvertiser.toString()
      : undefined,
    scrippsFieldAdbookStation: scrippsFieldAdbookStation
      ? scrippsFieldAdbookStation.toString()
      : undefined,

    scrippsFieldAdbookClientName: scrippsFieldAdbookClientName
      ? scrippsFieldAdbookClientName.toString()
      : undefined,

    scrippsFieldAdbookDropId: scrippsFieldAdbookDropId
      ? scrippsFieldAdbookDropId.toString()
      : undefined,
    scrippsFieldAdbookStationId: scrippsFieldAdbookStationId
      ? scrippsFieldAdbookStationId.toString()
      : undefined,

    scrippsFieldAdbookLineItemStatus: scrippsFieldAdbookLineItemStatus
      ? scrippsFieldAdbookLineItemStatus.toString()
      : undefined,

    scrippsFieldAdbookScrippsId: scrippsFieldAdbookScrippsId
      ? scrippsFieldAdbookScrippsId.toString()
      : undefined,
    scrippsFieldAdbookAdvertiserId: scrippsFieldAdbookAdvertiserId
      ? scrippsFieldAdbookAdvertiserId.toString()
      : undefined,

    scrippsFieldAdbookPriceType: scrippsFieldAdbookPriceType
      ? scrippsFieldAdbookPriceType.toString()
      : undefined,

    scrippsFieldAdbookPrice: scrippsFieldAdbookPrice
      ? scrippsFieldAdbookPrice.toString()
      : undefined,
    scrippsFieldAdbookGrossPrice: scrippsFieldAdbookGrossPrice
      ? scrippsFieldAdbookGrossPrice.toString()
      : undefined,

    scrippsFieldAdbookStnDemoAudience: scrippsFieldAdbookStnDemoAudience
      ? scrippsFieldAdbookStnDemoAudience.toString()
      : undefined,

    scrippsFieldAdbookStnConsumerAudience: scrippsFieldAdbookStnConsumerAudience
      ? scrippsFieldAdbookStnConsumerAudience.toString()
      : undefined,

    scrippsFieldAdbookCustomSegment: scrippsFieldAdbookCustomSegment
      ? scrippsFieldAdbookCustomSegment.toString()
      : undefined,

    scrippsFieldAdbookCustomSegmentNotes: scrippsFieldAdbookCustomSegmentNotes
      ? scrippsFieldAdbookCustomSegmentNotes.toString()
      : undefined,

    scrippsFieldAdbookPostalCodes: scrippsFieldAdbookPostalCodes
      ? scrippsFieldAdbookPostalCodes.toString()
      : undefined,

    scrippsFieldAdbookProduct: scrippsFieldAdbookProduct
      ? scrippsFieldAdbookProduct.toString()
      : undefined,

    scrippsFieldAdbookRevision: scrippsFieldAdbookRevision
      ? scrippsFieldAdbookRevision.toString()
      : undefined,

    scrippsFieldAdbookLastUpdated: scrippsFieldAdbookLastUpdated
      ? scrippsFieldAdbookLastUpdated.toString()
      : undefined,

    scrippsFieldAdbookAgencyName: scrippsFieldAdbookAgencyName
      ? scrippsFieldAdbookAgencyName.toString()
      : undefined,
    scrippsFieldAdbookPackagePositionPath: scrippsFieldAdbookPackagePositionPath
      ? scrippsFieldAdbookPackagePositionPath.toString()
      : undefined,
    scrippsFieldAdbookPositionName: scrippsFieldAdbookPositionName
      ? scrippsFieldAdbookPositionName.toString()
      : undefined,
    scrippsFieldAdbookAdvertiserDomain: scrippsFieldAdbookAdvertiserDomain
      ? scrippsFieldAdbookAdvertiserDomain.toString()
      : undefined,
    scrippsFieldAdbookPackageLevel: scrippsFieldAdbookPackageLevel
      ? scrippsFieldAdbookPackageLevel.toString()
      : undefined,
    scrippsFieldAdbookLastChangedBy: scrippsFieldAdbookLastChangedBy
      ? scrippsFieldAdbookLastChangedBy.toString()
      : undefined,

    scrippsFieldAdbookAdvertiserWhitelistName:
      scrippsFieldAdbookAdvertiserWhitelistName
        ? scrippsFieldAdbookAdvertiserWhitelistName.toString()
        : undefined,
    scrippsFieldAdbookAdvertiserWhitelist: scrippsFieldAdbookAdvertiserWhitelist
      ? scrippsFieldAdbookAdvertiserWhitelist.toString()
      : undefined,

    lineItems: lineItemsAssociatedWithCampaign,
    premionFieldClientCode: premionFieldClientCode
      ? premionFieldClientCode.toString()
      : undefined,
    estimateCode: estimateCode ? estimateCode.toString() : undefined,
    productCode: productCode ? productCode.toString() : undefined,
    premionFieldRevenueType: premionFieldRevenueType
      ? premionFieldRevenueType.toString()
      : undefined,
    premionFieldRfpDetailName: premionFieldRfpDetails
      ? premionFieldRfpDetails.toString()
      : undefined,
    premionFieldRfpName: premionFieldRfpName
      ? premionFieldRfpName.toString()
      : undefined,
    foxFieldWideorbitLineId: undefined,
    scrippsFieldAdbookContractDate: scrippsFieldAdbookContractDate
      ? scrippsFieldAdbookContractDate.toString()
      : undefined,
    foxHoldingCompany: foxHoldingCompany
      ? foxHoldingCompany.toString()
      : undefined,

    salesforceInsertionOrderId: salesforceInsertionOrderId?.toString(),
    wideOrbitAttributes: wideOrbitEntries,
    // PK TODO: What makes it a wide orbit order?
    isWideOrbitOrder: !!(
      rawRootCampaign.ext_metas && metadataFields.wideorbit_order_number
    ),
    brand: brand?.toString() || undefined,
    estimate: estimate?.toString() || undefined,
    iab_category_rtb_ids: rawRootCampaign.iab_category_rtb_ids,
    cashOrTrade: cash_or_trade?.toString(),
    advertiserCode: advertiser_code?.toString()
  };

  return {
    ...campaignWithoutDerivedStatus,
    isUserEditable: !UNEDITABLE_CAMPAIGN_STATUSES.has(
      campaignWithoutDerivedStatus.status
    ),
    userCanSetLive: calculateUserShouldBeAbleToSetInstructionLive(
      campaignWithoutDerivedStatus.status
    )
  };
};

const DAYPART_UI_TEMPLATE: Record<
  string,
  {
    day: string;
    hours: number[];
  }
> = {
  Sunday: { day: DayOfTheWeek.SUNDAY, hours: [] },
  Monday: { day: DayOfTheWeek.MONDAY, hours: [] },
  Tuesday: { day: DayOfTheWeek.TUESDAY, hours: [] },
  Wednesday: { day: DayOfTheWeek.WEDNESDAY, hours: [] },
  Thursday: { day: DayOfTheWeek.THURSDAY, hours: [] },
  Friday: { day: DayOfTheWeek.FRIDAY, hours: [] },
  Saturday: { day: DayOfTheWeek.SATURDAY, hours: [] }
};

export const transformDaypartFromEndpoint = (
  dayparts: Array<{
    day: DayOfTheWeek;
    hours: number[];
  }> = []
): DaypartsArray => {
  if (!dayparts || !Array.isArray(dayparts) || dayparts.length === 0) {
    // Returning all filled dayparts instead of empty since we're now defaulting to include
    return Object.values(FILLED_DAYPARTS) as DaypartsArray;
  }
  /** The operator for dayparts from the backend is always DaypartOperator.EXCLUDE so need to transform
   *  since new requirement says to default to Include now
   */

  /** The UI needs every day to be present, regardless of whether or not its empty. */
  const includedDaypartsWithAllDays = Object.values(
    invertDayparts(dayparts).reduce(
      (acc, cur) => ({
        ...acc,
        [cur.day]: {
          day: cur.day,
          hours: cur.hours
        }
      }),
      DAYPART_UI_TEMPLATE
    )
  );

  return includedDaypartsWithAllDays as DaypartsArray;
};

/**
 * Most things about a campaign actually show up in the shallow data. This
 * function extracts and formats only the things that don't show up in the
 * response. from the shallow endpoint.
 * */
export const transformRawCampaignDetails = (
  rawCampaignDetails: CampaignIdEndpointGetResponse
): CampaignDetailSpecificInfo => ({
  parent: rawCampaignDetails.parent,
  dayparts: transformDaypartFromEndpoint(rawCampaignDetails.dayparts || [])
});

export const transformRawLineItemDetails = (
  rawLineItemDetails: LineItemIdEndpointGetResponse,
  productIdToProductNameMap: Record<string, Entity>
): LineItemFormatted => {
  const startDate = new Date(rawLineItemDetails.start_date);
  const endDate = new Date(rawLineItemDetails.end_date);

  const derivedStatus =
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    CampaignStatus[
      DerivedLineItemStatus[rawLineItemDetails.derived_statuses![0]]
    ];

  const metadataFields: Partial<
    PremionLineItemExternalMetadata &
      ScrippsCampaignExternalMedatata &
      ScrippsLineItemExternalMetadata &
      FoxLineItemExternalMetadata &
      GroundTruthLineItemExternalMetadata
  > = {
    ...DEFAULT_LINEITEM_METADATA_FIELDS,
    ...rawLineItemDetails.ext_metas
  };

  // TODO: Can skip this check for non-wideorbit orgs, will have to modify upstream calls as well.
  const wideOrbitEntries: Record<
    keyof WideOrbitLineItemExternalMetadata,
    string
  > = Object.entries(metadataFields)
    .filter(el => el[0].includes("wideorbit"))
    .reduce((acc, cur) => {
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      acc[cur[0]] = cur[1];
      return acc;
    }, {} as Record<keyof WideOrbitLineItemExternalMetadata, string>);

  const {
    adbook_status: scrippsFieldAdbookStatus,
    adbook_market: scrippsFieldAdbookMarket,
    adbook_client_name: scrippsFieldAdbookClient,
    adbook_wide_orbit_id: scrippsWideOrbitId,
    adbook_package_id: scrippsFieldAdbookPackageId,
    adbook_server_mt_alias: scrippsFieldAdbookPackageName,
    adbook_adbook_id: scrippsFieldAdbookId,
    adbook_segment_notes: scrippsFieldAdbookSegmentNotes,
    adbook_geo: scrippsFieldAdbookGeo,
    adbook_geo_ids: scrippsFieldAdbookGeoIds,
    adbook_agency_id: scrippsFieldAdbookAgencyId,
    adbook_external_advertiser_id: scrippsFieldAdbookExternalAdvertiserId,
    adbook_campaign_id: scrippsFieldAdbookCampaignId,
    adbook_advertiser: scrippsFieldAdbookAdvertiser,
    adbook_station: scrippsFieldAdbookStation,
    adbook_client_name: scrippsFieldAdbookClientName,
    adbook_drop_id: scrippsFieldAdbookDropId,
    adbook_station_id: scrippsFieldAdbookStationId,
    adbook_line_item_status: scrippsFieldAdbookLineItemStatus,
    adbook_scripps_advertiser_id: scrippsFieldAdbookScrippsId,
    adbook_advertiser_id: scrippsFieldAdbookAdvertiserId, // eslint-disable-line no-unused-vars

    adbook_price_type: scrippsFieldAdbookPriceType,
    adbook_price: scrippsFieldAdbookPrice,
    adbook_gross_price: scrippsFieldAdbookGrossPrice,
    adbook_demo_audience: scrippsFieldAdbookStnDemoAudience,
    adbook_consumer_audience: scrippsFieldAdbookStnConsumerAudience,
    adbook_custom_segment: scrippsFieldAdbookCustomSegment,
    adbook_custom_segment_notes: scrippsFieldAdbookCustomSegmentNotes,
    placements_io_id: premionPlacementsIoId,
    placements_io_group_id: premionPlacementsIoGroupId,
    postal_codes: scrippsFieldAdbookPostalCodes,
    adbook_revision: scrippsFieldAdbookRevision,
    adbook_last_updated: scrippsFieldAdbookLastUpdated,
    adbook_agency_name: scrippsFieldAdbookAgencyName,
    adbook_package_position_path: scrippsFieldAdbookPackagePositionPath,
    adbook_position_name: scrippsFieldAdbookPositionName,
    adbook_advertiser_domain: scrippsFieldAdbookAdvertiserDomain,
    adbook_package_level: scrippsFieldAdbookPackageLevel,
    adbook_last_changed_by: scrippsFieldAdbookLastChangedBy,
    adbook_advertiser_whitelist: scrippsFieldAdbookAdvertiserWhitelist,
    adbook_whitelist_name: scrippsFieldAdbookAdvertiserWhitelistName,
    adbook_product: scrippsFieldAdbookProduct,
    adbook_contract_date: scrippsFieldAdbookContractDate,
    double_verify: scrippsIsDoubleVerify,
    wideorbit_line_number: foxFieldWideorbitLineId,
    brands: groundTruthBrands,
    location_groups: groundTruthLocationGroups
  } = metadataFields;

  const geoTargetingInclude = rawLineItemDetails.geo_include
    ? {
        country: rawLineItemDetails.geo_include.country || "",
        districts: rawLineItemDetails.geo_include.congressional_districts || [],
        dmaCodes: rawLineItemDetails.geo_include.dmas || [],
        states: rawLineItemDetails.geo_include.states || [],
        zipCodes: rawLineItemDetails.geo_include.zips || []
      }
    : {
        country: "",
        districts: [],
        dmaCodes: [],
        states: [],
        zipCodes: []
      };

  const geoTargetingExclude = rawLineItemDetails.geo_exclude
    ? {
        country: rawLineItemDetails.geo_exclude.country || "",
        districts: rawLineItemDetails.geo_exclude.congressional_districts || [],
        dmaCodes: rawLineItemDetails.geo_exclude.dmas || [],
        states: rawLineItemDetails.geo_exclude.states || [],
        zipCodes: rawLineItemDetails.geo_exclude.zips || []
      }
    : {
        country: "",
        districts: [],
        dmaCodes: [],
        states: [],
        zipCodes: []
      };

  const attachedCreatives = (rawLineItemDetails.creatives || [])
    .map(
      (rawCreative): LineItemCreative => ({
        id: rawCreative.id,
        creativeId: rawCreative.creative_vid_id,
        endDate: rawCreative.end?.seconds
          ? new Date(rawCreative.end.seconds * 1000)
          : undefined,
        startDate: rawCreative.start?.seconds
          ? new Date(rawCreative.start.seconds * 1000)
          : undefined,
        startASAP: !!rawCreative.start_asap,
        weight: rawCreative.weight / 100,
        clickThroughUrl: removeWhitespace(
          findClickthroughFromEventsLegacy(rawCreative.event_urls)
        ),
        attachedLineItemId: rawLineItemDetails.id,
        // There are instances where event_urls will come back as null from backend
        trackingPixels: rawCreative.event_urls
          ? fillInEmptyTrackingTypes(rawCreative.event_urls)
          : DEFAULT_EMPTY_TRACKING_PIXELS,
        status: rawCreative.status
      })
    )
    .filter(item => item.status !== RawInstructionStatus.ARCHIVED);

  const archivedCreatives = (rawLineItemDetails.creatives || [])
    .filter(item => item.status === RawInstructionStatus.ARCHIVED)
    .map(
      (rawCreative): LineItemCreative => ({
        id: rawCreative.id,
        creativeId: rawCreative.creative_vid_id,
        endDate: rawCreative.end?.seconds
          ? new Date(rawCreative.end.seconds * 1000)
          : undefined,
        startDate: rawCreative.start?.seconds
          ? new Date(rawCreative.start.seconds * 1000)
          : undefined,
        startASAP: !!rawCreative.start_asap,
        weight: rawCreative.weight / 100,
        clickThroughUrl: findClickthroughFromEventsLegacy(
          rawCreative.event_urls
        ),
        attachedLineItemId: rawLineItemDetails.id,
        // There are instances where event_urls will come back as null from backend
        trackingPixels: rawCreative.event_urls
          ? fillInEmptyTrackingTypes(rawCreative.event_urls)
          : DEFAULT_EMPTY_TRACKING_PIXELS,
        status: rawCreative.status
      })
    );

  const { include_ids: includeIds = [], exclude_ids: excludeIds = [] } =
    rawLineItemDetails.inventory_set || {};

  const transformedLineItem: Omit<
    LineItemFormatted,
    "userCanSetLive" | "isUserEditable" | "criticalStatuses"
  > = {
    id: rawLineItemDetails.id,
    isAdbookOrder: !!(
      rawLineItemDetails.ext_metas &&
      rawLineItemDetails.ext_metas.adbook_campaign_id
    ),
    startDate,
    startASAP: !!rawLineItemDetails.start_asap,
    tpa: !!rawLineItemDetails.tpa,
    tpaId: rawLineItemDetails.tpa_id,
    endDate,
    name: rawLineItemDetails.name,
    effectiveCpm: satoshisToDollars(rawLineItemDetails.ecpm),
    formattedProductName:
      rawLineItemDetails.product_id &&
      productIdToProductNameMap[rawLineItemDetails.product_id]
        ? productIdToProductNameMap[rawLineItemDetails.product_id].name
        : "",
    parentCampaignId: rawLineItemDetails.parent,
    bookedImpressions: rawLineItemDetails.booked || 0,
    actualizedImpressions: rawLineItemDetails.actualized || 0,
    budget: satoshisToDollars(rawLineItemDetails.budget),
    bookedPaceStrategy:
      rawLineItemDetails.ext_metas?.booked_pace_strategy ||
      (rawLineItemDetails.budget
        ? PaceStrategy.BUDGET
        : PaceStrategy.IMPRESSIONS),
    goalInterval: rawLineItemDetails.goal_interval,
    orderManagementSystemId: rawLineItemDetails.oms_id,
    premionPlacementsIoId: premionPlacementsIoId
      ? premionPlacementsIoId.toString()
      : undefined,
    premionPlacementsIoGroupId: premionPlacementsIoGroupId?.toString(),
    geoTargeting: {
      exclude: geoTargetingExclude,
      include: geoTargetingInclude
    },
    dayparts: transformDaypartFromEndpoint(rawLineItemDetails.dayparts || []),
    appliedSegmentIds: rawLineItemDetails.segments || [],
    attachedCreatives,
    archivedCreatives,
    creativesCount: attachedCreatives.length,
    dateLastFetched: new Date(),
    daypartOperator: LogicalOperator.INCLUDE,
    externalId: rawLineItemDetails.external_id,
    frequencies: rawLineItemDetails.frequencies || [],
    hasFrequencyCap: true, // Assume every line item has a frequency cap.
    deviceType: {
      tv:
        rawLineItemDetails.device_caps &&
        rawLineItemDetails.device_caps.tv &&
        typeof rawLineItemDetails.device_caps.tv === "number"
          ? rawLineItemDetails.device_caps.tv / 100
          : 0,
      desktop:
        rawLineItemDetails.device_caps &&
        rawLineItemDetails.device_caps.desktop &&
        typeof rawLineItemDetails.device_caps.desktop === "number"
          ? rawLineItemDetails.device_caps.desktop / 100
          : 0,
      tablet:
        rawLineItemDetails.device_caps &&
        rawLineItemDetails.device_caps.tablet &&
        typeof rawLineItemDetails.device_caps.tablet === "number"
          ? rawLineItemDetails.device_caps.tablet / 100
          : 0,
      mobile:
        rawLineItemDetails.device_caps &&
        rawLineItemDetails.device_caps.mobile &&
        typeof rawLineItemDetails.device_caps.mobile === "number"
          ? rawLineItemDetails.device_caps.mobile / 100
          : 0
    },
    productId: rawLineItemDetails.product_id || "",
    whitelistId: rawLineItemDetails.whitelist_id,
    rawStatus: rawLineItemDetails.status,
    status: derivedStatus,
    version: rawLineItemDetails.version,
    premionFieldRfpName: metadataFields.rfp_name
      ? String(metadataFields.rfp_name)
      : undefined,
    packageId: "",
    premionGeoTargetingNotes: metadataFields.salesforce_geo_label
      ? String(metadataFields.salesforce_geo_label)
      : undefined,

    scrippsFieldAdbookClient: scrippsFieldAdbookClient
      ? scrippsFieldAdbookClient.toString()
      : undefined,
    scrippsFieldAdbookMarket: scrippsFieldAdbookMarket
      ? scrippsFieldAdbookMarket.toString()
      : undefined,
    scrippsFieldAdbookStatus,
    scrippsWideOrbitId: scrippsWideOrbitId
      ? scrippsWideOrbitId.toString()
      : undefined,

    scrippsFieldAdbookPackageId: scrippsFieldAdbookPackageId
      ? scrippsFieldAdbookPackageId.toString()
      : undefined,
    scrippsFieldAdbookPackageName: scrippsFieldAdbookPackageName
      ? scrippsFieldAdbookPackageName.toString()
      : undefined,
    scrippsFieldAdbookId: scrippsFieldAdbookId
      ? scrippsFieldAdbookId.toString()
      : undefined,

    scrippsFieldAdbookSegmentNotes: scrippsFieldAdbookSegmentNotes
      ? scrippsFieldAdbookSegmentNotes.toString()
      : undefined,

    scrippsFieldAdbookGeo: scrippsFieldAdbookGeo
      ? scrippsFieldAdbookGeo.toString()
      : undefined,
    scrippsFieldAdbookGeoIds: scrippsFieldAdbookGeoIds
      ? scrippsFieldAdbookGeoIds.toString()
      : undefined,

    scrippsFieldAdbookAgencyId: scrippsFieldAdbookAgencyId
      ? scrippsFieldAdbookAgencyId.toString()
      : undefined,

    scrippsFieldAdbookExternalAdvertiserId:
      scrippsFieldAdbookExternalAdvertiserId
        ? scrippsFieldAdbookExternalAdvertiserId.toString()
        : undefined,

    scrippsFieldAdbookCampaignId: scrippsFieldAdbookCampaignId
      ? scrippsFieldAdbookCampaignId.toString()
      : undefined,

    scrippsFieldAdbookAdvertiser: scrippsFieldAdbookAdvertiser
      ? scrippsFieldAdbookAdvertiser.toString()
      : undefined,
    scrippsFieldAdbookStation: scrippsFieldAdbookStation
      ? scrippsFieldAdbookStation.toString()
      : undefined,

    scrippsFieldAdbookClientName: scrippsFieldAdbookClientName
      ? scrippsFieldAdbookClientName.toString()
      : undefined,

    scrippsFieldAdbookDropId: scrippsFieldAdbookDropId
      ? scrippsFieldAdbookDropId.toString()
      : undefined,
    scrippsFieldAdbookStationId: scrippsFieldAdbookStationId
      ? scrippsFieldAdbookStationId.toString()
      : undefined,

    scrippsFieldAdbookScrippsId: scrippsFieldAdbookScrippsId
      ? scrippsFieldAdbookScrippsId.toString()
      : undefined,

    scrippsFieldAdbookPriceType: scrippsFieldAdbookPriceType
      ? scrippsFieldAdbookPriceType.toString()
      : undefined,

    scrippsFieldAdbookPrice: scrippsFieldAdbookPrice
      ? scrippsFieldAdbookPrice.toString()
      : undefined,
    scrippsFieldAdbookGrossPrice: scrippsFieldAdbookGrossPrice
      ? scrippsFieldAdbookGrossPrice.toString()
      : undefined,

    scrippsFieldAdbookStnDemoAudience: scrippsFieldAdbookStnDemoAudience
      ? scrippsFieldAdbookStnDemoAudience.toString()
      : undefined,

    scrippsFieldAdbookStnConsumerAudience: scrippsFieldAdbookStnConsumerAudience
      ? scrippsFieldAdbookStnConsumerAudience.toString()
      : undefined,

    scrippsFieldAdbookCustomSegment: scrippsFieldAdbookCustomSegment
      ? scrippsFieldAdbookCustomSegment.toString()
      : undefined,

    scrippsFieldAdbookCustomSegmentNotes: scrippsFieldAdbookCustomSegmentNotes
      ? scrippsFieldAdbookCustomSegmentNotes.toString()
      : undefined,

    scrippsFieldAdbookPostalCodes: scrippsFieldAdbookPostalCodes
      ? scrippsFieldAdbookPostalCodes.toString()
      : undefined,

    scrippsFieldAdbookProduct: scrippsFieldAdbookProduct
      ? scrippsFieldAdbookProduct.toString()
      : undefined,

    scrippsFieldAdbookRevision: scrippsFieldAdbookRevision
      ? scrippsFieldAdbookRevision.toString()
      : undefined,

    scrippsFieldAdbookLastUpdated: scrippsFieldAdbookLastUpdated
      ? scrippsFieldAdbookLastUpdated.toString()
      : undefined,

    scrippsFieldAdbookLineItemStatus: scrippsFieldAdbookLineItemStatus
      ? scrippsFieldAdbookLineItemStatus.toString()
      : undefined,

    scrippsFieldAdbookAgencyName: scrippsFieldAdbookAgencyName
      ? scrippsFieldAdbookAgencyName.toString()
      : undefined,
    scrippsFieldAdbookPackagePositionPath: scrippsFieldAdbookPackagePositionPath
      ? scrippsFieldAdbookPackagePositionPath.toString()
      : undefined,

    scrippsFieldAdbookPositionName: scrippsFieldAdbookPositionName
      ? scrippsFieldAdbookPositionName.toString()
      : undefined,
    scrippsFieldAdbookAdvertiserDomain: scrippsFieldAdbookAdvertiserDomain
      ? scrippsFieldAdbookAdvertiserDomain.toString()
      : undefined,
    scrippsFieldAdbookPackageLevel: scrippsFieldAdbookPackageLevel
      ? scrippsFieldAdbookPackageLevel.toString()
      : undefined,
    scrippsFieldAdbookLastChangedBy: scrippsFieldAdbookLastChangedBy
      ? scrippsFieldAdbookLastChangedBy.toString()
      : undefined,

    scrippsFieldAdbookAdvertiserWhitelistName:
      scrippsFieldAdbookAdvertiserWhitelistName
        ? scrippsFieldAdbookAdvertiserWhitelistName.toString()
        : undefined,

    scrippsFieldAdbookAdvertiserWhitelist: scrippsFieldAdbookAdvertiserWhitelist
      ? scrippsFieldAdbookAdvertiserWhitelist.toString()
      : undefined,

    foxFieldWideorbitLineId: foxFieldWideorbitLineId || undefined,
    scrippsFieldAdbookContractDate: scrippsFieldAdbookContractDate
      ? scrippsFieldAdbookContractDate.toString()
      : undefined,
    scrippsIsDoubleVerify: !!(
      scrippsIsDoubleVerify && scrippsIsDoubleVerify === "true"
    ),
    groundTruthBrands,
    groundTruthLocationGroups,
    wideOrbitAttributes: wideOrbitEntries,
    // PK TOOD: Determine how we find out if it's a wideorbit ingested line item, is it just checking on line_number?
    isWideOrbit: !!(
      rawLineItemDetails.ext_metas && metadataFields.wideorbit_line_number
    ),
    mediaType: rawLineItemDetails.media_type,
    inventorySet: {
      includeIds,
      excludeIds
    },
    iab_category_rtb_ids: rawLineItemDetails.iab_category_rtb_ids,
    scenarioId: rawLineItemDetails.scenario_id
  };

  return {
    ...transformedLineItem,
    criticalStatuses: rawLineItemDetails
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      .derived_statuses!.map(s => CampaignStatus[DerivedLineItemStatus[s]])
      .filter(s => isActionRequiredStatus(s)),
    userCanSetLive:
      calculateUserShouldBeAbleToSetInstructionLive(derivedStatus),
    isUserEditable: isCampaignDescendentEditable(
      rawLineItemDetails.owners || [],
      derivedStatus
    )
  };
};

type OwnerTypeWithKeys = {
  id: string;
  keyType: ObjType;
  orgType: OrganizationType | undefined;
};

/**
 * This function converts an array of owners id into a array of owners with keys
 * @param  {string[]} owners
 * @returns Promise
 */
export const getOwnerKeyTypes = async (
  owners: string[]
): Promise<OwnerTypeWithKeys[]> =>
  await Promise.all(
    owners.map(async ownerId => {
      const keyType = await madSDK.cryptography.getTypeFromKey(ownerId);

      return {
        id: ownerId,
        keyType,
        orgType:
          keyType === ObjType.ORG
            ? await madSDK.cryptography.getOrganizationTypeFromOrgKey(ownerId)
            : undefined
      };
    })
  );

type OwnerIds = {
  advertiserId: string;
  agencyId: string;
  stationIds: string[];
  stationGroupId: string;
  ownerIdsWithTypes: OwnerTypeWithKeys[];
};

/**
 * This function takes an array of owner ids and returns a key value object where each
 * type of owner is associated to its id(s)
 * @param  {string[]} owners
 * @returns Promise
 */
export const appendOwnerIdsToShallowCampaign = async (
  owners: string[],
  parent: string
): Promise<OwnerIds> => {
  /**
   * Convert the array of owner id into an array of OwnerTypeWithKeys (easier to parse)
   */
  const ownerKeyTypes = await getOwnerKeyTypes(owners);

  /**
   * Find the advertiser key among the owners
   */
  const keyWithAdvertiser = ownerKeyTypes.find(
    el => el.orgType === OrganizationType.ADVERTISER
  );

  /**
   * Find the agency key among the owners
   */
  const keyWithAgency = ownerKeyTypes
    .filter(el => el.orgType === OrganizationType.AGENCY)
    .find(agency => parent && agency.id !== parent);

  /**
   * Find all the station keys  among the owners
   */
  const stationKeys = ownerKeyTypes.filter(
    el => el.orgType === OrganizationType.STATION
  );

  /**
   * Find all the station group key  among the owners
   */
  const stationGroupKey = ownerKeyTypes.find(
    el => el.orgType === OrganizationType.STATION_GROUP
  );

  const advertiserId = keyWithAdvertiser ? keyWithAdvertiser.id : "";
  const agencyId = keyWithAgency ? keyWithAgency.id : "";
  const stationIds = stationKeys ? stationKeys.map(key => key.id) : [];
  const stationGroupId = stationGroupKey ? stationGroupKey.id : "";

  return {
    advertiserId,
    agencyId,
    stationIds,
    stationGroupId,
    ownerIdsWithTypes: ownerKeyTypes
  };
};

export const getAdbookCampaignData = async (
  currentOrgId: KnownOrganizationIds
): Promise<AdbookCampaignsEndpointGetResponse> => {
  try {
    const res = await axios.get(
      `${ADBOOK_CAMPAIGNS_BASE_URL}/v2/errors?org=${currentOrgId}`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return res.data;
  } catch (e) {
    throw Error("Failed to retrieve adbook campaigns.");
  }
};

export const editBulkLineItems = async (
  lineItems: PartialLineItemForBulk[],
  withChangeReport?: boolean
): Promise<any> => {
  const url = withChangeReport
    ? `${BASE_API_URL}/bulkLineItem`
    : `${BASE_API_URL}/campaigns`;

  const contentType = withChangeReport ? "text/csv" : "application/json";

  try {
    return await axios.patch(
      url,
      { data: lineItems },
      {
        headers: {
          "Content-Type": contentType
        }
      }
    );
  } catch (e) {
    throw e.response.data.visible_error
      ? e.response.data.errors.filter(isTruthy)
      : "Failed to save bulk line items data.";
  }
};

import { useRef, useContext, useState, useEffect } from "react";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { ToasterContext } from "lib/context";
import { madSDK } from "lib/sdk";
import { isValidDate, isValidFirestoreDate } from "lib/utils/date";
import {
  FirestoreNotificationStatuses,
  NOTIFICATION_ACTION_TYPE_TO_CUSTOM_ACTION,
  NOTIFICATION_CATEGORY_TO_TOAST_MESSAGE,
  NotificationCategory
} from "lib/utils/notifications";
import {
  SmithersNotificationActionsFormatted,
  SmithersNotificationActionsRaw,
  SmithersNotificationFormatted,
  SmithersNotificationRaw
} from "types";
import { User } from "@madhive/mad-sdk";
import { dateFromFirestoreDate } from "@madhive/mad-sdk/utils/dates";

const sortNotifications = (notifs: SmithersNotificationFormatted[]) => {
  if (!Array.isArray(notifs) || !notifs.length) {
    return;
  }

  return [...notifs].sort((a, b) => {
    if (a.read && !b.read) {
      return 1;
    }
    if (!a.read && b.read) {
      return -1;
    }
    if (a.read === b.read) {
      return isValidDate(a.createdDate) && isValidDate(b.createdDate)
        ? b.createdDate.valueOf() - a.createdDate.valueOf()
        : 0;
    }
    return 0;
  });
};

const formatNotificationActions = (
  rawNotificationActions: SmithersNotificationActionsRaw | undefined
): SmithersNotificationActionsFormatted => {
  if (
    typeof rawNotificationActions !== "object" ||
    !Object.keys(rawNotificationActions).length
  ) {
    return [];
  }

  return Object.keys(rawNotificationActions).reduce((acc, curr) => {
    if (typeof rawNotificationActions[curr] !== "object") {
      return acc;
    }

    /**
     * Set custom button text (if available). Otherwise, fallback to default settings
     * for this specific action.
     */
    const defaultButtonText =
      curr in NOTIFICATION_ACTION_TYPE_TO_CUSTOM_ACTION
        ? /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          NOTIFICATION_ACTION_TYPE_TO_CUSTOM_ACTION[curr].buttonText
        : "";

    const customButtonText =
      typeof rawNotificationActions[curr].buttonText === "string"
        ? rawNotificationActions[curr].buttonText
        : defaultButtonText;

    acc.push({
      ...rawNotificationActions[curr],
      actionId: parseInt(curr, 10),
      buttonText: customButtonText
    });

    return acc;
  }, [] as SmithersNotificationActionsFormatted);
};

export const useNotificationListener = (user: User | null) => {
  const timestampOfLastUpdate = useRef(Date.now());
  const { addToast } = useContext(ToasterContext);

  const [firestoreNotifications, setFirestoreNotifications] = useState<
    SmithersNotificationFormatted[]
  >([]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const unsubscribeFromFirestoreNotifications = madSDK.madFire.onSnapshot(
      "notifications",
      [
        { fieldPath: "recipientId", opStr: "==", value: user.email },
        {
          fieldPath: "status",
          opStr: "==",
          value: FirestoreNotificationStatuses.SENT
        }
      ],
      snapshot => {
        const notifications: QueryDocumentSnapshot[] = snapshot.docs;

        try {
          if (!Array.isArray(notifications) || !notifications.length) {
            setFirestoreNotifications([]);
            return;
          }

          const formattedNotifications = notifications.map(
            (doc): SmithersNotificationFormatted => {
              const data = doc.data() as SmithersNotificationRaw;

              return {
                title: data.title || "",
                actions: formatNotificationActions(data.actions),
                authorId: data.authorId || "",
                category: data.category || NotificationCategory.MAINTENANCE,
                content: data.content || "",
                createdDate: isValidFirestoreDate(data.createdDate)
                  ? dateFromFirestoreDate(data.createdDate)
                  : undefined,
                lastUpdatedDate: isValidFirestoreDate(data.lastUpdatedDate)
                  ? dateFromFirestoreDate(data.lastUpdatedDate)
                  : undefined,
                read: Boolean(data.read),
                recipientId: data.recipientId,
                id: doc.id,
                status: data.status,
                meta: data.meta || {}
              };
            }
          );

          const sortedNotifications = sortNotifications(formattedNotifications);
          if (sortedNotifications) {
            setFirestoreNotifications(sortedNotifications);
          }

          timestampOfLastUpdate.current = Date.now();
        } catch (e) {
          console.error(e);
        }
      }
    );

    return () => {
      unsubscribeFromFirestoreNotifications();
    };
  }, [user]);

  const latestNotifications = firestoreNotifications.filter(
    notification =>
      isValidDate(notification.createdDate) &&
      notification.createdDate.valueOf() > timestampOfLastUpdate.current &&
      !notification.read
  );

  latestNotifications.forEach(notification => {
    const toastMsg =
      NOTIFICATION_CATEGORY_TO_TOAST_MESSAGE[notification.category];

    /**
     * We only want to show toast notifications if there is a default message.
     */
    if (!toastMsg) {
      return;
    }

    addToast({
      message: toastMsg
    });
  });

  return {
    latestNotifications,
    firestoreNotifications
  };
};

import { OrganizationType } from "../../types";
import { PixelOwnerParseFailed } from "../../errors";
export var PixelField;
(function (PixelField) {
    PixelField["Id"] = "id";
    PixelField["Name"] = "name";
    PixelField["Created"] = "created";
    PixelField["LegacyId"] = "legacyId";
    PixelField["TrackingCode"] = "trackingCode";
    PixelField["AdvertiserName"] = "advertiserName";
    PixelField["AdvertiserId"] = "advertiserId";
    PixelField["Owners"] = "owners";
})(PixelField || (PixelField = {}));
/**
 * Assembles a list of the owning org ids with their org types from a service pixel's array of owner ids.
 * @param pixel: a pixel obtained from the backend.
 * @return: an array of OwnerOrg objects, representing the owners of @pixel.
 */
const getOwnerOrgs = (pixel, crypto) => {
    const { owners } = pixel;
    if (!owners) {
        return Promise.resolve([]);
    }
    return Promise.all(owners.map((id) => crypto.getOrganizationTypeFromOrgKey(id)))
        .then((OrganizationTypes) => OrganizationTypes.map((OrganizationType, idx) => ({
        orgId: owners[idx],
        OrganizationType
    })))
        .catch(() => {
        throw new PixelOwnerParseFailed();
    });
};
const assignDefaultTrackingCode = (pixelId) => `https://jelly.mdhv.io/v1/star.gif?pid=${pixelId}&src=mh&evt=hi`;
/**
 * @param pixel: a pixel obtained from the backend/adhering to ServicePixel.
 * @return: an equivalent object formatted to the Pixel interface.
 * @throws: PixelOwnerParseFailed if the org types of the pixel's owners cannot be determined.
 */
export const servicePixelToPixel = async (pixel, crypto) => {
    const ownerOrgs = await getOwnerOrgs(pixel, crypto);
    const { orgId: advertiserId } = ownerOrgs.find((owner) => owner.OrganizationType === OrganizationType.ADVERTISER) || { orgId: "" };
    return {
        id: pixel.id,
        name: pixel.name,
        parent: pixel.parent,
        created: new Date(pixel.created_at.seconds * 1000),
        legacyId: pixel.legacy_id,
        trackingCode: assignDefaultTrackingCode(pixel.id),
        advertiserName: null, // null b/c we need all advertisers to properly assess the advertiserName, and that's done as-needed in the app
        advertiserId,
        owners: ownerOrgs.map((ownerOrg) => ownerOrg.orgId),
        status: pixel.status
    };
};
/**
 * @param pixel: a pixel obtained from the frontend/adhering to Pixel.
 * @return: an equivalent object formatted to the ServicePixel interface.
 */
export const pixelToServicePixel = (pixel) => {
    return {
        id: pixel.id,
        name: pixel.name.trim(),
        parent: pixel.parent,
        created_at: {
            seconds: Math.round(pixel.created.getTime() / 1000),
            toDate: () => pixel.created
        },
        legacy_id: pixel.legacyId,
        owners: pixel.owners,
        status: pixel.status
    };
};
/**
 * @param pixel: a pixel obtained from the frontend/adhering to PixelBase - though it must have an ID.
 * @return: an equivalent object formatted to the ServicePixel interface.
 */
export const pixelBaseToServicePixel = (pixel) => {
    return {
        id: pixel.id,
        name: pixel.name.trim(),
        parent: pixel.parent,
        created_at: {
            seconds: Math.round(pixel.created.getTime() / 1000),
            toDate: () => pixel.created
        },
        legacy_id: pixel.legacyId,
        owners: pixel.owners,
        status: pixel.status
    };
};

import { GetReturn, Store, Stores } from "./models";

class ReduxLookup {
  private static instance: any;

  private storeInstance: Store;

  constructor() {
    if (ReduxLookup.instance) {
      return ReduxLookup.instance;
    }

    ReduxLookup.instance = this;
  }

  /** Is this lookup tied to some redux state.  */
  get initialized() {
    return typeof this.storeInstance !== "undefined";
  }

  /** Set the store this redux lookup will access. */
  set store(value: Store) {
    this.storeInstance = value;
  }

  /** Get the state assocaited with the redux store. */
  private get state() {
    return this.storeInstance.getState();
  }

  /**
   * @param type: the type of asset to look for.
   * @param id: the ID of the specific asset.
   * @return: the individual instance of that asset, if it is found. Else, undefined.
   */
  get<T extends keyof typeof Stores>(
    type: T,
    id: string | undefined
  ): GetReturn<T> {
    const store: any = Stores[type];
    let result: any;

    if (!id || !store) {
      return result;
    }

    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    result = this.state[store]?.byId[id];

    return result;
  }
}

export const lookup = new ReduxLookup();

import Filters from "./filters";
/**
 * This Paging class handles the paging logic for a Stream
 */
class Paging extends Filters {
    constructor(defaultFilters) {
        super(defaultFilters);
        /**
         * Offset to move (1,0,-1)
         */
        this._offset = 0;
        /**
         * Page size
         */
        this._size = 10;
        if (defaultFilters?.paging?.size) {
            this._size = defaultFilters.paging.size;
        }
        /**
         * To allow setter/getter for properties to work with binds
         */
        /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
        const get = (prop) => this[prop];
        /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7052 */
        const set = (prop, input) => (this[prop] = input);
        const setSize = this.setSize.bind(this);
        this.paging = {
            next: this.next.bind(this),
            previous: this.previous.bind(this),
            reset: this.reset.bind(this),
            get: this.getPaging.bind(this),
            get token() {
                return get("_token");
            },
            set token(input) {
                set("_token", input);
            },
            get offset() {
                return get("_offset");
            },
            set offset(input) {
                set("_offset", input);
            },
            get count() {
                return get("_count");
            },
            set count(input) {
                set("_count", input);
            },
            get size() {
                return get("_size");
            },
            set size(input) {
                setSize(input);
            }
        };
    }
    /**
     * Method to move paging to the next page
     */
    next() {
        this._offset = 1;
        this.handlePagingUpdate();
    }
    /**
     * Method to move paging ot the previous page
     */
    previous() {
        this._offset = -1;
        this.handlePagingUpdate();
    }
    /**
     * @param size Size to set the paging to be
     */
    setSize(size) {
        this._offset = undefined;
        this._size = size;
        this._token = undefined;
        this.handlePagingUpdate();
    }
    /**
     * Resets the current paging
     */
    reset() {
        this._offset = undefined;
        this._token = undefined;
        this._count = 0;
        this.handlePagingUpdate();
    }
    /**
     * Get the current paging information
     */
    getPaging() {
        return {
            count: this._count || 0,
            size: this._size,
            token: this._token,
            offset: this._offset
        };
    }
}
export default Paging;

import { madSDK } from "lib/sdk";
import { FirestoreCollectionId } from "lib/constants/firestore";
import {
  KnownOrganizationIds,
  ManageCampaignColumn,
  User
} from "@madhive/mad-sdk";
import {
  buildDefaultOrderedColumnIds,
  validateCustomColumnIdsIsSubsetOfKnownColumnIds
} from "lib/utils/localStorageColumns";
import {
  ManageCampaignTableColumn,
  ALL_COLUMNS_MAP,
  PREMION_COLUMN_IDS,
  SCRIPPS_COLUMN_IDS,
  BASE_LOGGED_IN_USER_COLUMN_IDS,
  BASE_COLUMN_IDS,
  FOX_COLUMN_IDS,
  GROUNDTRUTH_COLUMN_IDS,
  UNIVISION_COLUMN_IDS
} from "../MainScreen/constants";

const ORG_ID_TO_UNIQ_DEFAULT_COLUMN_ID_SET = Object.freeze({
  [KnownOrganizationIds.PREMION]: PREMION_COLUMN_IDS,
  [KnownOrganizationIds.SCRIPPS]: SCRIPPS_COLUMN_IDS,
  [KnownOrganizationIds.CAPITOL_BROADCASTING]: SCRIPPS_COLUMN_IDS,
  [KnownOrganizationIds.FOX]: FOX_COLUMN_IDS,
  [KnownOrganizationIds.GROUNDTRUTH]: GROUNDTRUTH_COLUMN_IDS,
  [KnownOrganizationIds.UNIVISION]: UNIVISION_COLUMN_IDS
});

export const getDefaultColumnIdSet = (user: User | null) => {
  if (user) {
    return (
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      ORG_ID_TO_UNIQ_DEFAULT_COLUMN_ID_SET[user.primaryOrganizationId] ||
      BASE_LOGGED_IN_USER_COLUMN_IDS
    );
  }
  return BASE_COLUMN_IDS;
};

const validateColumnIds = (
  columnIds: ManageCampaignColumn[],
  defaultColumnIdSet: Set<ManageCampaignColumn>
): boolean => {
  // name must always be first column
  if (columnIds[0] !== ManageCampaignColumn.NAME) {
    return false;
  }
  // columnIds must be a subset of organization's known column ids
  if (
    !validateCustomColumnIdsIsSubsetOfKnownColumnIds(
      columnIds,
      defaultColumnIdSet
    )
  ) {
    return false;
  }
  return true;
};

export const generateColumns = (
  columnIds: ManageCampaignColumn[],
  user: User | null
): ManageCampaignTableColumn[] =>
  columnIds.map(id => {
    const getLabelByUser = ALL_COLUMNS_MAP().get(id);
    return {
      id,
      label: (getLabelByUser && getLabelByUser(user)) || ""
    };
  });

export const getDefaultColumns = (
  user: User | null
): ManageCampaignTableColumn[] => {
  const defaultColumnIdSet = getDefaultColumnIdSet(user);
  const defaultOrderedColumnIds = buildDefaultOrderedColumnIds(
    defaultColumnIdSet,
    ALL_COLUMNS_MAP()
  );
  return generateColumns(defaultOrderedColumnIds, user);
};

export const generateColumnsFromCustomization = (
  user: User | null,
  customizedColumnIds: ManageCampaignColumn[] | undefined | null
): ManageCampaignTableColumn[] => {
  const defaultColumnIdSet = getDefaultColumnIdSet(user);
  const columnIdsToRender =
    customizedColumnIds &&
    validateColumnIds(customizedColumnIds, defaultColumnIdSet)
      ? customizedColumnIds
      : buildDefaultOrderedColumnIds(defaultColumnIdSet, ALL_COLUMNS_MAP());
  return generateColumns(columnIdsToRender, user);
};

export const generateColumnsForUser = (
  user: User | null
): ManageCampaignTableColumn[] => {
  const customizedColumnIds: ManageCampaignColumn[] | undefined | null =
    user?.columns && user.columns.manageCampaignsColumns;
  return generateColumnsFromCustomization(user, customizedColumnIds);
};

export const saveColumnCustomization = (
  userId: string,
  columnCustomization: ManageCampaignColumn[] | null
) =>
  madSDK.madFire.updateDoc(FirestoreCollectionId.USERS, userId, {
    "settings.columns.manage_campaigns": columnCustomization
  });

export const clearColumnCustomization = (userId: string) =>
  saveColumnCustomization(userId, null);

export const IMPRESSION_METRICS = [
    "explore_conversion.reach",
    "explore_conversion.impressions",
    "explore_conversion.avg_frequency",
    "explore_conversion.completes",
    "explore_conversion.view_completion_rate",
    "explore_conversion.clicks"
];
export const CONVERSION_METRICS = [
    "explore_conversion.impression_converted",
    "explore_conversion.unique_conversion_rate",
    "explore_conversion.unique_conversions",
    "explore_conversion.conversion_rate"
];
const METRIC_TO_COLOR = {
    "explore_conversion.reach": "#33CCCC",
    "explore_conversion.impressions": "#C2F0F0",
    "explore_conversion.avg_frequency": "#0072B2",
    "explore_conversion.completes": "#00A3FF",
    "explore_conversion.view_completion_rate": "#FF99D6",
    "explore_conversion.clicks": "#FF0099",
    "explore_conversion.impression_converted": "#B299E5",
    "explore_conversion.unique_conversion_rate": "#A75300",
    "explore_conversion.unique_conversions": "#7547D1",
    "explore_conversion.conversion_rate": "#FFA100"
};
export const METRIC_TO_DISPLAY_NAME = {
    "explore_conversion.reach": "Reach",
    "explore_conversion.impressions": "Impressions",
    "explore_conversion.avg_frequency": "Avg Frequency",
    "explore_conversion.completes": "Completes",
    "explore_conversion.view_completion_rate": "VCR",
    "explore_conversion.clicks": "Clicks",
    "explore_conversion.impression_converted": "Conversions",
    "explore_conversion.unique_conversion_rate": "Unique Conversion Rate",
    "explore_conversion.unique_conversions": "Unique Conversions",
    "explore_conversion.conversion_rate": "Conversion Rate"
};
const AXES = [
    [
        "explore_conversion.impressions",
        "explore_conversion.reach",
        "explore_conversion.completes",
        "explore_conversion.clicks"
    ],
    ["explore_conversion.avg_frequency"],
    ["explore_conversion.view_completion_rate"],
    ["explore_conversion.conversion_rate", "explore_conversion.unique_conversion_rate"],
    ["explore_conversion.unique_conversions", "explore_conversion.impression_converted"]
];
const buildAxes = (metrics) => AXES.reduce((allAxes, axis) => {
    const foundMetric = axis.find((metric) => metrics.includes(metric));
    if (foundMetric) {
        const orientation = CONVERSION_METRICS.includes(foundMetric) ? "right" : "left";
        const series = [];
        axis.forEach((metric) => {
            if (metrics.includes(metric)) {
                series.push({
                    axisId: metric,
                    id: metric,
                    name: METRIC_TO_DISPLAY_NAME[metric]
                });
            }
        });
        const label = series.map((a) => a.name).join(" & ");
        allAxes.push({
            label,
            orientation,
            series,
            showLabels: true,
            showValues: true,
            unpinAxis: false,
            tickDensity: "default",
            tickDensityCustom: 5,
            type: "linear"
        });
    }
    return allAxes;
}, []);
export const buildVisConfig = (metrics, hiddenMeasures) => {
    const axes = buildAxes(metrics);
    const seriesTypes = {};
    metrics.forEach((metric) => {
        if (CONVERSION_METRICS.includes(metric)) {
            seriesTypes[metric] = "line";
        }
    });
    const colors = {};
    metrics.forEach((metric) => {
        colors[metric] = METRIC_TO_COLOR[metric];
    });
    return {
        hidden_series: hiddenMeasures,
        y_axes: axes,
        series_types: seriesTypes,
        series_colors: colors,
        color_application: {
            custom: {
                label: "Custom",
                type: "discrete",
                colors: Object.values(METRIC_TO_COLOR)
            },
            options: {
                steps: 5
            }
        }
    };
};

import { ServiceCampaignStatus } from "../../../../models/campaigns/instruction";
import { REGEX } from "../../regex";
import { FilterTypes } from "../../../handlers/filter";
import { State } from "../../../../types";
import { stateToServiceStatus } from "../../../../utils";
/**
 * @param status: a string that represents a campaign status.
 * @return: the equivalent service camapign status, if one exists. Else, undefined.
 */
const toServiceCampaignStatus = (status) => {
    // if they gave us the numeric value stringified, use that
    const numeric = Number(status);
    if (ServiceCampaignStatus[numeric]) {
        return numeric;
    }
    if (REGEX.CampaignStatus.LIVE_WARNING.test(status)) {
        return ServiceCampaignStatus.LIVE_WARNING;
    }
    if (REGEX.CampaignStatus.MISSING_WHITELIST.test(status)) {
        return ServiceCampaignStatus.MISSING_WHITELIST;
    }
    if (REGEX.CampaignStatus.READY.test(status)) {
        return ServiceCampaignStatus.READY;
    }
    if (REGEX.CampaignStatus.WAITING.test(status)) {
        return ServiceCampaignStatus.WAITING;
    }
    return (
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7015 */
    ServiceCampaignStatus[status.toUpperCase()] ||
        /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7015 */
        ServiceCampaignStatus[status.replace(/[ ]/g, "_").toUpperCase()]);
};
/**
 * @param statuses: the input set of campaign status filters.
 * @return: parsed statuses. Ignores unrecognized values. Removes duplicates.
 */
export const parseCampaignStatus = (statuses) => {
    const parsed = new Set();
    statuses.forEach((status) => {
        const serviceState = toServiceCampaignStatus(status);
        if (serviceState) {
            parsed.add(serviceState);
        }
    });
    return Array.from(parsed);
};
/**
 * @param lens: the timeframe to view this filter in.
 * @param input: the value for the filter.
 * @return: a proper filter field object for the date filter.
 */
const toDateField = (lens, input) => {
    const date = new Date(input[0]);
    let type;
    let field;
    let value;
    switch (lens) {
        case "before":
        case "starts":
            date.setHours(0, 0, 0, 0);
            type = lens === "before" ? FilterTypes.LT : FilterTypes.GT;
            field = "startDate";
            value = date.toISOString();
            break;
        case "after":
        case "ends":
            date.setHours(24, 0, 0, 0);
            type = lens === "after" ? FilterTypes.GT : FilterTypes.LT;
            field = "endDate";
            value = date.toISOString();
            break;
        case "between":
        case "timeframe":
            {
                const end = new Date(input[1]);
                date.setHours(0, 0, 0, 0);
                end.setHours(24, 0, 0, 0);
                type = FilterTypes.BETWEEN;
                field = "timeframe";
                value = [date.toISOString(), end.toISOString()];
            }
            break;
        case "modified":
            date.setHours(0, 0, 0, 0);
            type = FilterTypes.GT;
            field = "lastUpdated";
            value = date.toISOString();
            break;
        default:
            throw new Error(`Invalid lens for Date Field: '${lens}'.`);
    }
    return {
        field,
        type,
        value
    };
};
/**
 * @param filters: map of filters, keys being the filter name, values being the applied values.
 * @return: an array of any date filters, parsed into valid SDK-style filter objects. Empty if there are no date filters in `filters`.
 */
const parseDateFields = (filters) => {
    const fields = [];
    if (filters.has("starts") && filters.has("ends")) {
        const start = filters.get("starts")[0];
        const end = filters.get("ends")[0];
        fields.push(toDateField("between", [start, end]));
    }
    else if (filters.has("starts")) {
        fields.push(toDateField("starts", filters.get("starts")));
    }
    else if (filters.has("ends")) {
        fields.push(toDateField("ends", filters.get("ends")));
    }
    filters.has("timeframe") && fields.push(toDateField("between", filters.get("timeframe")));
    filters.has("before") && fields.push(toDateField("before", filters.get("before")));
    filters.has("after") && fields.push(toDateField("after", filters.get("after")));
    filters.has("modified") && fields.push(toDateField("modified", filters.get("modified")));
    return fields;
};
/**
 * @param filters: map of filters, keys being the filter name, values being the applied values.
 * @return: filters parsed into valid SDK-style filter objects.
 */
export const toFilterObjects = (filters) => {
    const fields = [];
    const dateFilters = parseDateFields(filters);
    if (dateFilters.length > 0) {
        fields.push(...dateFilters);
    }
    if (filters.has("state")) {
        // convert given instruction statuses to their numeric values
        const value = [];
        filters.get("state").forEach((state) => {
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            const parsedState = State[state.toUpperCase()];
            if (typeof parsedState !== "undefined") {
                value.push(stateToServiceStatus(parsedState));
            }
        });
        fields.push({
            field: "state",
            type: FilterTypes.IN,
            value
        });
    }
    if (filters.has("status")) {
        // convert given campaign statuses to their numeric values
        fields.push({
            field: "status",
            type: FilterTypes.IN,
            value: parseCampaignStatus(filters.get("status"))
        });
    }
    if (filters.has("meta")) {
        fields.push({
            field: "meta",
            type: FilterTypes.EQ,
            value: filters.get("meta")[0]
        });
    }
    if (filters.has("search")) {
        fields.push({
            field: "search",
            type: FilterTypes.EQ,
            value: filters.get("search")[0]
        });
    }
    ["mediaType", "category", "name", "station", "agency", "advertiser", "id", "updatedBy"].forEach((field) => {
        if (filters.has(field)) {
            fields.push({
                field,
                type: FilterTypes.IN,
                value: filters.get(field)
            });
        }
    });
    return { where: fields };
};

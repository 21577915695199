import { useMemo } from "react";
import { DoNotCare, IdType, IdTypeFromData, isIdType } from "../../types";
import {
  TableRowType,
  TableRowActionDef,
  RowActionsOnTable,
  TableRowActionDefObject
} from "../types";
import { lookupElementsByDisplayName } from "../../utils";
import { getAdditionalChildrenKeys } from "../utils";

/**
 * Builds the rowAction definition objects based on the provided rowActions prop,
 * and includes any overrides found in the table children prop (on Table.RowAction components).
 * @param rowActions - the rowAction definitions
 * @param children - the children props of the table
 * @returns array of {@link TableRowActionDefObject}
 */
const makeRowActionsDefs = <
  Row extends TableRowType,
  Action extends TableRowActionDef<Row> = TableRowActionDef<Row>
>(
  rowActions: RowActionsOnTable<Row, Action> | undefined,
  children: DoNotCare
): Array<TableRowActionDefObject<Row>> => {
  const actionList = (rowActions?.list || []) as Action[];
  const actionsChildren = lookupElementsByDisplayName(
    "Table.RowAction",
    children
  );

  if (!actionList?.length && !actionsChildren?.length) {
    return [];
  }

  const allActions = [
    ...actionList,
    ...getAdditionalChildrenKeys(
      "Table.RowAction",
      actionList.map((action: { key: IdType } | IdType) =>
        isIdType(action) ? action : action.key
      ),
      actionsChildren
    )
  ] as Action[];

  const onAction = rowActions?.onAction || (() => {});

  return allActions.reduce((acc, action: TableRowActionDef<Row>) => {
    const actionKey = (
      isIdType(action) ? action : action.key
    ) as IdTypeFromData<Action>;

    const childProps =
      actionsChildren.find(({ key }) => key === actionKey)?.props || {};

    const actionObject = {
      ...(isIdType(action) ? { key: action, label: actionKey } : action),
      ...childProps
    };

    if (actionObject.hidden === true) {
      return acc;
    }

    if (actionObject?.hidden !== undefined) {
      const hiddenBool = Boolean(actionObject.hidden);
      actionObject.hidden =
        typeof actionObject.hidden === "function"
          ? actionObject.hidden
          : () => hiddenBool;
    } else {
      const hiddenBool = Boolean(rowActions?.hidden);
      actionObject.hidden = (row: Row) =>
        typeof rowActions?.hidden === "function"
          ? rowActions.hidden(actionKey, row)
          : hiddenBool;
    }

    if (!actionObject.onClick) {
      actionObject.onClick = (row: Row) => onAction(actionKey, row);
    }

    acc.push(actionObject);
    return acc;
  }, [] as Array<TableRowActionDefObject<Row>>);
};

/**
 * Hook to build rowAction definitions for a table,
 * based on the provided rowActions prop and table children.
 * @param rowActions - the rowAction definitions
 * @param children - the children props of the table
 * @returns array of {@link TableRowActionDefObject}
 */
export const useRowActionDefs = <
  Row extends TableRowType,
  Action extends TableRowActionDef<Row> = TableRowActionDef<Row>
>(
  rowActions: RowActionsOnTable<Row, Action> | undefined,
  children: DoNotCare = []
) =>
  useMemo(
    () => makeRowActionsDefs<Row, Action>(rowActions, children),
    [children, rowActions]
  );

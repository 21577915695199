export const LimitsPerTimeframes = {
    HOUR: 11,
    DAY: 21,
    WEEK: 141,
    MONTH: 621
};
export const numCapsToStringCaps = (caps) => caps.map((cap) => ({
    ...cap,
    max: cap.max.toString()
}));
export const stringCapsToNumCaps = (caps) => caps.map((cap) => ({
    ...cap,
    max: Number(cap.max) || 0
}));
export const findFrequencyValue = (timeFrames, timeUnit) => 
// eslint-disable-next-line camelcase
timeFrames.find(({ duration: { time_unit } }) => time_unit === timeUnit)?.max;
export const parseFrequencyByTimeframe = (caps) => {
    const capsByTimeframe = {};
    // eslint-disable-next-line camelcase
    caps.forEach(({ duration: { time_unit }, max }) => {
        /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
        capsByTimeframe[time_unit] = Number(max || 0);
    });
    return capsByTimeframe;
};
export const checkFrequencyProportions = (timeframes) => {
    if (timeframes.some((value) => value.max === 0)) {
        return "Frequency caps must be higher than 0";
    }
    const { HOUR: hour, DAY: day, WEEK: week, MONTH: month } = parseFrequencyByTimeframe(timeframes);
    if (month) {
        if (hour && hour > month) {
            return "The hourly frequency cap cannot exceed the monthly frequency cap.";
        }
        if (day && day > month) {
            return "The daily frequency cap cannot exceed the monthly frequency cap.";
        }
        if (week && week > month) {
            return "The weekly frequency cap cannot exceed the monthly frequency cap.";
        }
    }
    if (week) {
        if (hour && hour > week) {
            return "The hourly frequency cap cannot exceed the monthly weekly frequency cap.";
        }
        if (day && day > week) {
            return "The daily frequency cap cannot exceed the weekly frequency cap.";
        }
    }
    if (day) {
        if (hour && hour > day) {
            return "The hourly frequency cap cannot exceed the daily frequency cap.";
        }
    }
    return null;
};
export const checkFrequencyLimits = (currentTimeframes) => currentTimeframes.reduce(
// eslint-disable-next-line camelcase
(errors, { duration: { time_unit } }) => {
    if ((Number(findFrequencyValue(currentTimeframes, time_unit)) || 0) >=
        LimitsPerTimeframes[time_unit]) {
        if (!errors) {
            errors = {};
        }
        errors[time_unit] = `${time_unit.charAt(0) + time_unit.slice(1).toLocaleLowerCase()} frequency cap cannot exceed ${LimitsPerTimeframes[time_unit] - 1}.`;
    }
    return errors;
}, undefined);

import axios from "axios";
import { backOff } from "exponential-backoff";
import { Observable } from "rxjs";
import { NotImplementedError } from "../../errors";
import { ObservableHandler } from "../handlers";
import { FilterValueTypes, validateFilterStructure } from "../handlers/filter";
import { isDescending } from "../handlers/sort";
import { FilterTypes } from "../../types";
import { InvalidCustomDateFilter, InvalidQueryParameter, QuerySubmissionFailed, ReportFetchFailed, UnfinishedReport, UntrackableQuery } from "./errors";
import { Parameters } from "./parameters";
export class Statistics extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "statistics");
        this.urls = {
            /** This endpoint enables the generation of a report. */
            submit: `${this.sdk.urls.advancedQueryToolBaseUrl}/v2/report`,
            /**
             * This endpoint enables obtaining the results of a report.
             * @param id: the ID of the report to fetch results for.
             * @return: the URL, properly-formatted with the `id`.
             */
            result: (id) => `${this.sdk.urls.advancedQueryToolBaseUrl}/v1/results/${id}`
        };
        /**
         * @deprecated
         * `statistics` does not implement find_once.
         */
        this.find_once = () => {
            throw new NotImplementedError("find_once");
        };
        this.metrics = new Parameters(sdk, "metrics");
        this.dimensions = new Parameters(sdk, "dimensions");
        this.filters = new Parameters(sdk, "filters");
    }
    findItems(filters, sort) {
        return new Observable((subscriber) => {
            try {
                validateFilterStructure(filters, [
                    { filterType: FilterTypes.EQ, valueType: FilterValueTypes.STRING },
                    { filterType: FilterTypes.IN, valueType: FilterValueTypes.OBJECT },
                    { filterType: FilterTypes.EQ, valueType: FilterValueTypes.OBJECT }
                ]);
            }
            catch (error) {
                subscriber.error(error);
                return;
            }
            const query = this.toServiceQuery(filters, sort);
            this.submit(query)
                .then(({ id }) => {
                if (!id) {
                    subscriber.error(new UntrackableQuery());
                    return;
                }
                backOff(() => this.getReport(id).then((report) => {
                    // if pending, we want to continue to retry
                    if (report.status === "pending") {
                        throw new UnfinishedReport(report.id);
                    }
                    return report;
                }), {
                    delayFirstAttempt: true,
                    startingDelay: 5000, // in milliseconds === 5 seconds
                    retry: (error) => error instanceof UnfinishedReport
                })
                    .then((report) => {
                    subscriber.next(report);
                    subscriber.complete();
                })
                    .catch((error) => {
                    subscriber.error(error);
                });
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    /**
     * @param filter: a set of SDK-style filters. Only the `where` clause is relevant.
     * @param sort: an SDK-style sort instruction.
     * @return: the appropriate service query, based on these parameters.
     * @throws
     *   - `InvalidCustomDateFilter` if the `dates` filter is supplied with invalid values
     *   - `InvalidQueryParameter` if `metrics`, `dimensions` or filter keys are supplied with invalid values
     */
    toServiceQuery({ where = [] }, sort) {
        const query = {};
        if (sort) {
            const order = isDescending(sort) ? `-${sort.field}` : sort.field;
            query.order_by = [order];
        }
        for (const filter of where) {
            const { field, value } = filter;
            switch (field) {
                case "dates": {
                    if (typeof value === "string") {
                        query.date_range = value;
                    }
                    else if (Array.isArray(value)) {
                        const [start, end] = value;
                        if (!(start instanceof Date) || !(end instanceof Date)) {
                            throw new InvalidCustomDateFilter();
                        }
                        query.date_range = "CUSTOM";
                        // .split + indexing takes the UTC format (YYYY-MM-DDTHH:mm:ss.sssZ) and returns just YYYY-MM-DD
                        // this is the format AQT expects of custom date strings
                        query.start_date = start.toISOString().split("T")[0];
                        query.end_date = end.toISOString().split("T")[0];
                    }
                    break;
                }
                case "metrics":
                case "dimensions": {
                    const parsed = [];
                    const values = Array.isArray(value) ? value : [value];
                    for (const v of values) {
                        if (typeof v !== "string") {
                            throw new InvalidQueryParameter(field);
                        }
                        parsed.push(v);
                    }
                    query[field] = parsed;
                    break;
                }
                default: {
                    // anything that is not explicitly a metric, a dimension, or a date is assumed to be a filter
                    const filters = query.filters || {};
                    const individual = filters[field] || [];
                    let values = [];
                    if (Array.isArray(value)) {
                        if (value[0] instanceof Date || value[1] instanceof Date) {
                            throw new InvalidQueryParameter(field);
                        }
                        values = value;
                    }
                    else {
                        values = [value];
                    }
                    filters[field] = individual.concat(values);
                    query.filters = filters;
                    break;
                }
            }
        }
        return query;
    }
    /**
     * @param query: a query, structured for the service.
     * @return: the resulting report.
     */
    submit(query) {
        return axios
            .post(this.urls.submit, query, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data }) => data)
            .catch((error) => {
            throw new QuerySubmissionFailed(error);
        });
    }
    /**
     * @param id: the id of the report to get.
     * @return: the report.
     * @throws: `ReportFetchFailed`, if fetching the report results in a non-404 error.
     */
    getReport(id) {
        return axios
            .get(this.urls.result(id), {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data }) => {
            return {
                ...data,
                results: data.results || [],
                id
            };
        })
            .catch((error) => {
            // The underlying service will 404 if the report is valid and complete, but has no data
            // this is not correct behavior, and so we have this hack
            // though this will ignore real 404's, returning an empty array for those is not a dissimilar result
            if (error?.response?.status === 404) {
                return {
                    id,
                    results: [],
                    status: "complete"
                };
            }
            throw new ReportFetchFailed(error);
        });
    }
    /**
     * @deprecated
     * `statistics` does not implement make.
     */
    make() {
        return new Promise((_, reject) => reject(new NotImplementedError("make")));
    }
    saveItem() {
        throw new NotImplementedError("save");
    }
    deleteItem() {
        throw new NotImplementedError("delete");
    }
}

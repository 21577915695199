export var RemoteConfigProperties;
(function (RemoteConfigProperties) {
    RemoteConfigProperties["DASHBOARD_HEATMAP"] = "dashboard_heatmap";
    RemoteConfigProperties["CAMPAIGN_UPLOAD"] = "campaign_upload";
    RemoteConfigProperties["DOUBLE_VERIFY"] = "double_verify";
    RemoteConfigProperties["TARGETING_GEO_ZIPCODE"] = "targeting_geo_zipcode";
    RemoteConfigProperties["TARGETING_GEO_DMA"] = "targeting_geo_dma";
    RemoteConfigProperties["TARGETING_GEO_DISTRICT"] = "targeting_geo_district";
    RemoteConfigProperties["TARGETING_GEO_STATE"] = "targeting_geo_state";
    RemoteConfigProperties["TARGETING_GEO_NOTES"] = "targeting_geo_notes";
    RemoteConfigProperties["TARGETING_ITALY_ONLY"] = "targeting_italy_only";
    RemoteConfigProperties["TARGETING_MULTIPLE_FREQ_CAP"] = "targeting_multiple_freq_cap";
    /** Enables linear reports in general (linear+ott). */
    RemoteConfigProperties["CUSTOM_REPORTING_LINEAR"] = "custom_reporting_linear";
    // Enables the PowerPoint export button for custom reporting
    RemoteConfigProperties["CUSTOM_REPORTING_PPTX_EXPORT"] = "custom_reporting_pptx_export";
    RemoteConfigProperties["CUSTOM_REPORTING_CREATE_DEFAULT_DIMENSIONS"] = "custom_reporting_create_default_dimensions";
    RemoteConfigProperties["FLEXIBLE_AUDIENCES"] = "flexible_audiences";
    RemoteConfigProperties["PREMION_GROUP_ID"] = "premion_group_id";
    RemoteConfigProperties["DATA_STUDIO"] = "datastudio";
    RemoteConfigProperties["FORECASTER_RESTRICTED_AUDIENCES"] = "forecaster_restricted_audiences";
    RemoteConfigProperties["SALESFORCE_INSERTION_ORDER_ID"] = "salesforce_insertion_order_id";
    RemoteConfigProperties["EDIT_AUDIENCE_NAME"] = "edit_audience_name";
    RemoteConfigProperties["PACE_TO_BUDGET_WITH_GOAL_INTERVAL"] = "pace_to_budget_with_goal_interval";
    RemoteConfigProperties["USERS_FILTERS_DRAWER"] = "users_filters_drawer";
    RemoteConfigProperties["ASSOCIATED_CAMPAIGN_COLUMN"] = "associated_campaign_column";
    RemoteConfigProperties["OTT_MULTI_DIMENSIONS"] = "ott_multi_dimensions";
    RemoteConfigProperties["LINEAR_MULTI_DIMENSIONS"] = "linear_multi_dimensions";
    /** Additive to linear reports in general. Can specifically disable linear-only. */
    RemoteConfigProperties["CUSTOM_REPORTING_LINEAR_ONLY"] = "custom_reporting_linear_only";
    RemoteConfigProperties["WIDE_ORBIT"] = "wide_orbit";
    RemoteConfigProperties["BULK_EDIT_LINE_ITEMS"] = "bulk_edit_line_items";
    RemoteConfigProperties["FORECASTER_DEVICE_DISTRIBUTION"] = "forecaster_device_distribution";
    RemoteConfigProperties["MANAGE_CAMPAIGN_ARCHIVING"] = "manage_campaign_archiving";
    RemoteConfigProperties["START_ASAP"] = "start_asap";
    RemoteConfigProperties["HOLDING_COMPANY"] = "holding_company";
    RemoteConfigProperties["AUDIENCE_CREATION"] = "audience_creation";
    RemoteConfigProperties["AUDIENCE_BUILDER"] = "audience_builder";
    RemoteConfigProperties["AUDIENCE_SEGMENT_DETAILS_DEV_PHASE_1"] = "audience_segment_details_dev_phase_1";
    RemoteConfigProperties["DISPLAY_ACTIVATION"] = "display_activation";
    RemoteConfigProperties["AUDIO_ACTIVATION"] = "audio_activation";
    RemoteConfigProperties["LINEITEM_LEGACY_AUDIENCE_DISABLED"] = "lineitem_legacy_audience_disabled";
    RemoteConfigProperties["CAMPAIGN_USES_LINE_ITEM_DATES"] = "campaign_uses_line_item_dates";
    RemoteConfigProperties["THIRD_PARTY_ADS"] = "third_party_ads";
    RemoteConfigProperties["PLANNER_ADDITIONAL_METRICS"] = "planner_additional_metrics";
    RemoteConfigProperties["PLANNER_DIMENSION_PUBLISHERS"] = "planner_dimension_publishers";
    RemoteConfigProperties["PLANNER_DIMENSION_DEVICES"] = "planner_dimension_devices";
    RemoteConfigProperties["PLANNER_DIMENSION_ZIPS"] = "planner_dimension_zips";
    RemoteConfigProperties["PLANNER_DIMENSION_DMAS"] = "planner_dimension_dmas";
    RemoteConfigProperties["PLANNER_DIMENSION_STATES"] = "planner_dimension_states";
    RemoteConfigProperties["PLANNER_DIMENSION_DISTRICTS"] = "planner_dimension_districts";
    RemoteConfigProperties["PLANNER_DIMENSIONS_DEFAULT_DMAS"] = "planner_dimensions_default_dmas";
    RemoteConfigProperties["PLANNER_VIEW_ALL"] = "planner_view_all";
    RemoteConfigProperties["PLANNER_IAB_CATEGORY"] = "planner_iab_category";
    RemoteConfigProperties["PLANNER_NEW_VERSION"] = "planner_new_version";
    // Disable the device distribution selection options in Planner creation.
    RemoteConfigProperties["PLANNER_DISABLE_DEVICE_DISTRIBUTION"] = "planner_disable_device_distribution";
    // Enables planner convert a plan/scenarios to campaign/line items feature
    RemoteConfigProperties["PLANNER_ACTIVATING_OFF_PLANS"] = "planner_activating_off_plans";
    // Show forecast type based on publisher groups, related to Tubi only and FLX Local News Any and Big screens
    RemoteConfigProperties["PLANNER_SHOW_FORECAST_TYPE"] = "planner_show_forecast_type";
    // Show forecast type tooltip, we want to hide this until all supply initiative is ready
    RemoteConfigProperties["PLANNER_SHOW_FORECAST_TYPE_TOOLTIP"] = "planner_show_forecast_type_tooltip";
    // Use Premion Audience Builder for Planner.
    RemoteConfigProperties["PLANNER_LEGACY_AUDIENCE_BUILDER"] = "planner_legacy_audience_builder";
    // Hide Products in Planner https://madhive.atlassian.net/browse/PLANS-1015
    RemoteConfigProperties["PLANNER_HIDDEN_HEARST_PRODUCTS"] = "planner_hidden_hearst_products";
    // Shows inventory package feature in planner
    RemoteConfigProperties["PLANNER_INVENTORY_PACKAGE"] = "planner_inventory_package";
    // Shows planner admin tool in the internal tools menu
    RemoteConfigProperties["PLANNER_ADMIN_TOOL"] = "planner_admin_tool";
    // Enables custom reporting effective frequency chart overlapped line data for unique conversions
    RemoteConfigProperties["CUSTOM_REPORTING_EFFECTIVE_FREQUENCY_CHART_OVERLAP"] = "custom_reporting_effective_frequency_chart_overlap";
    RemoteConfigProperties["DISPLAY_CREATIVE_MEDIA_TYPE"] = "display_creative_media_type";
    // Allows publishers with all media types https://madhive.atlassian.net/browse/ACT-641
    RemoteConfigProperties["PUBLISHERS_WITH_ALL_MEDIA_TYPES"] = "publishers_with_all_media_types";
    // Hides charts in the pacing screen https://madhive.atlassian.net/browse/MSR-212
    RemoteConfigProperties["PACING_CHARTS"] = "pacing_charts";
    RemoteConfigProperties["RETARGETING_SEGMENTS"] = "retargeting_segments";
    RemoteConfigProperties["MEDIA_TYPE_FILTERS"] = "media_type_filters";
    RemoteConfigProperties["AKAMAI_URL"] = "akamai_url";
    RemoteConfigProperties["PLANNER"] = "planner";
    RemoteConfigProperties["ALLOW_BUDGET_CALCULATOR"] = "allow_budget_calculator";
    RemoteConfigProperties["ALLOW_BUDGET_CALCULATOR_NO_PRODUCT_TYPE"] = "allow_budget_calculator_no_product_type";
    RemoteConfigProperties["IGNORE_DEVICE_GRAPH_FORECASTER_OPTION"] = "ignore_device_graph_forecaster_option";
    // allows dev users to masquerade other users https://madhive.atlassian.net/browse/MSR-515
    RemoteConfigProperties["MASQUERADE_USER"] = "masquerade_user";
    RemoteConfigProperties["PUB_LIST_PRODUCTS"] = "publisher_list_products";
    // allows media market table to be visible to users
    RemoteConfigProperties["DASHBOARD_MEDIA_MARKET_TABLE"] = "dashboard_media_market_table";
    // displays a foot disclaimer in dashboard view
    RemoteConfigProperties["DASHBOARD_FOOTER_DISCLAIMER"] = "dashboard_footer_disclaimer";
    // allows users to view a creative summaries table on the dashboard
    RemoteConfigProperties["DASHBOARD_CREATIVE_SUMMARIES"] = "dashboard_creative_summaries";
    // TODO: remove this property from remote config once premion has qa-ed retargeting seg creation.
    RemoteConfigProperties["RETARGETING_SEGMENT_CREATION_PREMION"] = "retargeting_segment_creation_premion";
    // whether or not transunion dmp-related ui is visible to org
    RemoteConfigProperties["TRANSUNION"] = "transunion";
    // enables foot traffic brand dimension
    RemoteConfigProperties["FOOT_TRAFFIC_DIMENSION_BRAND"] = "foot_traffic_dimension_brand";
    // enables foot traffic estimate dimension
    RemoteConfigProperties["FOOT_TRAFFIC_DIMENSION_ESTIMATE"] = "foot_traffic_dimension_estimate";
    /** Can you even change table views for the campaigns/line items tables. */
    RemoteConfigProperties["INSTRUCTIONS_VIEW_SELECTOR"] = "instructions_view_selector";
    /** Can you select the pacing view for the campaigns/line items tables. */
    RemoteConfigProperties["INSTRUCTIONS_PACING_VIEW"] = "instructions_pacing_view";
    /** enables Inventory tab on the Manage Products screen */
    RemoteConfigProperties["INVENTORY_PRIORITIZATION"] = "inventory_prioritization";
    /** Enables product name column in pacing table -> line items tab */
    RemoteConfigProperties["PACING_LINE_ITEM_PRODUCT_NAME_COLUMN"] = "pacing_line_item_product_name_column";
    /** Enables conversion details tab to be the default view in foot traffic */
    RemoteConfigProperties["FOOT_TRAFFIC_DEFAULT_TAB_CONVERSION_DETAILS"] = "foot_traffic_default_tab_conversion_details";
    /** Enables deals selection on line items */
    RemoteConfigProperties["LINE_ITEM_DEALS"] = "line_item_deals";
    /** Enables dayparting on line items */
    RemoteConfigProperties["LINE_ITEM_DAYPARTS_READ_ONLY"] = "line_item_dayparts_read_only";
    /** Enables access to the manage deals menu item and screen */
    RemoteConfigProperties["INTERNAL_VIEW_MANAGE_DEALS"] = "internal_view_manage_deals";
    /** Enables access to checkbox in Deals screen*/
    RemoteConfigProperties["MADHIVE_SUPPLY_GROUP"] = "madhive_supply_group";
    /** Enables Deal Groups tab on Deals screen */
    RemoteConfigProperties["DEAL_GROUPS"] = "deal_groups";
    /** Enables access to the source field in the manage deals screen */
    RemoteConfigProperties["INTERNAL_MANAGE_DEALS_INVENTORY_SOURCE"] = "internal_manage_deals_inventory_source";
    /** Enables access to the new product screen */
    RemoteConfigProperties["INTERNAL_VIEW_PRODUCT_PAGE_V2"] = "internal_view_product_page_v2";
    /** Enables access to content objects (languages, generes) on new product screen */
    RemoteConfigProperties["INTERNAL_PRODUCT_CONTENT_OBJECTS"] = "internal_product_content_objects";
    /** Enables access to the charge type field on new product screen */
    RemoteConfigProperties["INTERNAL_PRODUCT_CHARGE_TYPE"] = "internal_product_charge_type";
    /** Enables access to the bulk upload menu item and screen on deals page */
    RemoteConfigProperties["INTERNAL_VIEW_DEALS_BULK_UPLOAD"] = "internal_view_deals_bulk_upload";
    /** Enables access to the 5 minute separation menu item and screen */
    RemoteConfigProperties["INTERNAL_FIVE_MIN_SEPARATION"] = "internal_five_min_separation";
    /** When iab_categories is turned on, that feature will be required for all orgs. This flag allows orgs to opt out of iab_categories being a required feature */
    RemoteConfigProperties["IAB_CATEGORIES_REQUIRED"] = "iab_categories_required";
    /** Turns includes-style search on and off - defaults to fuzzy search when includes_search is off */
    RemoteConfigProperties["INCLUDES_SEARCH"] = "includes_search";
    /** Provides the ability for users to utilize the Refresh button in readonly mode on the Creative Details page */
    RemoteConfigProperties["REFRESH_CREATIVE_IN_READONLY"] = "refresh_creative_in_readonly";
    /** Enables Advanced Exports Scheduled Reports */
    RemoteConfigProperties["ADVANCED_EXPORTS_SCHEDULED_REPORTS"] = "advanced_exports_scheduled_reports";
    /** Always show eCPM field in the line items screens */
    RemoteConfigProperties["SET_ECPM_AT_LINE_ITEM_WITHOUT_PACING_TO_BUDGET"] = "set_ecpm_at_line_item_without_pacing_to_budget";
    /** controls whether user is able to reset their password from profile preferences screen */
    RemoteConfigProperties["CAN_RESET_PASSWORD_FROM_PROFILE_PREFERENCES"] = "can_reset_password_from_profile_preferences";
    /** Enables error logging on otherwise-unhandled errors. */
    RemoteConfigProperties["UNHANDLED_ERROR_LOGGING"] = "unhandled_error_logging";
    /** Show the External ID field on the Campaign form. */
    RemoteConfigProperties["CAMPAIGN_FORM_EXTERNALID_FIELD"] = "campaign_form_externalid_field";
    /** Turns legacy line items on/off */
    RemoteConfigProperties["LEGACY_LINE_ITEM"] = "legacy_line_item";
    /** Legacy version of Manage Campaigns */
    RemoteConfigProperties["LEGACY_CAMPAIGN"] = "legacy_campaign";
    /** Legacy version of pacing to impression/budget fields */
    RemoteConfigProperties["LEGACY_PACE_TO_BUDGET"] = "legacy_pace_to_budget";
    /** Enables date presets for date picker on Conversion Dashboard */
    RemoteConfigProperties["CONVERSION_DASHBOARD_DATE_PRESETS"] = "conversion_dashboard_date_presets";
    /** Enables 'Content Genre' for Conversion Dashboard dimensions */
    RemoteConfigProperties["CONVERSION_DASHBOARD_GENRE_DIMENSION"] = "conversion_dashboard_genre_dimension";
    /** Will exclude conversions that are on ip blacklist */
    RemoteConfigProperties["CONVERSIONS_BLACKLIST"] = "conversions_blacklist";
    RemoteConfigProperties["UNIVISION_DASHBOARD_FILTERS"] = "univision_dashboard_filters";
    /** Enables the Audience Data Provisioning page (nav item under "Internal Tools" menu section). */
    RemoteConfigProperties["ENABLE_AUDIENCE_DATA_PROVISIONING"] = "enable_audience_data_provisioning";
    /** Enables access to the BI Conversion Dashboard View */
    RemoteConfigProperties["ENABLE_BI_CONVERSION_DASHBOARD"] = "enable_bi_conversion_dashboard";
    /** Enables access to the BI Pacing Dashboard View */
    RemoteConfigProperties["ENABLE_PACING_BETA"] = "enable_pacing_beta";
    /** Enables pace to budget reporting on the Pacing and Campaign dashboards */
    RemoteConfigProperties["PACE_TO_BUDGET_REPORTING"] = "pace_to_budget_reporting";
    RemoteConfigProperties["ENABLE_BI_SPEND_DASHBOARD"] = "enable_bi_spend_dashboard";
    /** Enables the pace to budget line item fields, and goal columns */
    RemoteConfigProperties["PACE_TO_BUDGET"] = "pace_to_budget";
    RemoteConfigProperties["ENABLE_CLIENT_FACING_DEALS_VIEW"] = "enable_client_facing_deals_view";
    /** Controls whether or not the save tracking information is sent to Firestore */
    RemoteConfigProperties["PEER_CHANGES_SAVE_ALERTS"] = "peer_changes_save_alerts";
    /** Controls whether or not the save alerts toast messages will be displayed (does not disable Firestore save tracking). */
    RemoteConfigProperties["PEER_CHANGES_SAVE_ALERTS_TOASTS"] = "peer_changes_save_alerts_toasts";
    /** Controls whether or not the save alerts toast messages will be displayed for list changes (does not disable Firestore save tracking). */
    RemoteConfigProperties["PEER_CHANGES_SAVE_LISTS_ALERTS_TOASTS"] = "peer_changes_save_lists_alerts_toasts";
    /** Enables the ability to add a status field to a new product */
    RemoteConfigProperties["PRODUCTS_STATUS_FIELD"] = "products_status_field";
    RemoteConfigProperties["PRODUCT_MEDIA_TYPE"] = "product_media_type";
    /** Enables access to the Publisher Manager Screen */
    RemoteConfigProperties["PUBLISHER_MANAGER"] = "publisher_manager";
    /** Allows users to set the start and end dates when duplicating a campaign, which will be applied to its line items */
    RemoteConfigProperties["SET_DUPLICATED_CAMPAIGN_DATES"] = "set_duplicated_campaign_dates";
    /** Allows users to toggle the display of "user hints", an experimental new feature for toggling the display of help icons around the UI */
    RemoteConfigProperties["USER_HINTS"] = "user_hints";
    /** Determines whether or not to display the refactored version of the creative library. */
    RemoteConfigProperties["CREATIVE_LIBRARY_V2"] = "creative_library_v2";
    /** Disables dayparting for line items. This is used by orgs who want to control this field via their OMS. */
    RemoteConfigProperties["DISABLE_DAYPARTING"] = "disable_dayparting";
    /** Disables frequency caps for line items. This is used by orgs who want to control this field via their OMS. */
    RemoteConfigProperties["DISABLE_FREQUENCY_CAPS"] = "disable_frequency_caps";
})(RemoteConfigProperties || (RemoteConfigProperties = {}));

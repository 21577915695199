import * as FullStory from "@fullstory/browser";
import { RawInstructionStatus } from "features/ManageCampaigns/constants";
import {
  CampaignStatus,
  PRE_LIVE_CAMPAIGN_STATUSES
} from "lib/constants/campaign";
import { ObjType, ServiceStatus, Product } from "@madhive/mad-sdk";
import {
  archiveLineItem,
  createLineItem,
  getLineItemDetails,
  transformRawLineItemDetails,
  updateLineItem,
  updateCampaign
} from "api/manageCampaigns";
import { FILLED_DAYPARTS } from "components/SmithersDayparter/SmithersDayparter";
import { getCampaign, selectCampaignById } from "appReducers/campaignsReducer";
import axios, { CancelTokenSource } from "axios";
import {
  RootCampaign,
  LineItemFormatted,
  LineItemFormattedForAttachingCreatives,
  LineItemId,
  ShallowLineItem
} from "campaign-types";
import { FullStoryCustomEvent } from "lib/constants/fullStory";
import { madSDK } from "lib/sdk";
import {
  deriveErrorResponse,
  isErrorResponseValid,
  isHttpStatusForbidden
} from "lib/utils/api";
import { getEarliestDate, getLatestDate, isDatePassed } from "lib/utils/date";
import { isTruthy } from "lib/utils/fp";
import { CreateAndUpdateLineItemEndpointPostBody } from "newnewyork";
import { AppThunk } from "types";
import { getAllProducts } from "../productsReducer";
import { showSuccess } from "../toasterReducer/actions";
import { MESSAGE_GETTER_DICTIONARY } from "./constants";
import {
  selectCreateLineItemsCancellationSource,
  selectIsLineItemSaving,
  selectLoadArchiveLineIitemCancellationSource,
  selectUpdateLineItemsCancellationSource
} from "./selectors";
import {
  ABORT_ARCHIVE_LINE_ITEM_REQUEST,
  ABORT_CREATE_LINE_ITEM_REQUEST,
  ABORT_UPDATE_LINE_ITEM_REQUEST,
  CREATE_LINE_ITEM_FAILURE,
  CREATE_LINE_ITEM_PENDING,
  CREATE_LINE_ITEM_SUCCESS,
  DELETE_LINE_ITEM_FAILURE,
  DELETE_LINE_ITEM_PENDING,
  DELETE_LINE_ITEM_SUCCESS,
  GET_LINE_ITEM_DETAILS_FAILURE,
  GET_LINE_ITEM_DETAILS_PENDING,
  GET_LINE_ITEM_DETAILS_SUCCESS,
  LineItemFetchMap,
  lineItemsActionTypes,
  SET_SELECTED_FOR_BULK_ACTION,
  UPDATE_LINE_ITEM_FAILURE,
  UPDATE_LINE_ITEM_PENDING,
  UPDATE_LINE_ITEM_SUCCESS
} from "./types";

export const setSelectedForBulkAction = (
  lineItems: ShallowLineItem[]
): lineItemsActionTypes => ({
  type: SET_SELECTED_FOR_BULK_ACTION,
  payload: lineItems
});

const setCreateLineItemPending = (
  cancellationSource: CancelTokenSource
): lineItemsActionTypes => ({
  type: CREATE_LINE_ITEM_PENDING,
  meta: {
    cancellationSource
  }
});

const setCreateLineItemSuccess = (
  lineItem: CreateAndUpdateLineItemEndpointPostBody
): lineItemsActionTypes => ({
  type: CREATE_LINE_ITEM_SUCCESS,
  payload: lineItem,
  meta: {
    success: {
      message: MESSAGE_GETTER_DICTIONARY.CREATE_SUCCESS()
    }
  }
});

const setCreateLineItemFailure = (error?: Error): lineItemsActionTypes => ({
  type: CREATE_LINE_ITEM_FAILURE,
  meta: {
    error: {
      message: error && error.message
    }
  }
});

const setUpdateLineItemPending = (
  cancellationSource: CancelTokenSource
): lineItemsActionTypes => ({
  type: UPDATE_LINE_ITEM_PENDING,
  meta: {
    cancellationSource
  }
});

const setUpdateLineItemSuccess = (
  lineItemId: string
): lineItemsActionTypes => ({
  type: UPDATE_LINE_ITEM_SUCCESS,
  payload: lineItemId
});

const setUpdateLineItemFailure = (
  error: Error,
  action?: { text: string; onClick: () => void }
): lineItemsActionTypes => ({
  type: UPDATE_LINE_ITEM_FAILURE,
  meta: {
    error: {
      message: error && error.message,
      ...(action ? { action } : {})
    }
  }
});

const setDeleteLineItemPending = (
  cancellationSource: CancelTokenSource
): lineItemsActionTypes => ({
  type: DELETE_LINE_ITEM_PENDING,
  meta: {
    cancellationSource
  }
});

const setDeleteLineItemFailure = (error: Error): lineItemsActionTypes => ({
  type: DELETE_LINE_ITEM_FAILURE,
  meta: {
    error: {
      message: error && error.message
    }
  }
});

const setDeleteLineItemSuccess = (
  lineItemId: string
): lineItemsActionTypes => ({
  type: DELETE_LINE_ITEM_SUCCESS,
  payload: lineItemId
});

const setGetLineItemDetailsPending = (
  lineItemFetchMap: LineItemFetchMap
): lineItemsActionTypes => ({
  type: GET_LINE_ITEM_DETAILS_PENDING,
  meta: {
    lineItemFetchMap
  }
});

const setGetLineItemDetailsFailure = (
  error: Error,
  lineItemMap: LineItemFetchMap
): lineItemsActionTypes => ({
  type: GET_LINE_ITEM_DETAILS_FAILURE,
  payload: lineItemMap,
  meta: {
    error: {
      message: error && error.message
    }
  }
});

const setGetLineItemDetailsSuccess = (
  lineItemMap: LineItemFetchMap
): lineItemsActionTypes => ({
  type: GET_LINE_ITEM_DETAILS_SUCCESS,
  payload: lineItemMap
});

const setAbortCreateLineItemRequest = (
  cancellationSource: CancelTokenSource
): lineItemsActionTypes => ({
  type: ABORT_CREATE_LINE_ITEM_REQUEST,
  meta: {
    cancellationSource
  }
});

const setAbortArchiveLineItemRequest = (
  cancellationSource: CancelTokenSource
): lineItemsActionTypes => ({
  type: ABORT_ARCHIVE_LINE_ITEM_REQUEST,
  meta: {
    cancellationSource
  }
});

const setAbortUpdateLineItemRequest = (
  cancellationSource: CancelTokenSource
): lineItemsActionTypes => ({
  type: ABORT_UPDATE_LINE_ITEM_REQUEST,
  meta: {
    cancellationSource
  }
});

export const getLineItemCreate =
  (
    lineItem: LineItemFormattedForAttachingCreatives,
    parentCampaign: RootCampaign | undefined,
    options: {
      shouldAlsoUpdateCampaign?: boolean;
    }
  ): AppThunk<Promise<string>> =>
  async (dispatch, getState) => {
    const cancellationSource = axios.CancelToken.source();
    const { shouldAlsoUpdateCampaign } = options;

    try {
      if (shouldAlsoUpdateCampaign && parentCampaign) {
        await updateCampaign(parentCampaign);
        // we have to watch out for the version number, so we're updating here
        await dispatch(getCampaign(parentCampaign.id));
      }

      const prevCancellationSource = selectCreateLineItemsCancellationSource(
        getState()
      );

      prevCancellationSource && prevCancellationSource.cancel();

      dispatch(setCreateLineItemPending(cancellationSource));

      const key = (await madSDK.cryptography.mintKey(ObjType.INST)) as string;

      if (!key) {
        dispatch(
          setCreateLineItemFailure(
            new Error(MESSAGE_GETTER_DICTIONARY.CREATE_MINT_ERROR())
          )
        );
        throw {
          type: "Failed to mint inst key."
        };
      }

      try {
        const createdLineItem = await createLineItem(
          { ...lineItem, id: key },
          key,
          cancellationSource
        );

        dispatch(setCreateLineItemSuccess(createdLineItem));
        return key;
      } catch (err) {
        if (isHttpStatusForbidden(err)) {
          const errorMsg = new Error(
            MESSAGE_GETTER_DICTIONARY.FORBIDDEN_FROM_UPDATING_FAILURE()
          );
          dispatch(setCreateLineItemFailure(errorMsg));
        } else {
          const errorMsg = new Error(
            `${MESSAGE_GETTER_DICTIONARY.CREATE_SETTINGS_FAILURE()} ${
              isErrorResponseValid(err)
                ? `${deriveErrorResponse(err).filter(isTruthy).join(",")}.`
                : ""
            }`
          );

          dispatch(setCreateLineItemFailure(errorMsg));
        }
        /** Make sure we bubble up the error so that any component that calls this function can handle it. */
        throw new Error(err);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        dispatch(setAbortCreateLineItemRequest(cancellationSource));
      } else {
        FullStory.event(FullStoryCustomEvent.LINE_ITEM_CREATION_FAILURE, err);

        if (err.type === "MINE_KEY_ERROR") {
          dispatch(
            setCreateLineItemFailure(
              new Error(MESSAGE_GETTER_DICTIONARY.CREATE_KEY_FAILURE())
            )
          );
        }
      }
      throw new Error(err);
    }
  };

export const lineItemArchive =
  (id: LineItemId): AppThunk<Promise<string>> =>
  async (dispatch, getState) => {
    if (selectIsLineItemSaving(getState())) {
      const errorMsg = new Error(
        MESSAGE_GETTER_DICTIONARY.CURRENTLY_UPDATING()
      );
      dispatch(setDeleteLineItemFailure(errorMsg));

      throw errorMsg;
    }
    const cancellationSource = axios.CancelToken.source();

    try {
      const prevCancelSource = selectLoadArchiveLineIitemCancellationSource(
        getState()
      );

      prevCancelSource && prevCancelSource.cancel();
      dispatch(setDeleteLineItemPending(cancellationSource));
      await archiveLineItem(id, cancellationSource);
      dispatch(setDeleteLineItemSuccess(id));
      dispatch(showSuccess(MESSAGE_GETTER_DICTIONARY.ARCHIVE_SUCCESS()));
      return id as string;
    } catch (err) {
      if (axios.isCancel(err)) {
        dispatch(setAbortArchiveLineItemRequest(cancellationSource));
      } else if (err?.name === "REFRESH_ERROR") {
        const errorMsg = new Error(MESSAGE_GETTER_DICTIONARY.UPDATE_REFRESH());
        dispatch(setDeleteLineItemFailure(errorMsg));
      } else {
        const errorMsg = new Error(MESSAGE_GETTER_DICTIONARY.ARCHIVE_FAILURE());
        dispatch(setDeleteLineItemFailure(errorMsg));
      }
      throw new Error(err);
    }
  };

export const requestLineItemArchive =
  (id: LineItemId, campaignId: string): AppThunk<Promise<void>> =>
  async dispatch => {
    await dispatch(lineItemArchive(id));
    await dispatch(getCampaign(campaignId));
  };

export const lineItemDetailToBeFetched =
  (
    id: LineItemId,
    products: Product[],
    forceRefresh = false,
    skipCampaignUpdate = false
  ): AppThunk<Promise<LineItemFormatted>> =>
  async (dispatch, getState) => {
    const lineItemIdToDetailsMap = getState().lineItems.details;

    if (
      lineItemIdToDetailsMap[id] &&
      lineItemIdToDetailsMap[id].data &&
      !forceRefresh
    ) {
      dispatch(
        setGetLineItemDetailsSuccess({
          ...lineItemIdToDetailsMap,
          [id]: {
            data: lineItemIdToDetailsMap[id].data as LineItemFormatted,
            isLoading: true
          }
        })
      );
      // Don't enqueue a fetch if data for it already exists in the hook's state.
      return lineItemIdToDetailsMap[id].data as LineItemFormatted;
    }

    dispatch(
      setGetLineItemDetailsPending({
        ...lineItemIdToDetailsMap,
        [id]: {
          data:
            (lineItemIdToDetailsMap[id] && lineItemIdToDetailsMap[id].data) ||
            null,
          isLoading: true
        }
      })
    );

    try {
      const cancellationSource = axios.CancelToken.source();
      const lineItem = await getLineItemDetails(id, cancellationSource, true);

      const formattedLineItemDetails = transformRawLineItemDetails(
        lineItem,
        products.reduce((acc, el) => {
          /* eslint-disable no-param-reassign */
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          acc[el.id] = el;
          /* eslint-enable no-param-reassign */
          return acc;
        }, {})
      );

      dispatch(
        setGetLineItemDetailsSuccess({
          ...getState().lineItems.details,
          [id]: {
            data: formattedLineItemDetails,
            isLoading: false
          }
        })
      );
      if (!skipCampaignUpdate) {
        /** Also make sure to update the line item's parent campaign */
        await dispatch(getCampaign(formattedLineItemDetails.parentCampaignId));
      }
      return formattedLineItemDetails;
    } catch (err) {
      dispatch(
        setGetLineItemDetailsFailure(err, {
          ...getState().lineItems.details,
          [id]: {
            data: null, // Set to null to prevent refetch.
            isLoading: false
          }
        })
      );
      throw new Error(err);
    }
  };

// state is of type any as we can't really hold a type over the state, and it's easier to fetch the campaign inside this function
const getCampaignWithDatesFromLineItems = (
  state: any,
  lineItem: LineItemFormattedForAttachingCreatives
) => {
  const campaignFromState = selectCampaignById(
    state,
    lineItem.parentCampaignId
  );
  const campaign = {
    ...campaignFromState,
    details: {
      ...campaignFromState.details,
      data: campaignFromState.details.data || {
        parent: campaignFromState.parent,
        dayparts: FILLED_DAYPARTS
      }
    }
  };

  if (
    PRE_LIVE_CAMPAIGN_STATUSES.has(campaign.status) ||
    !isDatePassed(campaign.startDate)
  ) {
    const startDates = campaign.lineItems
      .filter(li => li.id !== lineItem.id)
      .map(li => li.startDate);
    if (lineItem.startDate) {
      startDates.push(lineItem.startDate);
    }
    campaign.startDate = getEarliestDate(startDates);
    campaign.startASAP =
      lineItem.startASAP || campaign.lineItems.some(li => li.startASAP);
  }
  const endDates = campaign.lineItems
    .filter(li => li.id !== lineItem.id)
    .map(li => li.endDate);
  if (lineItem.endDate) {
    endDates.push(lineItem.endDate);
  }
  campaign.endDate = getLatestDate(endDates);
  return campaign;
};

export const lineItemUpdate =
  (
    lineItem: LineItemFormattedForAttachingCreatives,
    options: {
      ignorePreviousRequest?: boolean;
      shouldAlsoUpdateCampaign?: boolean;
    }
  ): AppThunk<Promise<string>> =>
  async (dispatch, getState) => {
    const cancellationSource = axios.CancelToken.source();
    const { ignorePreviousRequest, shouldAlsoUpdateCampaign } = options;

    try {
      if (!ignorePreviousRequest) {
        const prevCancellationSource = selectUpdateLineItemsCancellationSource(
          getState()
        );

        prevCancellationSource && prevCancellationSource.cancel();
      }

      // as we hope to allow the user more freedom before any publishing, it will be useful in the future to have a campaign update context here
      if (shouldAlsoUpdateCampaign) {
        const campaign = getCampaignWithDatesFromLineItems(
          getState(),
          lineItem
        );
        await updateCampaign(campaign);
        // we have to watch out for the version number, so we're updating here
        await dispatch(getCampaign(campaign.id));
      }

      dispatch(setUpdateLineItemPending(cancellationSource));

      await updateLineItem(lineItem, cancellationSource);

      dispatch(setUpdateLineItemSuccess(lineItem.id));

      return lineItem.id;
    } catch (err) {
      if (axios.isCancel(err)) {
        setAbortUpdateLineItemRequest(cancellationSource);
      } else if (err?.name === "REFRESH_LINE_ITEM_DETAILS_FAILURE") {
        const errorMsg = new Error(
          MESSAGE_GETTER_DICTIONARY.LATEST_DATA_UNABLE_TO_BE_FETCHED_FAILURE()
        );
        dispatch(setUpdateLineItemFailure(errorMsg));
      } else {
        // PK: This is to account for when a non-whitelisted user tries to save an inst on production (e.g save line item, will get error "This endpoint is not supported in prod at this time")
        // eslint-disable-next-line
        if (isHttpStatusForbidden(err)) {
          const errorMsg = new Error(
            MESSAGE_GETTER_DICTIONARY.FORBIDDEN_FROM_UPDATING_FAILURE()
          );

          dispatch(setUpdateLineItemFailure(errorMsg));
        } else {
          const errorMsg = new Error(
            `${MESSAGE_GETTER_DICTIONARY.UPDATE_FAILURE()}${
              isErrorResponseValid(err)
                ? // Accounts for multiple errors when saving
                  `${deriveErrorResponse(err).filter(isTruthy).join(",")}.`
                : // Fallback in the event there is no error message
                  ""
            }`
          );

          dispatch(
            setUpdateLineItemFailure(errorMsg, {
              text: "Try again",
              onClick: () =>
                dispatch(
                  lineItemUpdate(lineItem, {
                    shouldAlsoUpdateCampaign
                  })
                )
            })
          );
        }
      }
      throw err;
    }
  };

export const requestLineItemUpdate =
  (
    lineItem: LineItemFormattedForAttachingCreatives,
    options: {
      providedProducts?: Product[];
      shouldAlsoUpdateCampaign?: boolean;
    }
  ): AppThunk<Promise<LineItemFormatted>> =>
  async dispatch => {
    const { providedProducts, shouldAlsoUpdateCampaign } = options;

    const itemId = await dispatch(
      lineItemUpdate(lineItem, {
        shouldAlsoUpdateCampaign
      })
    );
    const products = providedProducts || (await dispatch(getAllProducts()));
    const freshLineItemData = await dispatch(
      lineItemDetailToBeFetched(itemId, products, true)
    ).catch(e => {
      console.error(e);
    });

    if (!freshLineItemData) {
      throw { name: "REFRESH_LINE_ITEM_DETAILS_FAILURE" };
    }

    dispatch(showSuccess(MESSAGE_GETTER_DICTIONARY.UPDATE_DETAIL_SUCCESS()));
    return freshLineItemData;
  };

export const requestLineItemCreate =
  (
    lineItem: LineItemFormattedForAttachingCreatives,
    options: {
      providedProducts?: Product[];
      shouldAlsoUpdateCampaign?: boolean;
    }
  ): AppThunk<Promise<LineItemFormatted>> =>
  async (dispatch, getState) => {
    const { providedProducts, shouldAlsoUpdateCampaign } = options;

    let campaign;
    if (shouldAlsoUpdateCampaign) {
      campaign = getCampaignWithDatesFromLineItems(getState(), lineItem);
      if (campaign.status === CampaignStatus.COMPLETED) {
        campaign.rawStatus = RawInstructionStatus.PAUSED;
      }
    }
    const itemId = await dispatch(
      getLineItemCreate(lineItem, campaign, {
        shouldAlsoUpdateCampaign
      })
    );
    const products = providedProducts || (await dispatch(getAllProducts()));
    const freshLineItemData = await dispatch(
      lineItemDetailToBeFetched(itemId, products, true)
    ).catch(e => {
      console.error(e);
    });

    if (!freshLineItemData) {
      throw { name: "REFRESH_LINE_ITEM_DETAILS_FAILURE" };
    }
    return freshLineItemData;
  };

export const requestLineItemStatusUpdate =
  (
    lineItem: ShallowLineItem | LineItemFormatted,
    campaignId: string,
    serviceStatus: ServiceStatus,
    options: {
      shouldAlsoUpdateCampaign?: boolean;
    }
  ): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const { shouldAlsoUpdateCampaign } = options;

    if (serviceStatus === ServiceStatus.ARCHIVED) {
      // Use endpoint with Delete and update table view
      await dispatch(requestLineItemArchive(lineItem.id, campaignId));

      if (shouldAlsoUpdateCampaign) {
        const campaign = getCampaignWithDatesFromLineItems(
          getState(),
          lineItem as LineItemFormattedForAttachingCreatives
        );
        await updateCampaign(campaign);
      }

      // Update cache for detail page
      const products = await dispatch(getAllProducts());
      await dispatch(lineItemDetailToBeFetched(lineItem.id, products, true));
      return;
    }

    // Get the latest data for update
    const products = await dispatch(getAllProducts());
    const freshLineItemData = await dispatch(
      lineItemDetailToBeFetched(lineItem.id, products, true)
    ).catch(e => {
      console.error(e);
    });

    if (!freshLineItemData) {
      throw { name: "REFRESH_LINE_ITEM_DETAILS_FAILURE" };
    }

    const lineItemDataToUpdate: LineItemFormatted = {
      ...freshLineItemData,
      rawStatus: serviceStatus
    };

    // Use endpoint for update and update cache for detail page
    await dispatch(
      requestLineItemUpdate(lineItemDataToUpdate, {
        providedProducts: [],
        shouldAlsoUpdateCampaign
      })
    );
    // Update table view
    await dispatch(getCampaign(campaignId));
  };

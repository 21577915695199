import axios from "axios";
import { getDownloadURL, getMetadata, ref, updateMetadata, uploadBytesResumable } from "firebase/storage";
import { InvalidCreativeError, InvalidResultFromUpload, InvalidURL, MadSDKAbortError, MintedKeyFailure, NoFileGiven, NoMetadata, NotImplementedError, UnauthenticatedError } from "../../../errors";
import { DEFAULT_RENDITIONS_LEGACY, deriveAudioChannelLegacy, fillInEmptyTrackingTypesLegacy, findClickthroughFromEventsLegacy, parseEventUrlsLegacy, DEFAULT_EMPTY_TRACKING_PIXELS_LEGACY, MIME_TO_GCP_EXTENSION_LEGACY, MIME_TYPE_TO_MEDIA_TYPE_LEGACY, TEXT_TYPE_STR_TO_ENUM_LEGACY, determineRenditionMediaTypeLegacy, validateCreativeLegacy } from "../../../models/creatives/legacy";
import { MediaType } from "../../../models/media";
import { SENSITIVE_CATEGORY_MAP } from "../../../models/creatives";
import { InscapeFilterFields } from "../../../models/creatives/inscape";
import Inscape from "./inscape";
import Specifications from "./specifications";
import { standardizeMacroUrl } from "../../../models/creatives/vast";
import Templates from "./templates";
import { Handler } from "../../handlers";
import { FilterTypes, ServiceFilterTypes } from "../../handlers/filter";
import { ObjType, URL_REGEX_WITH_REQUIRED_PROTOCOL_OR_PROTO_TAG } from "../../../types";
import { validators } from "../validators";
import { parseNewNewYorkApiResponse, removeWhitespace, validateUrl } from "../../../utils";
import { escapeRegex } from "../../../utils/strings";
/**
 * Handler for creatives
 */
class LegacyCreatives extends Handler {
    /**
     * Handlers need access to MadSDK
     * @param sdk Instance of MadSDK to use for lookups
     */
    constructor(sdk) {
        super(sdk, "creatives");
        /**
         * After uploading a asset we need to be able to update that metadata about that asset
         * @param asset The asset that was uploaded
         * @param url Url is the location of the asset to be updated
         */
        this.updateFileMetadata = async (asset, url) => {
            if (!asset.metadata) {
                throw new NoMetadata();
            }
            const reference = ref(this.sdk.madFire.dropStorage, url);
            const metadata = {
                customMetadata: {
                    user: asset.metadata.customMetadata.user,
                    organization: asset.metadata.customMetadata.organization,
                    height: asset.metadata.customMetadata.height || "0",
                    width: asset.metadata.customMetadata.width || "0",
                    creativeMediaKey: asset.metadata.customMetadata.creativeMediaKey || ""
                }
            };
            await updateMetadata(reference, metadata);
        };
        /**
         *
         * @param creative to attempt to upload or handle a url
         * @returns new creative
         */
        this.handleAsset = async (creative) => {
            // Going forward we will support having either
            // file or a url as an asset, this code will determine
            // is the url a vasturl or cdn
            if (creative.asset?.file) {
                // If the creative has a file we need to upload it
                const locationStorage = this.getStorageLocation(creative.id ? creative.id : "", !!creative.asset.isBulk, this.getFileExtensionFromFileType(creative.asset.file.type));
                const uploadedAsset = await this.uploadFileToPath(creative.asset.file, locationStorage);
                return {
                    ...creative,
                    cloudStorageUrl: uploadedAsset.url
                };
            }
            if (creative.asset?.url) {
                // validateAsset 400s then we have most likely been
                // given a vastUrl and need to treat it as such
                try {
                    await this.validateAsset(creative.asset.url);
                    return {
                        ...creative,
                        cloudStorageUrl: creative.asset.url
                    };
                }
                catch (error) {
                    console.error(error);
                    return {
                        ...creative,
                        vastUrl: creative.asset.url
                    };
                }
            }
            return creative;
        };
        /**
         * @deprecated
         * Save is currently unsupported, use create or update as needed
         */
        /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
        this.save = async (data) => {
            throw new NotImplementedError("save");
        };
        /**
         * @deprecated
         * Save is currently unsupported, use create or update as needed
         */
        /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
        this.saveItem = (data) => {
            throw new NotImplementedError("save");
        };
        /**
         * This currently only supports getting non bulk uploaded
         * information.
         * @param id Id of the creative to get meta for
         */
        this.getFileMetaData = async (id) => {
            const path = this.getStorageLocation(id, false, "mp4");
            const reference = ref(this.sdk.madFire.dropStorage, path);
            return getMetadata(reference);
        };
        /**
         * Handles uploading files.
         * TODO: Review is this should live in a different collection specifically for uploading files
         * @param file file to be uploaded
         * @param path id of where to upload the file
         * @param onUploadUpdate call back for process tracking
         * @return Url of the stored location
         */
        this.uploadFileToPath = async (file, path, onUploadUpdate) => {
            const reference = ref(this.sdk.madFire.dropStorage, path);
            const task = uploadBytesResumable(reference, file);
            return new Promise((resolve, reject) => {
                task.on("state_changed", 
                // TODO: this should not be any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    if (onUploadUpdate) {
                        onUploadUpdate(+snapshot.bytesTransferred);
                    }
                }, (uploadError) => {
                    reject(uploadError);
                }, async () => {
                    try {
                        const downloadURL = await getDownloadURL(task.snapshot.ref);
                        if (!task.snapshot.metadata.md5Hash || !task.snapshot.metadata.contentType) {
                            throw new Error("Didn't have data");
                        }
                        // asset hashes stored in hex format
                        resolve({
                            hash: task.snapshot.metadata.md5Hash,
                            name: file.name,
                            size: task.snapshot.metadata.size,
                            url: downloadURL,
                            gs: `gs://${task.snapshot.ref.bucket}/${task.snapshot.ref.fullPath}`,
                            contentType: task.snapshot.metadata.contentType,
                            lastModified: new Date(file.lastModified)
                        });
                    }
                    catch (e) {
                        console.error("Error finalizing video asset: ", e);
                        reject(e);
                    }
                });
            });
        };
        /**
         * Currently this only transforms a creative to the most basic needs
         * for a service. For non-linear and vast there is an extra step of
         * transforming event_urls
         * @param creative creative to transforms
         */
        this.toServiceCreative = (creative) => {
            const potentialCategory = creative.category ? creative.category.toLowerCase() : "";
            const iabCategoryIds = creative.iabSubcategoryId || creative.iabCategoryId
                ? [creative.iabSubcategoryId || creative.iabCategoryId]
                : [];
            return {
                id: creative.id,
                advertiser: creative.advertiserId || "", // seem creation needs this
                parent: creative.advertiserId || "", // put update uses parent
                isci_code: creative.isciCode ? creative.isciCode.trim() : "",
                name: creative.name ? creative.name.trim() : "",
                vast_url: creative.vastUrl || "",
                tag: creative.tag || "",
                url: creative.cloudStorageUrl ? creative.cloudStorageUrl.trim() : "",
                duration: creative.validationDetails?.assetMetadata?.durationInSeconds
                    ? Math.round(creative.validationDetails?.assetMetadata?.durationInSeconds)
                    : 0,
                renditions: creative.renditions ? creative.renditions : [DEFAULT_RENDITIONS_LEGACY],
                status: creative.status ? creative.status : 200,
                event_urls: parseEventUrlsLegacy(creative) || [],
                flags: potentialCategory
                    ? {
                        /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
                        [potentialCategory]: SENSITIVE_CATEGORY_MAP[potentialCategory]
                    }
                    : undefined,
                video_annotation: creative.videoAnnotation,
                file_name: creative.fileName,
                iab_category_rtb_ids: iabCategoryIds
            };
        };
        /**
         * Used to covert a ServiceCreative to a Creative
         * This legacy version of toCreative is used for creating and modifying creatives
         * @param serviceCreative Service Creative to transform
         * @param users List of users (used to hydrate)
         * @param inscape List of status (used to hydrate)
         * @param advertisers List of advertisers (used to hydrate)
         * @returns Creative based on that ServiceCreative
         */
        this.toCreative = (serviceCreative, users, inscape, advertisers) => {
            const creativeRenditions = serviceCreative.renditions
                ? serviceCreative.renditions[0]
                : DEFAULT_RENDITIONS_LEGACY;
            const createdByUser = serviceCreative.updated_by
                ? users[serviceCreative?.updated_by]
                : undefined;
            const updatedBy = serviceCreative.updated_by && createdByUser ? createdByUser.email : "";
            const creativeAdvertiser = advertisers[serviceCreative.parent];
            const inscapeStatus = inscape ? inscape[serviceCreative.id] : null;
            const validationDetails = this.toValidationRendition(creativeRenditions);
            const mimeType = validationDetails.assetMetadata.mime;
            const extension = MIME_TO_GCP_EXTENSION_LEGACY[mimeType];
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            const mediaType = MIME_TYPE_TO_MEDIA_TYPE_LEGACY[mimeType];
            const thumbnailSrc = this.getThumbnailSrc({
                creativeId: serviceCreative.id,
                mediaType,
                mimeType
            });
            const tagReinspectResult = serviceCreative.tag_reinspect_result?.inspected_at.seconds
                ? {
                    inspectedAt: new Date(serviceCreative.tag_reinspect_result.inspected_at.seconds * 1000),
                    status: serviceCreative.tag_reinspect_result.status,
                    errorMessage: serviceCreative.tag_reinspect_result.error_message
                }
                : undefined;
            return {
                id: serviceCreative.id,
                advertiserId: serviceCreative.parent,
                advertiserName: creativeAdvertiser ? creativeAdvertiser.name : "",
                assetUrl: `${this.sdk.urls.creativeAssetFolderBaseUrl}/${serviceCreative.id}/master${extension}`,
                cdnUrl: serviceCreative.asset_url || "",
                clickThroughUrl: removeWhitespace(findClickthroughFromEventsLegacy(serviceCreative.event_urls)),
                createdOn: serviceCreative.created_at
                    ? new Date(serviceCreative.created_at.seconds * 1000)
                    : undefined,
                validationDetails,
                asset_validations: serviceCreative.asset_validations,
                externalId: serviceCreative.ext_id || "",
                isciCode: serviceCreative.isci_code || "",
                lastUpdated: serviceCreative.updated_at
                    ? new Date(serviceCreative.updated_at.seconds * 1000)
                    : undefined,
                updatedBy,
                name: serviceCreative.name || "",
                orderManagementSystemId: serviceCreative.oms_id || "",
                vastUrl: serviceCreative.vast_url || "",
                tag: serviceCreative.tag || "",
                thumbnailSrc,
                trackingPixels: Array.isArray(serviceCreative.event_urls)
                    ? fillInEmptyTrackingTypesLegacy(serviceCreative.event_urls)
                    : DEFAULT_EMPTY_TRACKING_PIXELS_LEGACY,
                associatedLineItems: serviceCreative.associated_lineitems
                    ? serviceCreative.associated_lineitems
                    : 0,
                associatedCampaigns: serviceCreative.associated_campaigns
                    ? serviceCreative.associated_campaigns
                    : 0,
                renditions: [creativeRenditions], // TODO: Verify this is used b/c it isn't transformed and the data is in validationDetails
                inscapeStatus: inscapeStatus ? inscapeStatus.creativeInscapeStatus : "",
                inscapeTimestamp: inscapeStatus ? inscapeStatus.timestamp * 1000 : 0,
                status: serviceCreative.status,
                videoAnnotation: serviceCreative.video_annotation,
                tagReinspectResult,
                iabCategoryId: this.getIabCategory(serviceCreative.iab_category_rtb_ids),
                iabSubcategoryId: this.getIabSubcategory(serviceCreative.iab_category_rtb_ids),
                category: ""
            };
        };
        /**
         * Helper function to covert ServiceRendition to a ValidationDetails
         * @param data ServiceRendition to be converted to a ValidationDetails
         * @returns ValidationDetails based on the ServiceRendition
         */
        this.toValidationRendition = (data) => {
            const mediaType = determineRenditionMediaTypeLegacy(data);
            return {
                assetMetadata: {
                    videoEncodingFormat: data.video_codec,
                    volumeInDecibels: data.volume,
                    videoBitrateInBitsPerSecond: data.video_bitrate,
                    isVideoCropped: data.cropped,
                    height: data.height,
                    width: data.width,
                    fileSizeInBytes: data.size,
                    durationInSeconds: data.duration, // default duration transform
                    audioSampleRateInKhZ: data.audio_sample_rate,
                    audioChannelType: deriveAudioChannelLegacy(data),
                    audioEncodingFormat: data.audio_codec,
                    audioBitrateInBitsPerSecond: data.audio_bitrate,
                    assetHash: data.MD5 || data.hash,
                    mime: data.mime,
                    imageFormat: data.image_format,
                    mediaType,
                    isDisplayType: mediaType === MediaType.DISPLAY,
                    name: data.filename,
                    textFormat: data.text_format && TEXT_TYPE_STR_TO_ENUM_LEGACY[data.text_format]
                }
            };
        };
        /**
         * Coverts a ServiceAssetValidation to a ValidationDetails
         * @param data ServiceAssetValidation to be converted
         * @returns ValidationDetails to be returned
         */
        this.toValidationDetails = (data) => {
            const { macros_replacements_preview, metadata, problems, specifications, vast_video_url } = data;
            const details = this.toValidationRendition(metadata);
            if (vast_video_url) {
                details.assetMetadata.vastVideoUrl = vast_video_url;
            }
            const macroReplacements = new Array();
            if (macros_replacements_preview) {
                for (const [key, value] of Object.entries(macros_replacements_preview)) {
                    const regex = new RegExp(escapeRegex(key), "g");
                    const standard = value;
                    const isSupported = value.trim().length !== 0;
                    const macroReplacement = {
                        regex,
                        standard,
                        isSupported
                    };
                    macroReplacements.push(macroReplacement);
                }
            }
            return {
                validationDetails: {
                    assetValidationProblems: problems,
                    specificationsCheckedAgainst: specifications
                        .map((specification) => this.specifications.toSpecification(specification))
                        .reduce((byKey, specification) => {
                        return {
                            ...byKey,
                            [specification.nameOfSpecification]: specification
                        };
                    }, Object.create(null)),
                    ...(macroReplacements?.length && { macroReplacements }),
                    ...details
                },
                asset_validations: data.asset_validations
            };
        };
        /**
         * Creates the creative thumbnail based on media type and mime type
         * @param mediaType the current media type (display, audio or video)
         * @param id creative id
         * @param mimeType the mime type of the creative
         */
        this.getThumbnailSrc = ({ creativeId, mediaType, mimeType }) => {
            const thumbnailSrcByMediaType = {
                [MediaType.DISPLAY]: `${this.sdk.urls.creativeAssetFolderBaseUrl}/${creativeId}/master${MIME_TO_GCP_EXTENSION_LEGACY[mimeType]}`,
                [MediaType.AUDIO]: "",
                [MediaType.VIDEO]: `${this.sdk.urls.creativeAssetFolderBaseUrl}/${creativeId}/thumb.jpg`
            };
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            return thumbnailSrcByMediaType[mediaType];
        };
        /**
         * Clears creative related cache
         * @param creative to clear cache for
         */
        this.clearCache = (creative) => {
            const { id, advertiserId } = creative;
            this.cache.remove(`${this.sdk.urls.madhiveEncoderBaseUrl}/creatives`);
            if (id) {
                this.cache.remove(`${this.sdk.urls.madhiveEncoderBaseUrl}/creative/${id}`);
            }
            if (advertiserId) {
                this.cache.remove(`${this.sdk.urls.madhiveEncoderBaseUrl}/creatives?parent=${advertiserId}`);
            }
        };
        this.specifications = new Specifications(sdk);
        this.templates = new Templates(sdk);
        this.inscape = new Inscape(sdk);
        this.validators = validators(sdk);
    }
    /**
     * This method builds the storage location based on id & bulk
     * @param id creative id
     * @param isBulk is it build
     * @returns string for location
     */
    // eslint-disable-next-line class-methods-use-this
    getStorageLocation(id, isBulk, extension) {
        return isBulk ? `${id}/master.${extension}` : `creatives/originals/${id}.${extension}`;
    }
    /**
     * This method runs all the different validation we have on a creative
     * and throws errors when things are wrong
     * @param creative creative to validate
     */
    async validateInteral(creative) {
        const advertiserIds = await this.sdk.advertisers.find();
        const validationErrors = validateCreativeLegacy(creative, advertiserIds);
        const iabCategoryError = this.validators.iabCategoryId(creative.iabCategoryId);
        const iabSubcategoryError = this.validators.iabSubcategoryId(creative.iabCategoryId, creative.iabSubcategoryId);
        if (iabCategoryError) {
            validationErrors.push(iabCategoryError);
        }
        if (iabSubcategoryError) {
            validationErrors.push(iabSubcategoryError);
        }
        if (creative.cloudStorageUrl && !validationErrors.length) {
            if (!creative.asset?.file)
                return;
            try {
                await this.validateAsset(creative.cloudStorageUrl);
            }
            catch (error) {
                validationErrors.push(error);
            }
        }
        if (validationErrors.length > 0) {
            throw new InvalidCreativeError(creative.id ? creative.id : "", validationErrors);
        }
    }
    /**
     * This will take a given a creative and update it
     * @param creative creative to be updated
     * @returns Creative after updated (based on returned data)
     */
    async updateCreative(creative) {
        return new Promise((resolve, reject) => {
            const cancellationSource = axios.CancelToken.source();
            // eslint-disable-next-line no-unused-expressions
            (async () => {
                try {
                    const response = await axios.post(`${this.sdk.urls.madhiveEncoderBaseUrl}/creative`, this.toServiceCreative(creative), {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        cancelToken: cancellationSource.token
                    });
                    const { data: creativeAfterUpdate } = parseNewNewYorkApiResponse(response);
                    const [users, inscape, advertisers] = await this.getUsersCreativesAdvertisers();
                    this.cache.clear();
                    return resolve(this.toCreative(creativeAfterUpdate, users, inscape, advertisers));
                }
                catch (error) {
                    if (axios.isCancel(error)) {
                        reject(new MadSDKAbortError());
                    }
                    else {
                        reject(error);
                    }
                }
            })();
        });
    }
    /**
     * Takes a ServiceCreativeBase and call the correct api to create that creative
     * @param creative creative to be created
     * @param url url to run against (b/c there are three ways to create a creative)
     * @returns Creative that ended up being created.
     */
    async createCreative(creative, url) {
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const response = await axios.post(url, creative, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
                    const [users, inscape, advertisers] = await this.getUsersCreativesAdvertisers();
                    // because the different /new<-x> return different data styles we detect
                    // where the created creative data is
                    let creativeResponse;
                    if (response.data && response.data.data) {
                        creativeResponse = response.data.data.creative
                            ? response.data.data.creative
                            : response.data.data;
                    }
                    else if (response.data) {
                        creativeResponse = response.data;
                    }
                    if (creativeResponse) {
                        resolve(this.toCreative(creativeResponse, users, inscape, advertisers));
                        this.cache.clear();
                    }
                    else {
                        reject("No returned saved creative");
                    }
                }
                catch (error) {
                    if (axios.isCancel(error)) {
                        reject(new MadSDKAbortError());
                    }
                    else {
                        reject(error);
                    }
                }
            })();
        });
    }
    /**
     * Method that takes a asset url and runs the service validation on that url
     * @param url url to a valid asset (will error if it isn't a supported type)
     * @returns ValidationDetails returned about that asset
     */
    async validateAsset(url) {
        if (!validateUrl(url)) {
            throw new InvalidURL();
        }
        const response = await axios.post(`${this.sdk.urls.madhiveEncoderBaseUrl}/validate`, {
            url
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return this.toValidationDetails(response.data.data);
    }
    async validateTag(tag) {
        const response = await axios.post(`${this.sdk.urls.madhiveEncoderBaseUrl}/validate`, {
            tag
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return this.toValidationDetails(response.data.data);
    }
    /**
     * Method that takes a asset url and runs the service validation on that url
     * @param url url to a valid asset (will error if it isn't a supported type)
     * @returns ValidationDetails returned about that asset
     */
    async validateVAST(vast_url) {
        if (!URL_REGEX_WITH_REQUIRED_PROTOCOL_OR_PROTO_TAG.test(vast_url)) {
            throw new InvalidURL();
        }
        const response = await axios.post(`${this.sdk.urls.madhiveEncoderBaseUrl}/validate`, {
            tag: vast_url
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return this.toValidationDetails(response.data.data);
    }
    /**
     * This method exists because for converting from Service to Experience types
     * we need to fetch Users, InscapeStatus, and Advertisers
     * @returns Promise of the results of these look ups
     */
    async getUsersCreativesAdvertisers() {
        const user = await this.sdk.getCurrentUser();
        if (!user)
            throw new UnauthenticatedError();
        return Promise.all([
            (await this.sdk.users.find()).reduce((acc, cur) => {
                /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
                acc[cur.id] = cur;
                return acc;
            }, {}),
            (await this.inscape.find({
                where: [
                    {
                        field: InscapeFilterFields.ORG_ID,
                        type: FilterTypes.EQ,
                        value: user.primaryOrganizationId
                    }
                ]
            })).reduce((acc, cur) => {
                acc[cur.creativeID] = cur;
                return acc;
            }, {}),
            (await this.sdk.advertisers.find()).reduce((acc, cur) => {
                /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
                acc[cur.id] = cur;
                return acc;
            }, {})
        ]);
    }
    fetchItemsWithResponse(filters, options) {
        const filterMap = filters.where?.reduce((acc, cur) => {
            if (!cur.value) {
                return acc;
            }
            if (cur.field === "advertiserId") {
                // TODO: Get mapping of creative to service creative since we have to do transforms here
                // given we want to use a single Filter<Creative>. We cannot pass in a different type for Filter given
                // the base class expects a UniqueType aka Creative in this instance.
                acc.advertiserId = `?parent${ServiceFilterTypes[cur.type]}${cur.value}`;
            }
            else {
                /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
                acc[cur.field] = cur.value;
            }
            return acc;
        }, {
            id: "",
            advertiserId: "",
            pageSize: "",
            token: "",
            offset: "",
            search: "",
            sortBy: "",
            direction: "",
            statuses: "",
            parent: "",
            mediaTypes: "",
            categories: [],
            // TODO - remove once smithers passes through categories instead of advertiserCategories
            advertiserCategories: "",
            videoStandards: ""
        });
        // Using Web API URLSearchParams (https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams) to build the required query string for pagination, sorting and searches
        let paginationParams = "";
        if (filterMap) {
            const { pageSize, categories, token, offset, search, sortBy, direction, statuses, parent, mediaTypes, 
            // TODO - remove once smithers passes through categories instead of advertiserCategories
            advertiserCategories, videoStandards, asset_validations, asset_validation_status } = filterMap;
            const urlParams = new URLSearchParams();
            urlParams.append("fuzziness", "1");
            if (pageSize) {
                urlParams.append("page_size", pageSize);
            }
            if (token) {
                urlParams.append("page_token", token);
            }
            if (offset) {
                urlParams.append("offset", offset);
            }
            if (search) {
                urlParams.append("search", search);
            }
            if (statuses) {
                urlParams.append("statuses", statuses);
            }
            if (sortBy && direction) {
                urlParams.append("sortBy", `${sortBy} ${direction}`);
            }
            if (parent) {
                urlParams.append("parent", parent);
            }
            if (mediaTypes) {
                urlParams.append("media_types", mediaTypes);
            }
            // filters creatives by their iab_categories
            if (categories.length) {
                urlParams.append("iab_category_rtb_ids", categories.join(","));
            }
            // TODO - remove once smithers passes through categories instead of advertiserCategories
            if (advertiserCategories) {
                urlParams.append("advertiser_categories", advertiserCategories);
            }
            if (videoStandards) {
                urlParams.append("video_standards", videoStandards);
            }
            if (asset_validation_status) {
                urlParams.append("asset_validation_status", asset_validation_status);
            }
            if (asset_validations) {
                urlParams.append("asset_validations", asset_validations.join(","));
            }
            const resultingParams = urlParams.toString();
            if (resultingParams.length) {
                paginationParams = "?" + resultingParams;
            }
        }
        let url = `${this.sdk.urls.madhiveEncoderBaseUrl}/creatives${paginationParams}`;
        if (filterMap?.id) {
            url = `${this.sdk.urls.madhiveEncoderBaseUrl}/creative/${filterMap.id}`;
        }
        if (filterMap?.advertiserId) {
            url = `${this.sdk.urls.madhiveEncoderBaseUrl}/creatives${filterMap.advertiserId}`;
        }
        if (!filterMap?.pageSize && this.cache.has(url) && !options?.noCache) {
            return new Promise((resolve) => {
                this.cache.get(url).then((creatives) => {
                    resolve([undefined, creatives]);
                });
            });
        }
        const req = new Promise((resolve, reject) => {
            const cancellationSource = axios.CancelToken.source();
            (async () => {
                try {
                    const response = await axios.get(url, {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        cancelToken: cancellationSource.token
                    });
                    // There are instances where empty creative returns null
                    if (!response.data.data) {
                        resolve([response.data.pagingInfo, []]);
                    }
                    let creativesData;
                    let paging;
                    if (Array.isArray(response.data.data)) {
                        const { data: allServiceCreatives, pagingInfo } = parseNewNewYorkApiResponse(response);
                        creativesData = allServiceCreatives;
                        paging = pagingInfo;
                    }
                    else {
                        const { data: serviceCreative, pagingInfo } = parseNewNewYorkApiResponse(response);
                        creativesData = [serviceCreative];
                        paging = pagingInfo;
                    }
                    const [users, inscape, advertisers] = await this.getUsersCreativesAdvertisers();
                    resolve([
                        paging,
                        creativesData.map((serviceCreative) => this.toCreative(serviceCreative, users, inscape, advertisers))
                    ]);
                }
                catch (error) {
                    if (axios.isCancel(error)) {
                        reject(new MadSDKAbortError());
                    }
                    else {
                        reject(error);
                    }
                }
            })();
        });
        if (!filterMap?.pageSize) {
            this.cache.set(url, req.then((unwrap) => unwrap[1]));
        }
        return req;
    }
    /**
     * @param filters: filter the creatives. Supported filters are where-type, on id or advertiserId, with an equality condition on the provided value (e.g., { where: [{ field: "id", type: FilterTypes.EQ, value: "obi-wan" }] }).
     */
    async findItems(filters) {
        return this.fetchItemsWithResponse(filters).then((unwrap) => {
            const creatives = unwrap[1];
            return creatives;
        });
    }
    async findPaginatedItems(filters, options) {
        const paginatedItems = this.fetchItemsWithResponse(filters, options).then((unwrap) => {
            const paging = unwrap[0];
            const creatives = unwrap[1];
            if (paging) {
                return {
                    token: paging.token,
                    count: paging.count,
                    items: creatives
                };
            }
            return {
                token: undefined,
                count: 0,
                items: creatives
            };
        });
        return paginatedItems;
    }
    /**
     * Because of the nature of creatives we need to have a separate create,update
     * methods.
     * @param creative creative to be updated
     */
    async update(creative, options) {
        this.clearCache(creative);
        let updateCreative = creative;
        if (creative.vastUrl) {
            updateCreative = {
                ...creative,
                vastUrl: standardizeMacroUrl(creative.vastUrl, true)
            };
        }
        try {
            !options?.skipValidation && (await this.validateInteral(updateCreative));
            return this.updateCreative(updateCreative);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    /**
     * Because of the nature of creatives we need to have a separate create,update
     * methods.
     * @param creative creative to be updated
     */
    async create(creative) {
        this.clearCache(creative);
        let newCreative = creative;
        try {
            // if no id given mint a new id
            if (!creative.id || creative.id === "") {
                const id = await this.sdk.cryptography.mintKey(ObjType.CREATIVE_VID);
                newCreative = {
                    ...creative,
                    id
                };
            }
            newCreative = await this.handleAsset(newCreative);
            // even if vastUrl came in via asset.url we still want to clean
            // incase we were given a vastUrl
            if (newCreative.vastUrl) {
                newCreative = {
                    ...newCreative,
                    vastUrl: standardizeMacroUrl(newCreative.vastUrl, true)
                };
            }
            // Ready to save let's validate
            try {
                await this.validateInteral(newCreative);
            }
            catch (error) {
                return Promise.reject(error);
            }
            // Based on which type of creative we need to hit a different url
            let url = `${this.sdk.urls.madhiveEncoderBaseUrl}/new`;
            if (newCreative.vastUrl) {
                url = `${this.sdk.urls.madhiveEncoderBaseUrl}/new-vast`;
            }
            const newServiceCreative = this.toServiceCreative(newCreative);
            return this.createCreative(newServiceCreative, url);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    /**
     * Deletes given creative id
     * @param id id of creative to be deleted
     * @returns Creative that was deleted.
     */
    async deleteItem(id) {
        return new Promise((resolve, reject) => {
            const cancellationSource = axios.CancelToken.source();
            (async () => {
                try {
                    const response = await axios.delete(`${this.sdk.urls.baseAPIUrl}/creative/${id}`, {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        cancelToken: cancellationSource.token
                    });
                    const { data: creativeDeletion } = parseNewNewYorkApiResponse(response);
                    this.cache.clear();
                    const [users, inscape, advertisers] = await this.getUsersCreativesAdvertisers();
                    resolve(this.toCreative(creativeDeletion, users, inscape, advertisers));
                }
                catch (error) {
                    if (axios.isCancel(error)) {
                        reject(new MadSDKAbortError());
                    }
                    else {
                        reject(error);
                    }
                }
            })();
        });
    }
    /**
     * This is a help function to have a standard UploadFileResult
     * returned instead of having two different types.
     * @param url URL to an asset to be validated
     * @returns UploadedFileResults for a given asset (mostly this will be data from CDN)
     */
    async validateAssetFromUrl(url) {
        try {
            const { asset_validations, validationDetails } = await this.validateAsset(url);
            return Promise.resolve({
                hash: validationDetails.assetMetadata.assetHash,
                name: validationDetails.assetMetadata.name,
                size: validationDetails.assetMetadata.fileSizeInBytes,
                url,
                contentType: validationDetails.assetMetadata.videoEncodingFormat,
                validationDetails,
                asset_validations
            });
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    /**
     * This is a help function to have a standard UploadedFileResults
     * returned instead of having two different types.
     * @param tag TAG to an asset to be validated
     * @returns UploadedFileResults for a given asset (this will be data from Third Party Tag)
     */
    async validateAssetFromTag(tag) {
        try {
            const { asset_validations, validationDetails } = await this.validateTag(tag);
            return Promise.resolve({
                hash: validationDetails.assetMetadata.assetHash,
                size: validationDetails.assetMetadata.fileSizeInBytes,
                tag,
                validationDetails,
                asset_validations
            });
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    /**
     * This is a help function to have a standard UploadedFileResults
     * returned instead of having two different types.
     * @param vastURL to an asset to be validated
     * @returns UploadedFileResults for a given asset (this will be data from Third Party Tag)
     */
    async validateAssetFromVAST(vastURL) {
        try {
            const { asset_validations, validationDetails } = await this.validateVAST(vastURL);
            return Promise.resolve({
                hash: validationDetails.assetMetadata.assetHash,
                size: validationDetails.assetMetadata.fileSizeInBytes,
                validationDetails,
                asset_validations
            });
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    /**
     * Gets file extension from file type
     * @param fileType file type string (e.g. 'video/mp4')
     * @returns the file extension (e.g. 'mp4')
     */
    getFileExtensionFromFileType(fileType) {
        return fileType.split("/")[1];
    }
    /**
     * Uploads a single asset to our madhive storage locations
     * @param asset Asset to be uploaded
     * @param onUploadUpdate Callback method that is called on status updates of the upload
     * @returns UploadedFileResults of the uploaded asset.
     */
    async uploadFile(asset, onUploadUpdate) {
        if (!asset.file) {
            throw new NoFileGiven();
        }
        // We need a ID when just uploading a file not attacked to any creative
        const mintedKey = await this.sdk.cryptography.mintKey(ObjType.CREATIVE_VID);
        if (!mintedKey) {
            throw new MintedKeyFailure();
        }
        // With single upload we used the path for non-bulk storage
        const path = this.getStorageLocation(mintedKey, false, this.getFileExtensionFromFileType(asset.file.type));
        const result = await this.uploadFileToPath(asset.file, path, onUploadUpdate);
        if (asset.metadata) {
            // metadata given use it to update meta for the given file
            await this.updateFileMetadata({
                ...asset,
                metadata: {
                    customMetadata: {
                        ...asset.metadata.customMetadata,
                        height: result?.validationDetails?.assetMetadata?.height?.toString(),
                        width: result?.validationDetails?.assetMetadata?.width?.toString(),
                        creativeMediaKey: result.id
                    }
                }
            }, path);
        }
        // we validate the upload when doing a direct upload.
        if (result.url) {
            const { asset_validations, validationDetails } = await this.validateAsset(result.url);
            return Promise.resolve({
                ...result,
                id: mintedKey,
                validationDetails,
                asset_validations
            });
        }
        throw new InvalidResultFromUpload();
    }
    /**
     * Used to extract the IAB category from the BE response.
     * @param iabCategoriesResp An array of IAB categories returned by the BE (currently only one category is returned)
     * @returns The category associated with the response
     */
    getIabCategory(iabCategoriesResp) {
        const delimiter = "-";
        const resp = iabCategoriesResp?.[0];
        if (!resp)
            return "";
        return resp.includes(delimiter) ? resp.slice(0, resp.indexOf(delimiter)) : resp;
    }
    /**
     * Used to extract the IAB subcategory from the BE response.
     * @param iabCategoriesResp An array of IAB categories returned by the BE (currently only one category is returned)
     * @returns The subcategory associated with the response
     */
    getIabSubcategory(iabCategoriesResp) {
        const delimiter = "-";
        const resp = iabCategoriesResp?.[0];
        if (!resp)
            return "";
        return resp.includes(delimiter) ? resp : "";
    }
}
export default LegacyCreatives;

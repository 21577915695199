import { LookerBrowserSDK } from "@looker/sdk";
import { BrowserTransport, DefaultSettings } from "@looker/sdk-rtl";
import axios from "axios";
import { EmbedHandler } from "./embed";
import Attribution from "./modules/attribution";
import Pacing from "./modules/pacing";
import { QueryHandler } from "./query";
import { MadLookerSession } from "./session";
export class MadLooker {
    /**
     * @param sdk: an instance of the madSDK.
     * @param config: configuration otpions for MadLooker.
     */
    constructor(sdk, config = {}) {
        /**
         * Responds to Madhive account changes by properly modifying Looker's authentication session.
         * @param account: a Madhive account.
         */
        this.handleAuthStateChange = async (account) => {
            // if account is null, we've logged out - or if the IDs don't match, we've got a brand new account
            if (!account || this.userId !== account.user.id) {
                await this.session.logout();
            }
        };
        const settings = {
            ...DefaultSettings(),
            base_url: sdk.urls.lookerBaseUrl,
            ...(config.settings || {})
        };
        this.madSDK = sdk;
        const transport = config.transport || new BrowserTransport(settings);
        this.session = new MadLookerSession(sdk, settings, transport);
        const initializer = config.initializer || LookerBrowserSDK;
        this.sdk = initializer.init40(settings, transport, this.session);
        sdk.authentication.onAuthStateChanged({
            next: this.handleAuthStateChange,
            error: () => this.handleAuthStateChange(null)
        });
        this.embed = new EmbedHandler(sdk);
        this.query = new QueryHandler(sdk);
        this.modules = {
            pacing: new Pacing(sdk),
            attribution: new Attribution(sdk)
        };
    }
    /**
     * Retrieves the Looker user object for the logged in MadHive user,
     * we should not cache this request because part of the user retrieval process
     * it also adds the necessary permissions to the Looker user which get overwritten by
     * Looker's iframe embedding process. So this allows us to have the necessary permissions
     * before performing any Looker API action.
     * @returns the user object from Looker.
     */
    async me() {
        const userEndpointUrl = `${this.madSDK.urls.burnsBaseUrl}/looker/me`;
        return axios.get(userEndpointUrl).then((response) => response.data);
    }
}

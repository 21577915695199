import { madSDK } from "lib/sdk";
import { isValidNumber } from "./number";

const DEFAULT_FALLBACK_COLOR = "#FFF";

export const getVariable = (variable: any, scope = document.body) =>
  getComputedStyle(scope).getPropertyValue(variable).trim();

export const isValidHexFormat = (c: string) =>
  Boolean(
    typeof c === "string" &&
      c.startsWith("#") &&
      (c.length === 4 || c.length === 7) &&
      !/[^a-fA-F0-9]/.test(c.slice(1))
  );

export const convertRGBColorComponentToHex = (c: string | number) => {
  const hex = Number(c).toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

export const convertRBGStringToRGBComponents = (c: string) => {
  if (typeof c !== "string") {
    return [];
  }

  /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts2872 */
  if (!c.startsWith("rgb(" || !c.endsWith(")"))) {
    return [];
  }

  return c
    .trim()
    .replace("rgb(", "")
    .replace(")", "")
    .split(",")
    .map(v => v.trim());
};

export const isValidRGBFormat = (c: string) => {
  if (typeof c !== "string") {
    return false;
  }

  const rgbComponents = convertRBGStringToRGBComponents(c);

  if (rgbComponents.length !== 3) {
    return false;
  }

  return rgbComponents.every(v => {
    const i = parseInt(v, 10);
    return isValidNumber(i) && i >= 0 && i <= 255;
  });
};

export const convertRGBStringToHexString = (c: string) => {
  if (!isValidRGBFormat(c)) {
    return DEFAULT_FALLBACK_COLOR;
  }

  const [r, g, b] = convertRBGStringToRGBComponents(c);

  const hex = `#${convertRGBColorComponentToHex(
    r
  )}${convertRGBColorComponentToHex(g)}${convertRGBColorComponentToHex(b)}`;

  /**
   * If invalid hex format, fallback to default white color
   */
  return isValidHexFormat(hex) ? hex : DEFAULT_FALLBACK_COLOR;
};

export const initializeStyles = async () => {
  return new Promise<void>(resolve => {
    let link = document.getElementById("main-stylesheet") as HTMLLinkElement;

    // Chrome won't fire onload evens unless you create a new link
    if (link) {
      link.remove();
    }

    link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.id = "main-stylesheet";
    link.onload = () => {
      resolve();
    };
    link.onerror = () => {
      resolve();
    };
    link.onabort = () => {
      resolve();
    };
    document.head.appendChild(link);
    link.href = `${
      madSDK.urls.burnsBaseUrl
    }/public/styles/theme.css?reload=${new Date().getTime()}`;
  });
};

// Replaces Material UI breakpoint values
// https://v4.mui.com/customization/breakpoints/#default-breakpoints
export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
};

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | number;

const getBreakpointValue = (breakpoint: Breakpoint): number =>
  isValidNumber(breakpoint) ? breakpoint : BREAKPOINTS[breakpoint];

export const buildMediaQuery = {
  min: (min: Breakpoint) => `@media (min-width: ${getBreakpointValue(min)}px)`,
  max: (max: Breakpoint) =>
    `@media (max-width: ${getBreakpointValue(max) - 0.5}px)`,
  between: (min: Breakpoint, max: Breakpoint) =>
    `@media (min-width: ${getBreakpointValue(min)}px) and (max-width: ${
      getBreakpointValue(max) - 0.5
    }px)`
};

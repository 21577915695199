import { addTrailingSlash, sanitizeUserInput, validateUrl } from "../../../utils";
import { validateAndParseVAST } from "../vast";
import { TrackerType } from "../trackers";
import { MediaType } from "../../media";
export * from "./specifications";
export var MimeTypeLegacy;
(function (MimeTypeLegacy) {
    MimeTypeLegacy[MimeTypeLegacy["UNKNOWN"] = 0] = "UNKNOWN";
    MimeTypeLegacy[MimeTypeLegacy["MP4"] = 1] = "MP4";
    MimeTypeLegacy[MimeTypeLegacy["WEBM"] = 2] = "WEBM";
    MimeTypeLegacy[MimeTypeLegacy["AAC"] = 3] = "AAC";
    MimeTypeLegacy[MimeTypeLegacy["JPG"] = 4] = "JPG";
    MimeTypeLegacy[MimeTypeLegacy["PNG"] = 5] = "PNG";
    MimeTypeLegacy[MimeTypeLegacy["MP3"] = 6] = "MP3";
    MimeTypeLegacy[MimeTypeLegacy["GIF"] = 7] = "GIF";
    MimeTypeLegacy[MimeTypeLegacy["HTML"] = 10] = "HTML";
    MimeTypeLegacy[MimeTypeLegacy["OGG"] = 11] = "OGG";
})(MimeTypeLegacy || (MimeTypeLegacy = {}));
export const MIME_TO_GCP_EXTENSION_LEGACY = {
    [MimeTypeLegacy.UNKNOWN]: "",
    [MimeTypeLegacy.MP4]: ".mp4",
    [MimeTypeLegacy.WEBM]: "",
    [MimeTypeLegacy.AAC]: ".m4a",
    [MimeTypeLegacy.OGG]: ".ogg",
    [MimeTypeLegacy.JPG]: ".jpeg",
    [MimeTypeLegacy.PNG]: ".png",
    [MimeTypeLegacy.MP3]: ".m4a",
    [MimeTypeLegacy.GIF]: ".gif",
    [MimeTypeLegacy.HTML]: ""
};
export var ServiceMediaTypeLegacy;
(function (ServiceMediaTypeLegacy) {
    ServiceMediaTypeLegacy["VIDEO"] = "VIDEO";
    ServiceMediaTypeLegacy["AUDIO"] = "AUDIO";
    ServiceMediaTypeLegacy["DISPLAY"] = "DISPLAY";
})(ServiceMediaTypeLegacy || (ServiceMediaTypeLegacy = {}));
export var TextTypeLegacy;
(function (TextTypeLegacy) {
    TextTypeLegacy["HTML"] = "html";
    TextTypeLegacy["TEXT"] = "text";
    TextTypeLegacy["PLAIN"] = "plain";
})(TextTypeLegacy || (TextTypeLegacy = {}));
export const TEXT_TYPE_STR_TO_ENUM_LEGACY = {
    [TextTypeLegacy.HTML]: TextTypeLegacy.HTML,
    [TextTypeLegacy.TEXT]: TextTypeLegacy.TEXT,
    [TextTypeLegacy.PLAIN]: TextTypeLegacy.PLAIN
};
export const MIME_TYPE_TO_MEDIA_TYPE_LEGACY = {
    [MimeTypeLegacy.UNKNOWN]: MediaType.NONE,
    [MimeTypeLegacy.MP4]: MediaType.VIDEO,
    [MimeTypeLegacy.WEBM]: MediaType.VIDEO,
    [MimeTypeLegacy.AAC]: MediaType.AUDIO,
    [MimeTypeLegacy.JPG]: MediaType.DISPLAY,
    [MimeTypeLegacy.PNG]: MediaType.DISPLAY,
    [MimeTypeLegacy.MP3]: MediaType.AUDIO,
    [MimeTypeLegacy.GIF]: MediaType.DISPLAY,
    [MimeTypeLegacy.HTML]: MediaType.DISPLAY
};
export var AudioChannelLegacy;
(function (AudioChannelLegacy) {
    AudioChannelLegacy["STEREO"] = "Stereo";
    AudioChannelLegacy["MONO"] = "Mono";
    AudioChannelLegacy["NONE"] = "";
})(AudioChannelLegacy || (AudioChannelLegacy = {}));
export var ServiceAudioChannelLegacy;
(function (ServiceAudioChannelLegacy) {
    ServiceAudioChannelLegacy["STEREO"] = "stereo";
    ServiceAudioChannelLegacy["MONO"] = "mono";
    ServiceAudioChannelLegacy["NONE"] = "";
})(ServiceAudioChannelLegacy || (ServiceAudioChannelLegacy = {}));
export const DEFAULT_EMPTY_TRACKING_PIXELS_LEGACY = [
    {
        type: TrackerType.IMPRESSION,
        urls: [""]
    },
    {
        type: TrackerType.START,
        urls: [""]
    },
    {
        type: TrackerType.Q1,
        urls: [""]
    },
    {
        type: TrackerType.Q2,
        urls: [""]
    },
    {
        type: TrackerType.Q3,
        urls: [""]
    },
    {
        type: TrackerType.Q4,
        urls: [""]
    },
    {
        type: TrackerType.CLICK_TRACKER,
        urls: [""]
    },
    {
        type: TrackerType.CLICK_THROUGH,
        urls: [""]
    }
];
const TRACKER_EVENT_TYPE_TO_SORT_INDEX_LEGACY = {
    [TrackerType.IMPRESSION]: 0,
    [TrackerType.START]: 1,
    [TrackerType.Q1]: 2,
    [TrackerType.Q2]: 3,
    [TrackerType.Q3]: 4,
    [TrackerType.Q4]: 5,
    [TrackerType.CLICK_TRACKER]: 6,
    [TrackerType.CLICK_THROUGH]: 7
};
export const DEFAULT_RENDITIONS_LEGACY = {
    MD5: "",
    mime: 0,
    width: 0,
    height: 0,
    bitrate: 0,
    size: 0,
    duration: 0,
    volume: 0,
    video_bitrate: 0,
    video_codec: "",
    audio_bitrate: 0,
    audio_codec: "",
    audio_sample_rate: 0,
    audio_channel: 0,
    isDisplayType: false
};
var CreativeTagReinspectStatusLegacy;
(function (CreativeTagReinspectStatusLegacy) {
    CreativeTagReinspectStatusLegacy[CreativeTagReinspectStatusLegacy["NO_CHANGE"] = 1] = "NO_CHANGE";
    CreativeTagReinspectStatusLegacy[CreativeTagReinspectStatusLegacy["CHANGED"] = 2] = "CHANGED";
    CreativeTagReinspectStatusLegacy[CreativeTagReinspectStatusLegacy["INVALID"] = 3] = "INVALID";
})(CreativeTagReinspectStatusLegacy || (CreativeTagReinspectStatusLegacy = {}));
/** Fields which are valid to filter on. */
export var FilterFieldsLegacy;
(function (FilterFieldsLegacy) {
    FilterFieldsLegacy["ADVERTISER_ID"] = "advertiserId";
    FilterFieldsLegacy["ID"] = "id";
})(FilterFieldsLegacy || (FilterFieldsLegacy = {}));
/**
 * Looks through the event urls looking for a TrackerType.CLICK_THROUGH
 * @param event_urls array of event_urls
 * @returns the clickthrough url or empty string
 */
export const findClickthroughFromEventsLegacy = (event_urls) => {
    if (event_urls && event_urls.length > 0) {
        const maybeFoundClickthrough = event_urls.find((item) => item.type === TrackerType.CLICK_THROUGH);
        if (maybeFoundClickthrough &&
            maybeFoundClickthrough.urls &&
            maybeFoundClickthrough.urls.length) {
            return maybeFoundClickthrough.urls[0];
        }
        return "";
    }
    return "";
};
/**
 * Based on the ServiceRendition determine the Audio Channel
 * @param rendition Redition to use to determine channel
 * @returns AudioChannel that was determined
 */
export const deriveAudioChannelLegacy = (rendition) => {
    if (rendition.audio_channel) {
        if (`${rendition.audio_channel}` === ServiceAudioChannelLegacy.STEREO ||
            rendition.audio_channel === 2) {
            return AudioChannelLegacy.STEREO;
        }
        if (`${rendition.audio_channel}` === ServiceAudioChannelLegacy.MONO ||
            rendition.audio_channel === 1) {
            return AudioChannelLegacy.MONO;
        }
    }
    return AudioChannelLegacy.NONE;
};
/**
 * Filters our trackers that are only empty urls
 * @param trackers array of trackers to clean out
 * @returns
 */
export const filterOutEmptyTrackingUrlsLegacy = (trackers) => {
    if (trackers) {
        return trackers
            .map((el) => ({
            ...el,
            urls: el.urls ? el.urls.filter((url) => !!url) : []
        }))
            .filter((el) => el.urls && el.urls.length > 0);
    }
    return [];
};
/**
 * Cleans out the trackers object to make sure they are standard
 * @param trackers array of all TrackerObjects
 * @returns tracker array with added types
 */
export const fillInEmptyTrackingTypesLegacy = (trackers, defaultTrackers = DEFAULT_EMPTY_TRACKING_PIXELS_LEGACY) => {
    // Get existing trackers in creative/creative flight
    const existingTrackers = filterOutEmptyTrackingUrlsLegacy(trackers);
    // Get tracker types that exist in creative/creative flight
    const existingTrackerTypes = existingTrackers.map((item) => item.type);
    // Get non-existing types to fill tracking pixels data with and show in UI
    const trackersToFill = defaultTrackers.filter((tracker) => existingTrackerTypes.every((item) => item !== tracker.type));
    return [...existingTrackers, ...trackersToFill].sort((trackerA, trackerB) => TRACKER_EVENT_TYPE_TO_SORT_INDEX_LEGACY[trackerA.type] -
        TRACKER_EVENT_TYPE_TO_SORT_INDEX_LEGACY[trackerB.type]);
};
/**
 * clickthrough url needs to be part of Trackers. If
 * it isn't in the trackers add it.
 * @param trackers Array of trackers
 * @param clickThrough url fo the clickthrough
 * @returns new trackers array
 */
export const maybeAddClickthroughToTrackersLegacy = (trackers, clickThrough) => {
    // RR: If clickThrough is empty then we filter out the associated type in trackers array
    if (!clickThrough) {
        return trackers.filter((el) => el.type !== TrackerType.CLICK_THROUGH);
    }
    // PK: If clickthrough exists and type doesn't exist in event_urls, we'll want to append it
    return !trackers.find((el) => el.type === TrackerType.CLICK_THROUGH && el.urls[0])
        ? trackers.concat([{ type: TrackerType.CLICK_THROUGH, urls: [clickThrough.trim()] }])
        : trackers.map((item) => 
        // This ensures that the latest clickthrough is copied over to the event_urls[]
        item.type === TrackerType.CLICK_THROUGH
            ? { type: TrackerType.CLICK_THROUGH, urls: [clickThrough.trim()] }
            : item);
};
export const LAW_GOVT_POLITICS_CATEGORY = "IAB11";
export const IAB_CATEGORY_ERROR_MESSAGE = "IAB Category is required";
export const IAB_SUBCATEGORY_ERROR_MESSAGE = "IAB Subcategory is required";
export const validateAdvertiserIdInputLegacy = (advertiserIdInput, listOfAvailableAdvertiserIds) => {
    if (!advertiserIdInput) {
        return "Creatives must have an advertiser.";
    }
    if (listOfAvailableAdvertiserIds.size && !listOfAvailableAdvertiserIds.has(advertiserIdInput)) {
        return "The selected is not valid. Please choose a different advertiser.";
    }
    return null;
};
export const validateCreativeNameInputLegacy = (creativeNameInput) => {
    if (!creativeNameInput) {
        return "A creative cannot have a blank name.";
    }
    return null;
};
/**
 * Validates a given IAB category ID
 * @param categoryId the IAB category ID
 * @returns an error message if it's invalid, otherwise null
 */
export const validateIabCategoryIdInput = (iabCategoryId) => {
    return iabCategoryId ? null : IAB_CATEGORY_ERROR_MESSAGE;
};
/**
 * Validates a given IAB subcategory ID
 * @param subcategoryId the IAB subcategory ID
 * @returns an error message if it's invalid, otherwise null
 */
export const validateIabSubategoryIdInput = (iabCategoryId, iabSubcategoryId) => {
    if (iabCategoryId === LAW_GOVT_POLITICS_CATEGORY && !iabSubcategoryId) {
        return IAB_SUBCATEGORY_ERROR_MESSAGE;
    }
    return null;
};
/**
 * Takes a Creative and returns eventUrls based off the data given
 * @param creative to get the eventUrls
 * @returns Array of TrackerObjects
 */
export const parseEventUrlsLegacy = (creative) => {
    let eventUrls;
    if (creative.trackingPixels || creative.clickThroughUrl) {
        // Ensures that at least one tracker url exists since 0th index is empty strings
        const filteredOutEmptyTrackingUrls = filterOutEmptyTrackingUrlsLegacy(creative.trackingPixels);
        eventUrls = maybeAddClickthroughToTrackersLegacy(filteredOutEmptyTrackingUrls, creative.clickThroughUrl);
    }
    return eventUrls;
};
/**
 * Determines media type based on rendition information
 * @param data ServiceRendition information
 * @returns MediaType
 */
export const determineRenditionMediaTypeLegacy = (data) => {
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    if (MIME_TYPE_TO_MEDIA_TYPE_LEGACY[data.mime] === MediaType.DISPLAY || !!data.image_format) {
        return MediaType.DISPLAY;
        /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    }
    else if (MIME_TYPE_TO_MEDIA_TYPE_LEGACY[data.mime] === MediaType.VIDEO || !!data.video_codec) {
        return MediaType.VIDEO;
    }
    else if (
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    MIME_TYPE_TO_MEDIA_TYPE_LEGACY[data.mime] === MediaType.AUDIO ||
        (!data.video_codec && !!data.audio_codec)) {
        return MediaType.AUDIO;
    }
    return;
};
export const validateThirdPartyTrackingLegacy = (editablePixels) => {
    const pixelErrors = {};
    editablePixels.forEach((el) => {
        el.urls.forEach((url) => {
            // 3rd param is for isVast boolean arg
            const validatedForMacros = validateAndParseVAST(url, false, false);
            if (url &&
                (!validateUrl(addTrailingSlash(sanitizeUserInput(url))) || !validatedForMacros.isValid)) {
                /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
                pixelErrors[url] = true;
            }
        });
    });
    return pixelErrors;
};
/**
 * Runs a set a validation on a creative
 * validateAdvertiserIdInput, validateCreativeNameInput
 * @param creative creative to validate
 * @param advertisers Advertisers to validate with
 * @returns Array of errors in string form
 */
export const validateCreativeLegacy = (creative, advertisers) => {
    const validationErrors = [];
    const advertiserValidation = validateAdvertiserIdInputLegacy(creative.advertiserId, new Set(advertisers.map((advertiser) => advertiser.id)));
    if (advertiserValidation) {
        validationErrors.push(advertiserValidation);
    }
    const creativeNameValidation = validateCreativeNameInputLegacy(creative.name);
    if (creativeNameValidation) {
        validationErrors.push(creativeNameValidation);
    }
    return validationErrors;
};

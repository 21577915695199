import { KnownProjectIds, User as MadSDKUser } from "@madhive/mad-sdk";
import type { IListener, IUser } from "@madhive/mad-tracker";
import type { Event } from "@madhive/mad-tracker/src/lib/types/event";
import { madTracker } from "frontier/lib/tracker";
import { firebaseProjectId } from "lib/sdk";

import { Injector } from "../../injector";

import { HeapProjectID } from "./constants";

export class Heap extends Injector implements IListener<MadSDKUser> {
  /**
   * Sets the current user after MadSDK has authenticated into our heap instance.
   */
  user = {
    set: (user: IUser<MadSDKUser>) => {
      if (!user.traits || !this.instance) {
        return;
      }

      const {
        id,
        email,
        name,
        type,
        userType,
        isAdmin,
        isDev,
        primaryOrganizationId,
        primaryOrganizationName,
        brandingId,
        advertiserId,
        agencyId,
        teamId
      } = user.traits;

      this.instance.identify(email);
      this.instance.addUserProperties({
        hostUrl: window.location.hostname,
        id,
        name,
        email,
        type,
        userType,
        isAdmin,
        isDev,
        primaryOrganizationId,
        primaryOrganizationName,
        brandingId,
        advertiserId,
        agencyId,
        teamId
      });
    },
    reset: () => {
      this.instance?.resetIdentity();
    }
  };

  /**
   * Returns the heap instance from the window object for consumption.
   */
  protected get instance() {
    // heap has an issue where it is init'd as an array, before being turned into an object on load
    // to avoid having it break, we should just grab heap from the window each time we need it, instead of grabbing it once in the constructor
    // see: https://github.com/segmentio/analytics.js/issues/605#issuecomment-605464507
    // eslint-disable-next-line dot-notation
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7015 */
    return window.heap;
  }

  get projectId() {
    switch (firebaseProjectId) {
      case KnownProjectIds.MAD_MASTER:
        return HeapProjectID.PRODUCTION;
      case KnownProjectIds.MAD_HACK:
      case KnownProjectIds.MAD_QA:
      case KnownProjectIds.MADHIVE_TESTING:
      case KnownProjectIds.MAD_STAGING:
        return HeapProjectID.DEVELOPMENT;
      default:
        return undefined;
    }
  }

  /**
   * Used by the injector to inject the heap script into the DOM so we can use Heap.
   */
  protected get source() {
    return `
window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
heap.load('${this.projectId}');
window['_fs_ready'] = function () {
  if (FS) {
    heap.track('FullStory Session', { 'Fullstory Session URL': FS.getCurrentSessionURL(true) });
    heap.addUserProperties({ 'Latest FullStory Session': FS.getCurrentSessionURL() });
  }
};
`;
  }

  /**
   * Sends to Heap the event and properties to track.
   * Can check in https://heapanalytics.com/app/env/409351525/data-galaxy?view=live-data-feed with the Development project.
   */
  track = async ({ event, properties }: { event: Event; properties: any }) => {
    // Inititialize heap on localhost so we can use the track method.
    this.inject({ localhost: true });

    // If there's no instance or the there are no properties to send, stop.
    if (!this.instance || !Object.keys(properties).length) {
      return;
    }

    // Attach the user and org information.
    const user = madTracker.user.get();
    const payload: Record<string, any> = {
      ...properties,
      user_id: user?.id,
      org_id: user?.traits?.primaryOrganizationId,
      org_name: user?.traits?.primaryOrganizationName
    };

    // Send the payload to Heap to track.
    this.instance.track(event, payload);
  };
}

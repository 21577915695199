import { attributionModelFieldsToQueryDimensions } from "../../../../models/looker/modules/attribution";
import { KnownOrganizationIds } from "../../../../types";
import { ATTRIBUTION_QUERY } from "./query";
export default class Scheduling {
    constructor(sdk) {
        this.sdk = sdk;
    }
    async save(settings, filters) {
        const scheduleConfig = await this.buildScheduleConfig(settings, filters);
        const scheduleResponse = this.sdk.looker.sdk.create_scheduled_plan(scheduleConfig);
        try {
            await this.sdk.looker.sdk.ok(scheduleResponse);
        }
        catch (e) {
            console.error(e);
            throw new Error("Failed to save Looker schedule");
        }
    }
    async test(settings, filters) {
        const scheduleConfig = await this.buildScheduleConfig(settings, filters);
        const scheduleResponse = this.sdk.looker.sdk.scheduled_plan_run_once(scheduleConfig);
        try {
            await this.sdk.looker.sdk.ok(scheduleResponse);
        }
        catch (e) {
            console.error(e);
            throw new Error("Failed to run Looker schedule");
        }
    }
    async buildScheduleConfig(settings, filters) {
        const user = await this.sdk.looker.me();
        if (!user.id) {
            throw new Error("Looker user not found");
        }
        const dimensions = [filters.dimension];
        if (filters.crossDimension) {
            dimensions.push(filters.crossDimension);
        }
        const queryDimensions = attributionModelFieldsToQueryDimensions(dimensions);
        const queryFilters = {
            "explore_conversion.flt_advertiser_id": filters.advertiserId,
            "explore_conversion.flt_analysis_interval": this.parseDateFilter(filters.date),
            "explore_conversion.flt_lineitem_id": filters.lineItemIds.join(","),
            "explore_conversion.flt_beacon_id": filters.pixelIds.join(",")
        };
        if (this.sdk.getCurrentUser()?.primaryOrganizationId === KnownOrganizationIds.FOX) {
            queryFilters["explore_conversion.pmt_filter_out_fox_anomalous_ips"] = "yes";
        }
        const newQuery = await this.sdk.looker.query.create({
            ...ATTRIBUTION_QUERY,
            fields: [
                ...queryDimensions,
                "explore_conversion.impressions",
                "explore_conversion.completes",
                "explore_conversion.reach",
                "explore_conversion.avg_frequency",
                "explore_conversion.clicks",
                "explore_conversion.view_completion_rate",
                "explore_conversion.impression_converted",
                "explore_conversion.conversion_rate",
                "explore_conversion.unique_conversions",
                "explore_conversion.unique_conversion_rate"
            ],
            filters: queryFilters
        });
        if (!newQuery.id) {
            throw new Error("Failed to create Looker query");
        }
        return {
            name: `${settings.name} - ${Date.now()}`,
            user_id: user.id,
            run_as_recipient: false,
            enabled: true,
            require_results: false,
            require_no_results: false,
            require_change: false,
            send_all_results: true,
            crontab: this.frequencyToCron(settings.frequency),
            timezone: "America/New_York",
            scheduled_plan_destination: settings.emails.map((recipient) => ({
                format: settings.format,
                apply_formatting: false,
                apply_vis: false,
                address: recipient,
                type: "email"
            })),
            run_once: false,
            include_links: false,
            query_id: newQuery.id
        };
    }
    parseDateFilter(date) {
        switch (date) {
            case "TODAY":
                return "today";
            case "YESTERDAY":
                return "yesterday";
            case "LAST_7_DAYS":
                return "7 day";
            case "LAST_14_DAYS":
                return "14 day";
            case "LAST_30_DAYS":
                return "30 day";
            case "LAST_90_DAYS":
                return "90 day";
            case "LAST_180_DAYS":
                return "180 day";
            case "YTD":
                return "this year";
            case "THIS_WEEK":
                return "this week";
            case "THIS_MONTH":
                return "this month";
            case "THIS_QUARTER":
                return "this quarter";
            case "THIS_YEAR":
                return "this year";
            case "PREV_WEEK":
                return "last week";
            case "PREV_MONTH":
                return "last month";
            case "PREV_QUARTER":
                return "last quarter";
            case "PREV_YEAR":
                return "last year";
            default:
                return "today";
        }
    }
    frequencyToCron(frequency) {
        switch (frequency.date) {
            case "monthly": {
                const [monthHour, monthMinute] = frequency.time.split(":");
                return `${monthMinute} ${monthHour} ${frequency.day} * *`;
            }
            case "weekly": {
                const [weekHour, weekMinute] = frequency.time.split(":");
                return `${weekMinute} ${weekHour} * * ${frequency.day}`;
            }
            case "daily": {
                const [dayHour, dayMinute] = frequency.time.split(":");
                return `${dayMinute} ${dayHour} * * *`;
            }
            case "hourly": {
                const [startHour, startMinute] = frequency.startTime.split(":");
                const [endHour] = frequency.endTime.split(":");
                return `${startMinute} ${startHour}-${endHour}/${frequency.recurrence} * * *`;
            }
            case "minutes": {
                const [startHour] = frequency.startTime.split(":");
                const [endHour] = frequency.endTime.split(":");
                return `*/${frequency.recurrence} ${startHour}-${endHour} * * *`;
            }
            default:
                throw new Error("Invalid frequency type");
        }
    }
}
